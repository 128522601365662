import { DEFAULT_NODE } from "../helpers/handlers";

/**
 * On backspace, if at the start of a non-paragraph, convert it back into a
 * paragraph node.
 * @param {Object} event The keyboard event
 * @param {Editor} editor The slate editor
 * @param {function} next the function that is called next
 * @returns {boolean} Whether the loop should be broken
 */
export const onBackspace = (event, editor, next) => {
  const { value } = editor;
  const { selection } = value;
  if (selection.isExpanded) return next();
  if (selection.start.offset !== 0) return next();

  const { startBlock } = value;
  if (startBlock.type === DEFAULT_NODE) return next();

  event.preventDefault();
  editor.setBlocks(DEFAULT_NODE);

  /*if (startBlock.type === 'list-item') {
    editor.unwrapList();
  }*/
};

/**
 * Creates a removeType Plugin
 * @returns {Object} the plugin
 */
const RemoveType = () => ({
  onKeyDown: (event, editor, next) =>
    event.key !== "Backspace" ? next() : onBackspace(event, editor, next)
});

export default RemoveType;
