/**
 * Generates a mardown plugin that sets the block type if a space is entered after
 * the given sequence
 * @param {string} type The block type thath should be applied
 * @param {string} sequences The markdown starting sequence
 * @returns {Object} The slate plugin
 */
export const markdownBlockSequence = (type, sequences) => ({
  onKeyDown: (event, editor, next) => {
    if (event.key !== " ") return next();
    const { value } = editor;
    const { selection } = value;
    if (selection.isExpanded) return next();

    const { startBlock } = value;
    const { start } = selection;
    const chars = startBlock.text.slice(0, start.offset).replace(/\s*/g, "");

    if (
      Array.isArray(sequences)
        ? !sequences.includes(chars)
        : chars !== sequences
    )
      return next();

    if (!type) return next();
    if (type === "list-item" && startBlock.type === "list-item") return next();
    event.preventDefault();

    editor.setBlocks(type);

    if (type === "list-item") {
      editor.wrapList("bulleted-list");
    }

    editor.moveFocusToStartOfNode(startBlock).delete();
  }
});
