import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardText
} from "reactstrap";
import {
  getAuthenticatedUser,
  getCourseBySlug,
  getIsAuthenticated,
  getCourseRoleById
} from "reducers";

import MainContainer from "../components/MainContainer";
import Wrapper from "../components/Wrapper";
import Header from "./Header";
import { checkCourseInvitation } from "../actions/courseInvitation";
import { fetchApi } from "../utilities/api";
import { getRoleName } from "../utilities/policies";

/**
 * The container for accepting an invitation to a course
 * @returns {Component} The component
 */
class CourseInvite extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      invitation: null,
      acceptError: null,
      isAcceptLoading: false
    };
  }
  accept = () => {
    const { courseSlug, redirectToCourse } = this.props;
    const { invitation } = this.state;
    if (invitation && invitation.id) {
      this.setState({ isAcceptLoading: true });
      fetchApi(
        "/api/courses/" +
          courseSlug +
          "/invitations/" +
          invitation.id +
          "/accept",
        { method: "POST" }
      )
        .then(({ data }) => {
          this.setState({ isAcceptLoading: false });
          redirectToCourse();
        })
        .catch(error => {
          this.setState({ isAcceptLoading: false, acceptError: error });
        });
    }
  };
  componentWillMount() {
    const { checkCourseInvitation, redirectToCourse } = this.props;
    checkCourseInvitation()
      .then(({ item }) => {
        this.setState({ invitation: item });
        // if invitation has already been accepted then forward the user to the dashboard
        if (item.status === "accepted") {
          redirectToCourse();
        }
      })
      .catch(error => {
        let errorMessage;
        switch (error.statusCode) {
          case 404:
            errorMessage =
              "Diese Einladung ist nicht gültig. Hast du die URL richtig eingegeben?";
            break;
          default:
            errorMessage = "Diese Einladung ist nicht gültig.";
        }
        this.setState({ error: errorMessage });
      });
  }
  render() {
    const { error, invitation, acceptError } = this.state;
    return (
      <Wrapper header footer column>
        <Header />
        <MainContainer>
          <Container>
            <Row>
              <Col md={{ size: 4, offset: 4 }}>
                {invitation && (
                  <Card body>
                    <CardTitle>Einladung</CardTitle>
                    <CardText>
                      Du wurdest von{" "}
                      <strong>{invitation.creator.username}</strong> als{" "}
                      <strong>{getRoleName(invitation.userRole)}</strong> zu
                      einem Lehrgang eingeladen.
                    </CardText>
                    <Button color="primary" onClick={this.accept}>
                      Einladung annehmen
                    </Button>
                    {acceptError && (
                      <Alert className="my-2" color="danger">
                        {acceptError.message}
                      </Alert>
                    )}
                  </Card>
                )}
                {error && (
                  <div>
                    <Alert color="danger">{error}</Alert>
                    <Link to="/">
                      <Button block color="primary">
                        Zurück zur Startseite
                      </Button>
                    </Link>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </MainContainer>
      </Wrapper>
    );
  }
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { courseSlug, courseInvitationToken }
    }
  }
) => {
  return {
    user: getAuthenticatedUser(state),
    courseSlug,
    courseInvitationToken,
    isAuthenticated: getIsAuthenticated(state)
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { courseSlug, courseInvitationToken }
    }
  }
) => ({
  /**
   * Redirects to the course
   * @returns {void}
   */
  redirectToCourse() {
    return dispatch(push("/courses/" + courseSlug));
  },
  /**
   * Redirects to the login page
   * @returns {void}
   */
  redirectToLogin() {
    return dispatch(push("/login"));
  },
  /**
   * Checks if the token of the course invitation is valid
   * @returns {Promise} the checkInvitation Promise
   */
  checkCourseInvitation() {
    return dispatch(
      checkCourseInvitation(courseInvitationToken, true, courseSlug)
    );
  }
});

const mergeProps = (mapStateToProps, mapDispatchToProps, ownProps) => ({
  ...ownProps,
  ...mapStateToProps,
  ...mapDispatchToProps
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(CourseInvite)
);
