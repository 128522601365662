import React from "react";
import { Container, Row, Col, Badge } from "reactstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MainContainer from "../components/MainContainer";
import Wrapper from "../components/Wrapper";
import FooterLanding from "./landing/FooterLanding";
import NavbarLanding from "./landing/NavbarLanding";

const TimelineEntry = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 1rem;
    top: 2.8rem;
    width: 2px;
    background: rgba(11, 12, 44, 0.1);
    transform: translateX(-50%);
  }

  &:last-child:after {
    background: transparent;
  }
`;

const TimelineBubble = styled.div`
  border-radius: 2rem;
  width: 2rem;
  height: 2rem;
  background: #f2f3f5;
  text-align: center;
  color: rgba(11, 12, 44, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.625rem;
  margin-bottom: 0.5rem;
`;

const TimelineConnection = styled.div`
  width: 2px;
  height: 2rem;
  background: rgba(11, 12, 44, 0.1);
  flex: 1;
  opacity: ${props => (props.hide ? 0 : 1)};
`;

const TimelineDecoration = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TimelineContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
`;

const TimelineContentDate = styled.div`
  text-transform: uppercase;
  color: rgba(51, 51, 51, 0.25);
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0.125rem;
`;

const content = [
  {
    icon: ["far", "briefcase"],
    date: "28. Mai 19",
    title: "v.1.10 - 💾😈 Der Teufel steckt im Detail.",
    text: () => (
      <div>
        <p>
          Manchmal dauern die Dinge etwas länger als geplant. So haben wir in
          den letzten drei Monaten noch einmal intensiv an dem automatischen
          Speichern für Übungen gearbeitet. Zudem konnten wir zahlreiche Fehler
          beim Verschieben und Sortieren von Übungen beheben. Auch das
          Bearbeiten von Bildern, Hyperlinks, Videos und Formeln haben wir
          komplett überarbeitet und diverse Fehler diesbezüglich behoben.
        </p>
        <p>
          <strong>Weitere Neuerungen:</strong>
        </p>
        <ul>
          <li>
            Es können nun <mark>Vimeo Videos</mark> per URL eingefügt werden.
          </li>
          <li>
            Unnummerierte und nummerierte Aufzählungen lassen sich nun beliebig
            einrücken und gliedern.
          </li>
        </ul>
      </div>
    )
  },
  {
    icon: ["far", "briefcase"],
    date: "18. Januar 19",
    title: "v.1.9 - 🐛🐞🐛 Der frühe Vogel fängt den Wurm.",
    text: () => (
      <div>
        <p>
          In den letzten zwei Monaten haben wir viel am Innenleben von wit
          optimiert und zahlreiche Fehler behoben. Insbesondere das automatische
          Abspeichern beim Bearbeiten der Übungen funktioniert nun
          zuverlässiger. Ausserdem haben wir viele Kleinigkeiten an unserem
          Text-Editor verbessert, damit das Formatieren von Texten noch besser
          klappt.
        </p>
        <p>
          <strong>Weitere Neuerungen:</strong>
        </p>
        <ul>
          <li>
            Es können nun <mark>Youtube Videos</mark> per URL eingefügt werden.
          </li>
          <li>
            Die "Formatierung löschen" Option im Editor funktioniert nun wieder
            korrekt.
          </li>
          <li>Zitate heben sich neu etwas mehr vom restlichen Text ab.</li>
        </ul>
      </div>
    )
  },
  {
    icon: ["far", "briefcase"],
    date: "5. Dezember 18",
    title: "v.1.8 - Mehr Inhalt, mehr Möglichkeiten 📃",
    text: () => (
      <div>
        <p>
          Zwischen den einzelnen Übungen in einer Lektion können nun{" "}
          <mark>beliebige Inhalte</mark> eingefügt werden. So können die Übungen
          mit einem einleitenden Text, Bildern oder zusätzlichen Erläuterungen
          versehen werden. Dies ermöglicht die Erstellung von{" "}
          <mark>interaktiven Arbeitsblättern</mark>, die neben den Übungen auch
          noch den Kontext erläutern.
        </p>
        <p>
          <strong>Weitere Neuerungen:</strong>
        </p>
        <ul>
          <li>
            Das Einfügen und Bearbeiten von Links, LaTeX Formeln und Bildern
            funktioniert nun noch einfacher. Du kannst die Elemente einfach
            anwählen und sie direkt bearbeiten.
          </li>
          <li>
            Der "Übung hinzufügen" Button wurde überarbeitet und es sind nun
            weitere Details zu den unterschiedlichen Übungstypen ersichtlich.
          </li>
          <li>Diverse kleine Verbesserungen und Fehlerbehebungen</li>
        </ul>
      </div>
    )
  },
  {
    icon: ["far", "briefcase"],
    date: "15. November 18",
    title: "v.1.7 - Automatisches Speichern & neue Darstellung der Übungen 🤖",
    text: () => (
      <div>
        <p>
          Wir haben den Übungsmodus komplett überarbeitet und die einzelnen
          Übungen werden nun <mark>untereinander angezeigt</mark>. Somit ist
          jederzeit sichtbar, welche Übungen bereits gelöst wurden und welche
          Antworten dabei gegeben wurden. Als weitere grosse Neuerung werden die
          Übungen beim Bearbeiten nun <mark>automatisch gespeichert</mark>.
          Somit gehen garantiert keine Änderungen verloren und das Bearbeiten
          geht noch schneller.
        </p>
        <p>
          <strong>Weitere Neuerungen:</strong>
        </p>
        <ul>
          <li>Die Übungen im Übungsmodus sind nun nummeriert.</li>
          <li>
            Es erscheint nun eine Warnung, wenn die Seite oder der Browser
            geschlossen wird, wenn noch ungespeicherte Änderungen vorhanden
            sind.
          </li>
          <li>Diverse kleine Verbesserungen und Fehlerbehebungen</li>
        </ul>
      </div>
    )
  },
  {
    icon: ["far", "briefcase"],
    date: "13. November 18",
    title: "v.1.6 - Duplikate, Klone, Kopien & Vorlagen 🐑🐑🐑",
    text: () => (
      <div>
        <p>
          Bestehende öffentliche Lehrgänge können nun als <mark>Vorlage</mark>{" "}
          für eigene Lehrgänge verwendet werden. Lehrende und Lernende können so
          von vorhandenen Inhalten profitieren und ihre eigenen Anpassungen
          vornehmen. Die Sichtbarkeit eines von einem Nicht-Autor kopierten
          Lehrgangs kann nicht auf Privat gesetzt werden. Selbstverständlich
          können Autoren ihre eigenen Lehrgänge{" "}
          <mark>beliebig duplizieren</mark> und deren Sichtbarkeit ändern.
        </p>
        <p>
          <strong>Weitere Neuerungen:</strong>
        </p>
        <ul>
          <li>
            Wir haben die Grösse der drei Punkte zum Bearbeiten der Lehrgänge /
            Lektionen erhöht, was die Bedienung erleichtert.
          </li>
          <li>
            Bei den Lehrgängen auf der Startseite, werden nun die neusten zuerst
            angezeigt.
          </li>
          <li>Diverse kleine Verbesserungen und Fehlerbehebungen</li>
        </ul>
      </div>
    )
  },
  {
    icon: ["far", "briefcase"],
    date: "27. Oktober 18",
    title: "v.1.5 - Ich weiss, was du letzten Sommer getan hast.️ ⏰📊",
    text: () => (
      <div>
        <p>
          In der Auswertung zu jedem Lehrgang können Autoren nun einsehen, wann
          wieviele Übungen geübt worden sind und wieviele davon richtig und
          falsch beantwortet worden sind. Der Zeitraum der Auswertung lässt sich
          dabei frei wählen, so dass Autoren einen detaillierten Einblick
          erhalten, wann die Übungen effektiv absolviert werden. Die{" "}
          <mark>zeitliche Auswertung</mark> wird aggregiert über alle Benutzer
          angezeigt, so dass keine Rückschlüsse auf einzelne Benutzer möglich
          sind.
        </p>
        <p>
          <strong>Weitere Neuerungen:</strong>
        </p>
        <ul>
          <li>
            Die Lektionen werden beim Bearbeiten nun automatisch gespeichert. In
            Kürze folgt dieses Update auch für die einzelnen Aufgaben einer
            Lektion.
          </li>
          <li>Verbesserung der Performance beim Bearbeiten einer Lektion.</li>
          <li>
            Verbesserte Darstellung des Tooltips im WYSIWYG Editor der Übungen
            und Lektionen.
          </li>
        </ul>
      </div>
    )
  },
  {
    icon: ["far", "briefcase"],
    date: "10. September 18",
    title: "v.1.4 - Mitternachtsformel & Auswertungen️️‍ 📈➗✖️",
    text: () => (
      <div>
        <p>
          Dank dem <mark>neuen Formeleditor</mark> können Formeln (wie z.B. die
          Mitternachtsformel) bequem und einfach in allen Aufgabentypen erfasst
          werden. Für erfahrene Benutzer kann zudem LaTex Code für die
          Formeleingabe verwendet werden. <br />
          <br />
          Ausserdem können Autoren eines Lehrgangs nun eine Auswertung des
          Fortschritts aller Benutzer im Lehrgang betrachten.
        </p>
        <p>
          <strong>Weitere Neuerungen:</strong>
        </p>
        <ul>
          <li>
            Es ist nun bei jeder Übung ersichtlich, welcher Benutzer diese
            erstellt hat.
          </li>
          <li>
            Zitate werden nun korrekt angezeigt auch wenn diese mehrere Zeilen
            umfassen.
          </li>
          <li>
            Das Hinzufügen und Löschen von Hinweisen zu Multiple-Choice-Übungen
            ist nun noch einfacher möglich.
          </li>
          <li>Diverse Bugfixes und Performance-Verbesserungen. 🚀</li>
        </ul>
      </div>
    )
  },
  {
    icon: ["far", "briefcase"],
    date: "30. Juli 18",
    title: "v.1.3 - Tippen, Rechnen, Übersetzen. ✍️️‍",
    text: () => (
      <div>
        <p>
          Ein neuer Aufgabentyp ist hinzugekommen: <mark>freie Antworten</mark>.
          Dabei muss die korrekte Antwort in ein Eingabefeld eingegeben werden.
          Es können beliebig viele korrekte Antworten erfasst werden und es kann
          angeben werden, ob die Gross- / Kleinschreibung berücksichtigt werden
          soll. Dieser Aufgabentyp eignet sich perfekt um Vokabeln zu lernen
          oder um Rechnungen zu üben.
        </p>
        <p>
          <strong>Weitere Neuerungen:</strong>
        </p>
        <ul>
          <li>
            Benutzer können den Einladungs-Link zu einem Lehrgang nun auch dazu
            verwenden, den Lehrgang aufzurufen und werden nicht mehr mit einer
            Fehlermeldung konfrontiert.
          </li>
          <li>
            Bei den Übungen wird nun beim Bearbeiten angezeigt, wann diese
            zuletzt aktualisiert wurden.
          </li>
          <li>
            Karteikärtchen können nun während dem Üben auch durch Klicken auf
            einen Button umgedreht werden. (Vielen Dank an Alex für den Hinweis!
          </li>
        </ul>
      </div>
    )
  },
  {
    icon: ["far", "briefcase"],
    date: "16. Juni 18",
    title: "v.1.2 - Lerne nur das, was du noch nicht kannst. 📊️‍",
    text: () => (
      <div>
        <p>
          Du kannst nun vor jeder Übung auswählen, welche Aufgaben abgefragt
          werden sollen. Dabei siehst du auch gleich ein Balkendiagramm zu
          deinem Fortschritt. Somit behältst du immer den Überblick über deinen
          Lernstand und kannst gezielt auf deine Schwächen eingehen.
        </p>
        <p>
          <strong>Weitere Neuerungen:</strong>
        </p>
        <ul>
          <li>
            Aufgaben können nun dupliziert werden. Das spart dir Zeit beim
            Erstellen und Bearbeiten.
          </li>
          <li>
            Mit <kbd>Tab</kbd> kannst du nun durch die Felder beim Erstellen /
            Bearbeiten einer Aufgabe schalten und kommst so noch schneller
            vorwärts.
          </li>
          <li>
            Unser <del>Chef-Kammerjäger</del> Entwickler Nico hat zahlreiche
            Bugs gefunden und fachgerecht behoben. 🐛
          </li>
        </ul>
      </div>
    )
  },
  {
    icon: ["far", "briefcase"],
    date: "23. Mai 18",
    title: "v.1.1 - Suchfunktion und Lernkärtchen 🕵️‍",
    text: () => (
      <div>
        <p>
          Es kann nun neu nach bereits vorhandenen{" "}
          <mark>Lehrgängen gesucht</mark> werden. Als weitere grosse Neuerung
          ist ein neuer Übungstyp "Karteikarte" dazugekommen. Der Übungsmodus
          hat zudem einige optische Anpassungen erhalten.
        </p>
        <p>
          <strong>Weitere Neuerungen:</strong>
        </p>
        <ul>
          <li>
            Beim Einladen von Benutzern erscheint nun jeweils eine Beschreibung
            zu den Berechtigungen der einzelnen Rollen.
          </li>
          <li>
            Übungen innerhalb einer Lektion können nun per Drag'n'Drop sortiert
            werden.
          </li>
          <li>
            Lektionen können beim Üben entweder zufällig sortiert oder anhand
            einer vorgegebenen Reihenfolge abgespielt werden.
          </li>
        </ul>
      </div>
    )
  },
  {
    icon: ["far", "briefcase"],
    date: "28. März 18",
    title: "v.1.0 - Erste Version von wit 🐣",
    text: () => (
      <p>
        Die erste Version von wit ist Online! Momentan unterstützen wir nur{" "}
        <mark>Multiple Choice Fragen</mark> aber weitere Übungstypen werden bald
        folgen.
      </p>
    )
  }
];

/**
 * Container that displays the impressum page
 * @returns {Component} The component
 */
class Changelog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
  }
  toggle = () => this.setState({ isOpen: !this.state.isOpen });
  render() {
    return (
      <Wrapper fixed={"top"}>
        <NavbarLanding />
        <MainContainer className={"py-4"}>
          <Container>
            <Row>
              <Col md={{ size: "8", offset: "2" }} className={"mb-4"}>
                <h1 className={"mb-4"}>
                  <strong>Roadmap</strong>
                </h1>
                <p className={"lead mb-4"}>
                  Wir arbeiten ständig daran, wit zu verbessern. Falls du
                  Wünsche oder Verbesserungsvorschläge hast, dann freuen wir
                  uns, von dir zu hören:{" "}
                  <a href="mailto:hoi@witapp.io">hoi@witapp.io</a>.
                </p>
                <p>
                  Folgende Funktionen sind für die{" "}
                  <strong>nächsten Versionen</strong> von wit geplant:
                </p>
                <ul>
                  <li>
                    Erstellen von Übungen mit offenen Antworten, welche von den
                    Autoren eines Lehrgangs eingesehen werden können.
                  </li>
                  <li>Verschieben von Übungen in andere Lektionen</li>
                  <li>Verbessertes Bearbeiten von Übungen auf Smartphones</li>
                  <li>
                    Fortschritt der Übungen laufend speichern und nicht erst am
                    Schluss der Lektion
                  </li>
                  <li>
                    Benutzerprofil löschen (inkl. Lehrgänge, Lektionen, Übungen
                    und alle Fortschritte)
                  </li>
                </ul>
              </Col>
              <Col md={{ size: "8", offset: "2" }}>
                <h1 className={"mb-4"}>
                  <strong>Changelog</strong>
                </h1>
              </Col>
              <Col md={{ size: "8", offset: "2" }} className={"my-4"}>
                {content.map((item, index) => (
                  <TimelineEntry key={index}>
                    <TimelineDecoration>
                      <TimelineBubble>
                        <FontAwesomeIcon icon={item.icon} />
                      </TimelineBubble>
                      <TimelineConnection />
                    </TimelineDecoration>
                    <TimelineContent className={"py-2"}>
                      <TimelineContentDate>{item.date}</TimelineContentDate>
                      <h4 className={"mb-3"}>{item.title}</h4>
                      {item.text()}
                    </TimelineContent>
                  </TimelineEntry>
                ))}
              </Col>
            </Row>
          </Container>
        </MainContainer>
        <FooterLanding />
      </Wrapper>
    );
  }
}

export default Changelog;
