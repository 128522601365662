import { hasBlock, hasInline } from "./content";

export const DEFAULT_NODE = "paragraph";

/**
 * Toggles the current mark
 * @param {string} type The mark type
 * @returns {void}
 */
export const addMark = type => editor => {
  editor.addMark(type);
};

/**
 * Toggles the current mark
 * @param {string} type The mark type
 * @returns {void}
 */
export const removeMark = type => editor => {
  editor.removeMark(type);
};

/**
 * Toggles the current mark
 * @param {string} type The mark type
 * @param {Editor} editor the slate editor
 * @returns {void}
 */
export const toggleMark = type => editor => {
  editor.toggleMark(type);
};

/**
 * When a block button is clicked, toggle the block type.
 * @param {string} type The block type
 * @returns {void}
 */
export const toggleBlock = type => editor => {
  // Handle everything but list buttons.
  if (!type.endsWith("-list")) {
    const isActive = hasBlock(type)(editor);

    editor.setBlocks(isActive ? DEFAULT_NODE : type);
  } else {
    const isList =
      hasBlock("list-item")(editor) || hasBlock("list-item-child")(editor);

    if (isList) {
      editor.unwrapList();
    } else {
      editor.wrapList({ type });
    }
  }
};
