import { isKeyHotkey } from "is-hotkey";

/**
 * Creates an array of slate plugins based on a hotkey array
 * @param {Array} hotkeys The hotkey array
 * @returns {Array} The plugin array
 */
export const createHotkeyPlugin = hotkeys => ({
  /**
   * An onKeyDown handler for hotkeys
   * @param {Event} event The key event
   * @param {Editor} editor the editor where the hotkey was triggered
   * @param {function} next the function that is called next
   * @returns {void} the function that is called next
   */
  onKeyDown(event, editor, next) {
    // Check that the key pressed matches our `hotkey` option.
    const hotkey = hotkeys.find(({ hotkey }) => isKeyHotkey(hotkey)(event));

    if (!hotkey || !hotkey.action || !editor) {
      return next();
    }

    // Prevent the default characters from being inserted.
    event.preventDefault();
    event.stopPropagation();

    editor.command(hotkey.action);
  },
  hotkeys
});

/**
 * Toggles the given marktype on the given hotkey
 * @param {string} hotkey The hotkey
 * @param {string} action The callback function. {event, editor, change, onChange} are passed to it
 * @returns {Object} The slate plugin
 */
export const hotkey = (hotkey, action) =>
  createHotkeyPlugin([{ hotkey, action }]);
