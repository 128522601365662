import React from "react";
import styled from "styled-components";
import { push } from "react-router-redux";
import { Form, Input, InputGroup, InputGroupAddon } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

import Btn from "../components/Button";

const Search = styled(Form)`
  display: flex;
  flex: 1;
`;

/**
 * Component that renders a searchbar for the header
 * @returns {Component} the component
 */
class HeaderSearchbar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchOpen: false,
      searchQuery: null
    };
  }

  /**
   * Submits the search
   * @param {Event} event dom Event
   * @returns {void}
   */
  onSearchSubmit = event => {
    const { dispatch } = this.props;
    const { searchQuery } = this.state;
    event.stopPropagation();
    event.preventDefault();
    if (searchQuery && searchQuery.length > 2) {
      dispatch(
        push({
          pathname: "/courses/",
          search: "?search=" + encodeURIComponent(searchQuery)
        })
      );
    }
  };

  render = () => {
    const { searchQuery } = this.state;
    return (
      <Search className="form-inline mr-2" onSubmit={this.onSearchSubmit}>
        <InputGroup size="sm">
          <Input
            className="form-control"
            type="search"
            placeholder="Suche ..."
            aria-label="Search"
            data-hj-whitelist
            onChange={e =>
              this.setState({ searchQuery: e.currentTarget.value })
            }
          />
          <InputGroupAddon addonType="append">
            <Btn
              disabled={!(searchQuery && searchQuery.length > 2)}
              type="submit"
              color={"primary"}
            >
              <FontAwesomeIcon icon={["far", "search"]} />
            </Btn>
          </InputGroupAddon>
        </InputGroup>
      </Search>
    );
  };
}
const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapDispatchToProps)(HeaderSearchbar);
