import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Container, Row, Col, Card } from "reactstrap";
import { Formik } from "formik";
import yup from "yup";
import { withRouter } from "react-router";
import queryString from "query-string";
import { Link } from "react-router-dom";

import RegisterForm from "../components/forms/RegisterForm";
import Wrapper from "../components/Wrapper";
import { getIsAuthenticating } from "../reducers/index";
import { register } from "../actions/authentication";
import Button from "../components/Button";
import MainContainer from "../components/MainContainer";
import { colors } from "../utilities/style";
import Footer from "../components/Footer";

/**
 * Container that displays the registration page
 * @param {*} values props that are passed to the component
 * @returns {Component} The component
 */
class Registration extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { redirect: null };
  }
  componentWillMount(props) {
    //parse get parameters
    const { redirect } = queryString.parse(location.search);

    if (redirect) {
      this.setState({ redirect });
    }
  }
  // TODO: check that username is unique
  validationSchema = () => {
    return yup.object().shape({
      firstname: yup.string().required(),
      lastname: yup.string().required(),
      username: yup.string().required(),
      email: yup
        .string()
        .email()
        .required(),
      password: yup
        .string()
        .required()
        .min(6, "Das Passwort muss mindestens 6 Zeichen lang sein.")
    });
  };
  handleSubmit = (
    values,
    {
      props,
      setSubmitting,
      setErrors /* setValues, setStatus, and other goodies */
    }
  ) => {
    const { dispatch } = this.props;
    const { redirect } = this.state;

    dispatch(
      register(
        values.firstname,
        values.lastname,
        values.username,
        values.email,
        values.password
      )
    )
      .then(() => {
        if (redirect) {
          dispatch(push(redirect));
        } else {
          dispatch(push("/"));
        }
        setSubmitting(false);
      })
      .catch(error => {
        setSubmitting(false);
        // TODO: transform API errors to Formik's errors
        setErrors(error.errors);
      });
  };

  render = () => {
    const { redirect } = this.state;
    return (
      <Wrapper header footer>
        <MainContainer>
          <Row>
            <Col md={{ size: 4, offset: 4 }}>
              <h3 className={"mt-4 mb-2 text-center"}>Registrieren</h3>
              <p className={"mt-2 mb-4 text-center"}>
                Oh, ein neues Gesicht auf wit! 😍
              </p>
              <Card
                body
                inverse
                className={"mb-4"}
                style={{
                  backgroundColor: colors.grey_3,
                  borderColor: colors.black
                }}
              >
                <h6>
                  <strong>Jetzt wit-Pionier werden! 🚀</strong>
                </h6>
                <p className={"mb-0"}>
                  wit befindet sich momentan in der <b>Testphase</b>. Mit deiner
                  Registrierung nimmst du automatisch an unserem Testprogramm
                  teil und kannst wit komplett <b>kostenlos</b> und{" "}
                  <b>unverbindlich</b> ausprobieren.
                </p>
              </Card>
              <Card body>
                <Formik
                  initialValues={{
                    firstname: "",
                    lastname: "",
                    username: "",
                    email: "",
                    password: ""
                  }}
                  onSubmit={this.handleSubmit}
                  component={RegisterForm}
                  validationSchema={this.validationSchema}
                />
              </Card>
              <p className={"text-muted my-2 px-4"}>
                <small>
                  Mit der Registrierung akzeptierst du unsere{" "}
                  <Link to={"/privacy"}>Spielregeln</Link> und bestätigst, dass
                  du unsere{" "}
                  <Link to={"/privacy"}>Bestimmungen zum Datenschutz</Link>{" "}
                  gelesen und verstanden hast.
                </small>
              </p>
              <hr />
              <p className={"text-center"}>Du hast bereits ein Konto? 😉</p>
              <Button
                id={"toLogin"}
                color="primary"
                block
                onClick={() =>
                  this.props.dispatch(
                    push(
                      redirect
                        ? "/login?redirect=" + encodeURIComponent(redirect)
                        : "/login"
                    )
                  )
                }
              >
                Login
              </Button>
              <Button
                color="link"
                block
                onClick={() => this.props.dispatch(push("/"))}
              >
                Zurück zur Startseite
              </Button>
            </Col>
          </Row>
        </MainContainer>
        <Footer />
      </Wrapper>
    );
  };
}

const mapStateToProps = state => ({
  isAuthenticating: getIsAuthenticating(state)
});

export default connect(mapStateToProps)(Registration);
