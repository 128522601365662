import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Notifications from "react-notification-system-redux";

import { colors, shadows } from "../utilities/style";

/**
 * The customized notification systen
 * @returns {Component} The component
 */
class NotificationSystem extends React.Component {
  /**
   * Renders the component
   * @returns {Component} The component
   */
  render() {
    const { notifications } = this.props;

    //Optional styling
    const style = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "1rem",
          fontSize: "1rem",
          padding: "0.5rem 0.625rem",
          borderRadius: "3px",
          borderTopColor: colors.grey_light,
          boxShadow: shadows.y,
          color: "#333"
        },
        success: {
          backgroundColor: colors.success,
          borderTop: "1px solid" + colors.success,
          color: "#fff",
          boxShadow: shadows.y
        },
        warning: {
          backgroundColor: colors.warning,
          borderTop: "1px solid" + colors.warning,
          color: "#fff",
          boxShadow: shadows.y
        },
        error: {
          backgroundColor: colors.danger,
          borderTop: "1px solid" + colors.danger,
          color: "#fff",
          boxShadow: shadows.y
        }
      },
      Action: {
        DefaultStyle: {
          background: "#ffffff",
          borderRadius: "5px",
          fontWeight: "bold",
          margin: "0.5rem 0"
        },
        success: {
          backgroundColor: "#ffffff",
          color: colors.success
        },

        error: {
          backgroundColor: "#ffffff",
          color: colors.danger
        },

        warning: {
          backgroundColor: "#ffffff",
          color: colors.warning
        }
      },
      ActionWrapper: {
        DefaultStyle: {
          margin: "0 auto",
          textAlign: "center"
        }
      }
    };

    return <Notifications notifications={notifications} style={style} />;
  }
}

NotificationSystem.contextTypes = {
  store: PropTypes.object
};

NotificationSystem.propTypes = {
  notifications: PropTypes.array
};

export default connect(state => ({ notifications: state.notifications }))(
  NotificationSystem
);
