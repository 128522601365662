import React from "react";
import { FormGroup, Label, Alert } from "reactstrap";
import { Form } from "formik";

import InputField from "../input/InputField";
import Spinner from "../Spinner";
import Flexbar from "../Flexbar";
import Button from "../Button";
import Btn from "../Button";
import WarnOnLeaving from "../WarnOnLeaving";

/**
 * Renders the profile form
 * @param {Object} formikProps The formik props
 * @returns {Component} The component
 */
const ProfileForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting
}) => (
  <Form>
    <FormGroup>
      <Label for="name">Vorname</Label>
      <InputField
        type="text"
        name="firstname"
        value={values.firstname}
        placeholder="Wolfgang"
        onChange={handleChange}
      />
    </FormGroup>
    <FormGroup>
      <Label for="lastname">Nachname</Label>
      <InputField type="text" name="lastname" placeholder="Goethe" />
    </FormGroup>
    <FormGroup>
      <Label for="email">E-Mail</Label>
      <InputField type="email" name="email" placeholder="wolfang@goethe.ch" />
    </FormGroup>
    <FormGroup>
      <Label for="username">Benutzername</Label>
      <InputField type="text" name="username" placeholder="Goethe49" disabled />
    </FormGroup>

    <Btn color="primary" type="submit" block loading={isSubmitting}>
      Profil aktualisieren
    </Btn>

    <WarnOnLeaving />
  </Form>
);
export default ProfileForm;
