import {
  createFetchActionFactory,
  createFetchSingleItemActionFactory,
  createFetchSingleItemThunkCreator,
  createFetchItemsActionFactory,
  createFetchItemPageActionFactory,
  createFetchAllItemsThunkFactory,
  createFetchItemsPageThunkFactory,
  createCreateItemActionCreator,
  createCreateItemThunkCreator,
  createUpdateItemActionCreator,
  createUpdateItemThunkCreator,
  createDeleteItemActionCreator,
  createDeleteItemThunkCreator,
  createFetchItemsThunkFactory
} from "../utilities/action";
import { getAuthenticatedUser } from "../reducers";
import { fetchApi } from "../utilities/api";

export const itemName = "analyticsUserProgress";

/**
 * Maps an item so we can store it in the state
 * @param {Object} item The item to map
 * @returns {Object} The mapped item
 */
export const mapItem = ({
  id,
  username,
  firstname,
  lastname,
  correct,
  once,
  wrong,
  hard,
  unviewed,
  total,
  tries,
  type
}) => ({
  id,
  username,
  firstname,
  lastname,
  correct,
  once,
  wrong,
  hard,
  unviewed,
  total,
  tries,
  type
});

/**
 * Action called before and after fetching multiple items
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} items The received items
 * @returns {object} The redux action
 */
export const fetchAction = createFetchItemsActionFactory(
  itemName,
  "courseSlug",
  "lessonId"
);

/**
 * Fetches all items
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} [lessonId] The id of the lesson
 * @returns {function} The redux thunk
 */
export const fetchAnalyticsUserProgress = createFetchItemsThunkFactory(
  fetchAction,
  (courseSlug, lessonId) =>
    `/api/courses/${courseSlug}/analytics/users-progress${
      lessonId ? `?lessonId=${lessonId}` : ""
    }`,
  mapItem
);
