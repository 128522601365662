import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col } from "reactstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MainContainer from "../components/MainContainer";
import Wrapper from "../components/Wrapper";
import NavbarLanding from "./landing/NavbarLanding";
import FooterLanding from "./landing/FooterLanding";
import FeatureVideo1 from "../assets/images/feature_video_1.png";
import FeatureVideo1Retina from "../assets/images/feature_video_1@2x.png";
import FeatureVideo2 from "../assets/images/feature_video_2.png";
import FeatureVideo2Retina from "../assets/images/feature_video_2@2x.png";
import FeatureVideo3 from "../assets/images/feature_video_3.png";
import FeatureVideo3Retina from "../assets/images/feature_video_3@2x.png";
import FeatureVideo4 from "../assets/images/feature_video_4.png";
import FeatureVideo4Retina from "../assets/images/feature_video_4@2x.png";
import ConversationImage from "../assets/images/conversation.svg";

const ScreenMockup = styled.div`
  border-radius: 3px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

const Masthead = styled.div`
  background: #f2f2f2;
  height: 30px;
  width: 100%;
  display: flex;
  padding: 0.625rem;
  flex: 1;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  align-items: center;
  flex-direction: row;
`;

const Urlbar = styled.div`
  background: #e5e5e5;
  flex: 1;
  border-radius: 5px;
  height: 10px;
  margin-left: 20px;
`;
const Dot = styled.div`
  background: #e5e5e5;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 6px;
`;

const GradientBg = styled.div`
  background: linear-gradient(
    117.18deg,
    #25d641 0%,
    #25d69a 35.58%,
    #25c1d6 115.04%
  );
`;

const FeatureVideo = styled.div`
  background: #fafafa;
  box-shadow: 0px 4px 4px rgba(11, 12, 44, 0.05);
  border-radius: 5px;
  img {
    border-radius: 5px;
  }
`;

/**
 * Container that displays the landing page
 * @returns {Component} The component
 */
class Landing extends React.PureComponent {
  render() {
    return (
      <Wrapper fixed={"top"}>
        <NavbarLanding />
        <MainContainer className={"py-4"} id={"home"}>
          <Container>
            <Row>
              <Col
                md={{ size: "5" }}
                className={"flex-column justify-content-center  d-flex"}
              >
                <h2 className={"mb-4"}>
                  <strong>
                    Interaktive Lerneinheiten
                    <br /> gemeinsam erstellen.
                  </strong>
                </h2>
                <p className="lead pr-2">
                  wit ist der einfachste und schnellste Weg, Lernmaterialien
                  gemeinsam zu erstellen und effizient zu lernen.
                </p>
                <Link to={"/courses/welcome"}>
                  <Button color="primary" className={"my-3"}>
                    Demo-Lehrgang ansehen
                  </Button>
                </Link>
              </Col>
              <Col md={{ size: "7" }}>
                <ScreenMockup className={"landing-screen"}>
                  <Masthead>
                    <Dot />
                    <Dot />
                    <Dot />
                    <Urlbar />
                  </Masthead>
                  <div className={"mx-2"}>
                    <div className={"embed-responsive embed-responsive-16by9"}>
                      <iframe
                        src="https://www.youtube.com/embed/qXSwgn8JBTw?rel=0&amp;controls=0&amp;showinfo=0&autoplay=1&modestbranding=1&color=white"
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                      />
                    </div>
                  </div>
                </ScreenMockup>
              </Col>
            </Row>
          </Container>
        </MainContainer>
        <div className={"py-4 bg-white"}>
          <Container className={"py-4"}>
            <Row>
              <Col md={{ size: "6", offset: "3" }} className={"text-center"}>
                <h2>
                  <strong>
                    Eigener Online-Lehrgang <br />– in weniger als 5 Minuten
                  </strong>
                </h2>
                <p className={"my-3"}>
                  Mit wit ist das Erstellen, Bearbeiten und Verwalten von
                  Übungsinhalten ein Kinderspiel. Du verbringst so weniger Zeit
                  mit dem Einrichten und kannst dich auf deine Inhalte
                  konzentrieren.
                </p>
                <Link to={"/register"}>
                  <Button color="primary" className={"my-2"}>
                    Kostenlos registrieren
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={"pb-4 bg-white"}>
          <Container className={"py-4"}>
            <Row>
              <Col md={{ size: "5", offset: "1" }}>
                <FeatureVideo>
                  <img
                    className={"img-fluid"}
                    src={FeatureVideo1}
                    srcset={
                      FeatureVideo1 + " 1x," + FeatureVideo1Retina + " 2x"
                    }
                  />
                </FeatureVideo>
              </Col>
              <Col
                md={{ size: "5" }}
                className={
                  "text-left flex-column justify-content-center d-flex"
                }
              >
                <h4>
                  <strong>
                    👫👬
                    <br />
                    Gemeinsam Lerninhalte erstellen
                  </strong>
                </h4>
                <p className={"my-1"}>
                  Gliedere deine Inhalte in Lehrgänge und Lektionen und lade
                  Freunde und Interessierte ein, die dir beim Erstellen helfen.
                  Du behältst dabei die volle Kontrolle, wer Inhalte bearbeiten
                  kann und wer nicht.
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                md={{ size: "5", offset: "1" }}
                className={
                  "text-right flex-column justify-content-center d-flex"
                }
              >
                <h4>
                  <strong>
                    🎨
                    <br />
                    Übungen, so vielfältig wie das Leben
                  </strong>
                </h4>
                <p className={"my-1"}>
                  Wähle die passende Übungsform für deine Inhalte: Single- und
                  Multiple-Choice-Fragen, Lernkärtchen, Textaufgaben. Formatiere
                  deine Übungen so, wie es dir gefällt. Verwende Bilder, Links,
                  Aufzählungen, Code und LaTeX-Formeln.
                </p>
              </Col>
              <Col md={{ size: "5" }} className={"py-4"}>
                <FeatureVideo>
                  <img
                    className={"img-fluid"}
                    src={FeatureVideo2}
                    srcSet={
                      FeatureVideo2 + " 1x," + FeatureVideo2Retina + " 2x"
                    }
                  />
                </FeatureVideo>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: "5", offset: "1" }} className={"py-4"}>
                <FeatureVideo>
                  <img
                    className={"img-fluid"}
                    src={FeatureVideo3}
                    srcSet={
                      FeatureVideo3 + " 1x," + FeatureVideo3Retina + " 2x"
                    }
                  />
                </FeatureVideo>
              </Col>
              <Col
                md={{ size: "5" }}
                className={
                  "text-left flex-column justify-content-center d-flex"
                }
              >
                <h4>
                  <strong>
                    🔒
                    <br />
                    Psst, etwas Diskretion bitte!
                  </strong>
                </h4>
                <p className={"my-1"}>
                  Du bestimmst bei jedem Lehrgang selbst, ob dieser privat oder
                  öffentlich ist. Bei privaten Lehrgängen entscheidest du, wer
                  deine Inhalte sehen kann. Neue Mitglieder kannst du bequem per
                  Link einladen.
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                md={{ size: "5", offset: "1" }}
                className={
                  "text-right flex-column justify-content-center d-flex"
                }
              >
                <h4>
                  <strong>
                    🎯
                    <br />
                    Fortschritt und Lernerfolgskontrolle
                  </strong>
                </h4>
                <p className={"my-1"}>
                  wit merkt sich den individuellen Lernstand: Was wurde richtig
                  beantwortet, was falsch und wo sollte noch mehr Zeit
                  investiert werden.
                </p>
              </Col>
              <Col md={{ size: "5" }} className={"py-4"}>
                <FeatureVideo>
                  <img
                    className={"img-fluid"}
                    src={FeatureVideo4}
                    srcSet={
                      FeatureVideo4 + " 1x," + FeatureVideo4Retina + " 2x"
                    }
                  />
                </FeatureVideo>
              </Col>
            </Row>
            <Row className={"mt-4 pt-4"}>
              <Col md={{ size: "6" }} lg={{ size: "4" }} className={"py-2"}>
                <h5>
                  <FontAwesomeIcon
                    icon={["fal", "alarm-clock"]}
                    className={"mr-2 text-primary"}
                  />
                  <strong>Mehr Zeit für das Wesentliche</strong>
                </h5>
                <p>
                  Die Inhalte auf wit können von SchülerInnen im Selbststudium
                  erarbeitet und geübt werden. Dadurch werden im Unterricht neue
                  Freiräume geschaffen.
                </p>
              </Col>
              <Col md={{ size: "6" }} lg={{ size: "4" }} className={"py-2"}>
                <h5>
                  <FontAwesomeIcon
                    icon={["fal", "users"]}
                    className={"mr-2 text-primary"}
                  />
                  <strong>Gemeinsam statt einsam</strong>
                </h5>
                <p>
                  Zusammen geht vieles einfacher: wit ermöglicht die
                  Zusammenarbeit innerhalb und ausserhalb der Organisation.
                </p>
              </Col>
              <Col md={{ size: "6" }} lg={{ size: "4" }} className={"py-2"}>
                <h5>
                  <FontAwesomeIcon
                    icon={["fal", "globe"]}
                    className={"mr-2 text-primary"}
                  />
                  <strong>Zeit- und ortsunabhängig</strong>
                </h5>
                <p>
                  Mit wit sind alle Inhalte jederzeit und von überall erreichbar
                  und lassen sich per Smartphone, Tablet oder PC / Laptop üben
                  und bearbeiten.
                </p>
              </Col>
              <Col md={{ size: "6" }} lg={{ size: "4" }} className={"py-2"}>
                <h5>
                  <FontAwesomeIcon
                    icon={["fal", "file-check"]}
                    className={"mr-2 text-primary"}
                  />
                  <strong>Korrigieren war gestern</strong>
                </h5>
                <p>
                  Die Übungen auf wit korrigieren sich von selbst.
                  Kontextgebundene Erklärungen helfen zudem dabei, die Lösungen
                  zu verstehen.
                </p>
              </Col>
              <Col md={{ size: "6" }} lg={{ size: "4" }} className={"py-2"}>
                <h5>
                  <FontAwesomeIcon
                    icon={["fal", "smile"]}
                    className={"mr-2 text-primary"}
                  />
                  <strong>Einfach ist besser</strong>
                </h5>
                <p>
                  Während andere LMS auf unzählige Funktionen setzen,
                  konzentrieren wir bei wit uns auf das Wesentliche: ein
                  einfaches und effizientes Lernerlebnis.
                </p>
              </Col>
              <Col md={{ size: "6" }} lg={{ size: "4" }} className={"py-2"}>
                <h5>
                  <FontAwesomeIcon
                    icon={["fal", "comments-alt"]}
                    className={"mr-2 text-primary"}
                  />
                  <strong>Im Dialog entwickelt</strong>
                </h5>
                <p>
                  wit wird in enger Zusammenarbeit mit Lehrpersonen,
                  Bildungsinstitutionen und SchülerInnen und Studierenden stetig
                  weiterentwickelt.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <MainContainer>
          <Container>
            <Row>
              <Col
                md={{ size: "6", offset: "3" }}
                className={"pt-4 text-center"}
              >
                <h2>
                  <strong>
                    Überall dort im Einsatz, <br />
                    wo es um Bildung geht
                  </strong>
                </h2>
                <p className={"my-3 lead"}>
                  wit ist intuitiv und modular aufgebaut und lässt sich in den
                  verschiedensten Bildung- und Lernkontexten einsetzen.
                </p>
              </Col>
            </Row>
            <Row className={"py-4"}>
              <Col md={{ size: "4" }} className={"my-4"}>
                <h5>
                  <strong>Im Unterricht</strong>
                </h5>
                <p className={"my-1"}>
                  Mit wit werden SchülerInnen Co-Autoren der Inhalte und können
                  selbstbestimmt und individuell Inhalte erarbeiten. Durch die
                  automatische Korrektur der Übungen inkl. Erklärungen zu den
                  korrekten/falschen Lösungen entlastet wit die Lehrpersonen im
                  Unterrichtsalltag.
                </p>
              </Col>
              <Col md={{ size: "4" }} className={"my-4"}>
                <h5>
                  <strong>In der Weiterbildung</strong>
                </h5>
                <p className={"my-1"}>
                  Einmal eingearbeitet, lassen sich die Inhalte für beliebig
                  viele Klassen / Kurse / Jahrgänge verwenden. Wit ermöglicht
                  die individuelle Vor- und Nachbereitung der
                  Weiterbildungsinhalte und unterstützt die Teilnehmer so bei
                  der Vertiefung der Inhalte und der Prüfungsvorbereitung.
                </p>
              </Col>
              <Col md={{ size: "4" }} className={"my-4"}>
                <h5>
                  <strong>Im Studium</strong>
                </h5>
                <p className={"my-1"}>
                  Die interaktiven Übungseinheiten eignen sich optimal zur
                  Ergänzung von Vorlesungsinhalten und ermöglichen den
                  Studierenden, die Inhalte im eigenen Tempo aufzuarbeiten.
                </p>
              </Col>
            </Row>
          </Container>
        </MainContainer>
        <div className={"py-4 bg-white"}>
          <Container className={"py-4"}>
            <Row>
              <Col
                md={{ size: "6" }}
                className={"flex-column justify-content-center d-flex"}
              >
                <h2>
                  <strong>Ist wit etwas für dich?</strong>
                </h2>
                <p className={"my-3"}>
                  Du bist dir nicht sicher, wie du wit in deinen Unterricht
                  einbinden kannst?
                  <br />
                  Du benötigst Hilfe bei der Einarbeitung deiner bestehenden
                  Materialien?
                  <br />
                  <br />
                  Unsere Experten unterstützen dich bei der Einrichtung und
                  beraten dich gerne, wenn es um inhaltliche oder technische
                  Fragen geht.
                  <br />
                  <br />
                  <Button color="primary" className={"my-2 drift-open-chat"}>
                    Support-Chat starten
                  </Button>
                </p>
              </Col>
              <Col
                md={{ size: "6" }}
                className={
                  "text-center flex-column justify-content-center d-flex"
                }
              >
                <div>
                  <img src={ConversationImage} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterLanding />
      </Wrapper>
    );
  }
}

export default Landing;
