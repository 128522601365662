import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import {
  renderBlockButton,
  renderMarkButton,
  renderNode
} from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { toggleBlock } from "../helpers/handlers";
import { hasBlock } from "../helpers/content";

/**
 * Converts the selected text to a bulleted list
 */
class BulletedListMarkButton extends AntonButton {
  name = "bulleted-list-mark";
  icon = <FontAwesomeIcon icon={["far", "list-ul"]} />;
  onMouseDown = toggleBlock("bulleted-list");
  tooltip = {
    target: "bulleted-list-mark",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Zu ungeordneter Liste konvertieren"
  };
}

/**
 * Inserts a bulleted list
 */
class BulletedListBlockButton extends AntonButton {
  name = "bulleted-list-block";
  icon = <FontAwesomeIcon icon={["far", "list-ul"]} />;
  onMouseDown = toggleBlock("bulleted-list");
  tooltip = {
    target: "bulleted-list-block",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Ungeordnete Liste einfügen"
  };
}


/**
 * The Bulleted List Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const BulletedList = options =>
  Object.assign(
    renderMarkButton("bulleted-list", BulletedListMarkButton),
    renderBlockButton("bulleted-list", BulletedListBlockButton),
    {
      isActive: hasBlock("bulleted-list")
    }
  );

export default BulletedList;
