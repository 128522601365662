import moment from "moment";

import {
  createFetchActionFactory,
  createFetchSingleItemActionFactory,
  createFetchSingleItemThunkCreator,
  createFetchItemsActionFactory,
  createFetchItemPageActionFactory,
  createFetchAllItemsThunkFactory,
  createFetchItemsPageThunkFactory,
  createCreateItemActionCreator,
  createCreateItemThunkCreator,
  createUpdateItemActionCreator,
  createUpdateItemThunkCreator,
  createDeleteItemActionCreator,
  createDeleteItemThunkCreator,
  createFetchItemsThunkFactory
} from "../utilities/action";
import { getAuthenticatedUser } from "../reducers";
import { fetchApi } from "../utilities/api";

export const itemName = "analyticsAggregatedProgress";

/**
 * Maps an item so we can store it in the state
 * @param {Object} item The item to map
 * @returns {Object} The mapped item
 */
export const mapItem = ({
  correct,
  wrong,
  total,
  aggregation,
  year,
  month,
  date = moment(`${year}-${month}-${day}`, "YYYY-M-D"),
  week = null,
  day = null
}) => {
  const obj = {
    id: `${year}-${month}-${day}`,
    correct,
    wrong,
    total,
    aggregation,
    year,
    month,
    week,
    day,
    date
  };
  switch (aggregation) {
    case "MONTHLY":
      return { ...obj, dateString: date.format("MMM YY") };
    case "WEEKLY":
      date = moment(`${year}W${week}`);
      return {
        ...obj,
        date,
        dateString: ["Woche " + date.format("ww"), date.format("MMM YY")]
      };
    case "DAILY":
      date = moment(`${year}-${month}-${day}`, "YYYY-M-D");
      return {
        ...obj,
        date,
        dateString: [date.format("ddd"), date.format("D.MM.YY")]
      };
  }
};

/**
 * Action called before and after fetching multiple items
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} items The received items
 * @returns {object} The redux action
 */
export const fetchAction = createFetchItemsActionFactory(
  itemName,
  "courseSlug",
  "lessonId"
);

/**
 * Fetches all items
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} [lessonId] The id of the lesson
 * @returns {function} The redux thunk
 */
export const fetchAnalyticsAggregatedProgress = createFetchItemsThunkFactory(
  fetchAction,
  (courseSlug, lessonId, startDate, endDate) =>
    `/api/courses/${courseSlug}/analytics/aggregated-progress?startDate=
    ${startDate}
    ${endDate ? `&endDate=${endDate}` : ""}
    ${lessonId ? `&lessonId=${lessonId}` : ""}`,
  mapItem
);
