import {
  createFetchItemsActionFactory,
  createCreateItemActionCreator,
  createCreateItemThunkCreator,
  createFetchItemsThunkFactory,
  createRemoveSingleItemActionCreator,
  createRemoveSingleItemThunkCreator,
  createFetchActionFactory,
  createFetchSingleItemActionFactory,
  createFetchSingleItemThunkCreator
} from "../utilities/action";

export const itemName = "courseInvitation";

/**
 * Maps an item so we can store it in the state
 * @param {Object} item The item to map
 * @returns {Object} The mapped item
 */
export const mapItem = ({
  id,
  status,
  creator,
  invitee,
  type,
  link,
  user_role,
  course_id,
  created_at,
  updated_at
}) => ({
  id,
  status,
  creator,
  invitee,
  type,
  link: link ? link : null,
  userRole: user_role,
  courseId: course_id,
  createdAt: created_at,
  updatedAt: updated_at,
  savedAt: updated_at
});

/**
 * Action called before and after creating a new item
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} item The item that should be / was created
 * @returns {object} The redux action
 */
export const createItem = createCreateItemActionCreator(itemName);

/**
 * Creates a new item
 * @param {object} item The item's data
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} The redux thunk
 */
export const create = createCreateItemThunkCreator(
  createItem,
  (item, courseId) => `/api/courses/${courseId}/invitations`,
  mapItem
);

export const removeItem = createRemoveSingleItemActionCreator(itemName);

export const remove = createRemoveSingleItemThunkCreator(
  removeItem,
  (itemId, courseId) => `/api/courses/${courseId}/invitations/${itemId}`
);

/**
 * Action called before and after fetching all items
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} items The received items
 * @returns {object} The redux action
 */
export const fetchAction = createFetchItemsActionFactory(itemName);

/**
 * Fetches all invitations from the specified course
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {string} courseId id of the course whose invitations should be fetched
 * @returns {function} The redux thunk
 */
export const fetch = createFetchItemsThunkFactory(
  fetchAction,
  courseId => `/api/courses/${courseId}/invitations`,
  mapItem
);

/**
 * Receives a new user role for the specified course
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} the invitation that was checked
 * @returns {object}
 */
const checkCourseInvitationAction = createFetchActionFactory(
  "CHECK_COURSE_INVITATION",
  "invitation"
);

export const checkCourseInvitation = createFetchSingleItemThunkCreator(
  checkCourseInvitationAction,
  (courseInvitationToken, courseId) =>
    `/api/courses/${courseId}/invitations/${courseInvitationToken}`,
  mapItem
);
