import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from "styled-components";

import {deserializeBlock, renderBlockButton, renderMarkButton, renderNode} from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { toggleBlock } from "../helpers/handlers";
import { hasBlock } from "../helpers/content";
import { markdownBlockSequence } from "../helpers/markdown";
import { colors } from "../../../utilities/style";

/**
 * Converts the selected text to a quote
 */
class QuoteMarkButton extends AntonButton {
  name = "quote-mark";
  icon = <FontAwesomeIcon icon={["far","quote-left"]} />;
  onMouseDown = toggleBlock("quote");
  tooltip = {
    target: "quote-mark",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Zitat einfügen"
  }
}

/**
 * Converts the selected text to a quote
 */
class QuoteBlockButton extends AntonButton {
  name = "quote-block";
  icon = <FontAwesomeIcon icon={["far","quote-left"]} />;
  onMouseDown = toggleBlock("quote");
  tooltip = {
    target: "quote-block",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Zitat einfügen"
  }
}

export const StyledBlockquote = styled.blockquote`
  && {
    padding-left: 1rem;
    border-left: 2px solid ${colors.grey_medium};
    font-size: 1rem;
    font-family: 'Source Serif Pro', serif;
  }
`;

/**
 * Renders a quote
 */
class QuoteMark extends React.PureComponent {
  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { children, attributes } = this.props;

    return (
      <StyledBlockquote className={"blockquote"} {...attributes}>
        {children}
      </StyledBlockquote>
    );
  };
}

/**
 * The Quote Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const Quote = (options) => Object.assign(
  renderNode("quote", QuoteMark),
  deserializeBlock("quote","blockquote"),
  renderMarkButton("quote-block",QuoteMarkButton),
  renderBlockButton("quote-block",QuoteBlockButton),
  markdownBlockSequence("quote", ">"),
  {
    isActive: hasBlock("quote"),
  }
);

export default Quote;
