import React from "react";
import { connect } from "formik";
import PropTypes from "prop-types";

/**
 * Warns the user if there is unsaved content
 * @returns {Component} The auto save component;
 */
class WarnOnLeaving extends React.PureComponent {
  static propTypes = {
    formik: PropTypes.object
  };

  /**
   * Called when the window should be closed
   * @param {Event} e The close event
   * @returns {string} The confirmation message
   */
  onUnload = e => {
    const { formik } = this.props;
    if (
      !formik.dirty ||
      Object.keys(formik.touched).filter(key => formik.touched[key]).length ===
        0
    ) {
      return;
    }

    const confirmationMessage =
      "Es sieht so aus als wärst du noch mitten im Bearbeiten." +
      "Beim Verlassen der Seite gehen alle ungespeicherten Änderungen verloren.";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  };

  componentDidMount = () => {
    window.addEventListener("beforeunload", this.onUnload);
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.onUnload);
  };

  render = () => {
    return null;
  };
}

export default connect(WarnOnLeaving);
