import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table
} from "reactstrap";
import { push } from "react-router-redux";
import { error, success } from "react-notification-system-redux";
import styled from "styled-components";
import queryString from "query-string";
import { Bar, Chart } from "react-chartjs-2";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

import MainContainer from "../components/MainContainer";
import Wrapper from "../components/Wrapper";
import Header from "./Header";
import { fetchAnalyticsUserProgress } from "../actions/analyticsUserProgress";
import {
  getAnalyticsAggregatedProgress,
  getAnalyticsUserProgress
} from "../reducers";
import Progressbar from "../components/Progressbar";
import { getRoleName } from "../utilities/policies";
import { fetchCourse } from "../actions/course";
import { getCourseBySlug } from "../reducers";
import { getCourseLessons } from "../reducers";
import { fetchAnalyticsAggregatedProgress } from "../actions/analyticsAggregatedProgress";
import { colors } from "../utilities/style";
import DateRangePickerWrapper from "../components/DateRangePickerWrapper";

const CustomTable = styled(Table)`
  && thead th {
    border-top: 0px;
    border-bottom: 0px;
  }
  && {
    margin-bottom: 0;
  }
`;

/**
 * Returns the content of the lessonId url parameter
 * @returns {number|null} the lessonId or null if there is none
 */
const getLessonId = () => {
  const parsed = queryString.parse(window.location.search);
  return parsed.lessonId ? parseInt(parsed.lessonId) : null;
};
/**
 * Returns the content of the startDate url parameter
 * @returns {string|null} the startDate or null if there is none
 */
const getStartDate = () => {
  const parsed = queryString.parse(window.location.search);
  return parsed.startDate ? parseInt(parsed.startDate) : null;
};
/**
 * Returns the content of the endDate url parameter
 * @returns {string|null} the endDate or null if there is none
 */
const getEndDate = () => {
  const parsed = queryString.parse(window.location.search);
  return parsed.endDate ? parseInt(parsed.endDate) : null;
};

const URL_DATE_FORMAT = "YYYYMMDD";

const StyledCalendar = styled.div`
  .CalendarDay__default {
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover,
  .CalendarDay__selected_span {
    background: ${colors.success};
    border: 1px double ${colors.success};
    color: #fff;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: rgba(35, 205, 147, 0.1);
    border: 1px double rgba(35, 205, 147, 0.4);
    color: ${colors.success};
  }
`;

/**
 * Used to show a small bar on the chart if the value is 0
 *
 * @type Object
 */
const zeroCompensation = {
  /**
   * Function that renders a line for every element in the dataset that is zero
   * @param {any} chartInstance the instance of the chartjs chart
   * @param {any} d display
   * @returns {void}
   */
  renderZeroCompensation: function(chartInstance, d) {
    // get postion info from _view
    const view = d._view;
    const context = chartInstance.chart.ctx;

    // the view.x is the central point of the bar, so we need minus half width of the bar.
    const startX = view.x - view.width / 2;
    // common canvas API, Check it out on MDN
    context.beginPath();
    // set line color, you can do more custom settings here.
    context.strokeStyle = "#aaaaaa";
    context.lineWidth = 3;
    context.moveTo(startX, view.y);
    // draw the line!
    context.lineTo(startX + view.width, view.y);
    // bam！ you will see the lines.
    context.stroke();
  },
  /**
   * Called after the dataset has been drawn
   * @param {obj} chart the chart
   * @param {any} easing the easing
   * @returns {void}
   */
  afterDatasetsDraw: function(chart, easing) {
    // get data meta, we need the location info in _view property.
    const meta = chart.getDatasetMeta(0);
    // also you need get datasets to find which item is 0.
    const dataSet = chart.config.data.datasets[0].data;
    meta.data.forEach((d, index) => {
      // for the item which value is 0, render a line.
      if (dataSet[index] === 0) {
        this.renderZeroCompensation(chart, d);
      }
    });
  }
};

/**
 * The container for displaying analytics
 * @returns {Component} The component
 */
class Analytics extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lessonId: props.lessonId,
      loading: true,
      aggregatedLoading: true,
      startDate: getStartDate()
        ? moment(getStartDate(), URL_DATE_FORMAT)
        : moment(),
      endDate: getEndDate() ? moment(getEndDate(), URL_DATE_FORMAT) : moment()
    };
  }

  /**
   * Function that's called when the date in the datepicker is changed
   * @param {momentObj} startDate the start Date
   * @param {momentObj} endDate the end Date
   * @returns {void}
   */
  onDateChange = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      const { gotoAnalyticsPage } = this.props;
      this.setState({ startDate, endDate, aggregatedLoading: true });
      gotoAnalyticsPage(
        startDate.format(URL_DATE_FORMAT),
        endDate.format(URL_DATE_FORMAT),
        getLessonId()
      ).then(() => this.setState({ aggregatedLoading: false }));
    }
  };

  /**
   * Called when a new date is set
   * @param {momentObj} startDate the start Date
   * @param {momentObj} endDate the end Date
   * @returns {void}
   */
  setNewDates = (startDate, endDate) => {
    this.setState({ startDate, endDate });
    this.onDateChange({ startDate, endDate });
  };

  componentDidMount = () => {
    const {
      fetchAnalyticsUserProgress,
      fetchAnalyticsAggregatedProgress,
      course,
      fetchCourse
    } = this.props;
    if (!course.id) {
      fetchCourse();
    }

    Chart.pluginService.register(zeroCompensation);
    fetchAnalyticsAggregatedProgress(
      this.state.startDate.format(URL_DATE_FORMAT),
      this.state.endDate.format(URL_DATE_FORMAT),
      getLessonId(),
      true
    ).then(this.setState({ aggregatedLoading: false }));
    fetchAnalyticsUserProgress(getLessonId()).then(
      this.setState({ loading: false })
    );
  };

  render = () => {
    const {
      analyticsAggregatedProgress,
      analyticsUserProgress,
      lessons,
      gotoAnalyticsPage,
      course
    } = this.props;
    const selectedLesson = lessons.find(lesson => lesson.id === getLessonId());
    const { selectedDate, aggregatedLoading, startDate, endDate } = this.state;
    const maxValue = Math.max(
      ...analyticsAggregatedProgress.map(o => o.total),
      0
    );
    const chartData = {
      labels: analyticsAggregatedProgress.map(el => el.dateString),
      datasets: [
        {
          label: "Falsch beantwortet",
          backgroundColor: colors.danger,
          borderWidth: 0,
          stack: "first",
          data: analyticsAggregatedProgress.map(el => el.wrong)
        },
        {
          label: "Richtig beantwortet",
          backgroundColor: colors.success,
          borderWidth: 0,
          stack: "first",
          data: analyticsAggregatedProgress.map(el => el.correct)
        }
      ]
    };

    return (
      <Wrapper>
        <Header />
        <MainContainer>
          <Container>
            <Row>
              <Col md={{ size: 12, offset: 0 }}>
                <div className={"d-flex"}>
                  <h2 className={"mr-auto"}>Auswertung</h2>
                  <StyledCalendar>
                    <DateRangePickerWrapper
                      showDefaultInputIcon
                      startDate={startDate} // momentPropTypes.momentObj or null,
                      startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                      endDate={endDate} // momentPropTypes.momentObj or null,
                      endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                      onDatesChange={this.onDateChange} // PropTypes.func.isRequired,
                      hideKeyboardShortcutsPanel={true}
                      small={true}
                      isOutsideRange={date => date.isAfter(moment())}
                      minimumNights={0}
                      numberOfMonths={2}
                      initialVisibleMonth={() => moment().subtract(1, "month")}
                      verticalSpacing={0}
                      daySize={30}
                      setNewDates={this.setNewDates}
                    />
                  </StyledCalendar>
                  <UncontrolledDropdown>
                    <DropdownToggle caret color="link">
                      {selectedLesson
                        ? selectedLesson.title
                        : course && course.name
                        ? course.name
                        : "Laden ..."}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() =>
                          gotoAnalyticsPage(
                            startDate.format(URL_DATE_FORMAT),
                            endDate.format(URL_DATE_FORMAT)
                          )
                        }
                      >
                        {course && course.name ? course.name : "Laden ..."}
                      </DropdownItem>
                      <DropdownItem header>Lektionen</DropdownItem>
                      {lessons.map(lesson => (
                        <DropdownItem
                          key={lesson.id}
                          onClick={() =>
                            gotoAnalyticsPage(
                              startDate.format(URL_DATE_FORMAT),
                              endDate.format(URL_DATE_FORMAT),
                              lesson.id
                            )
                          }
                        >
                          {lesson.title}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <hr />
                <Card className={"mb-4"}>
                  <CardBody>
                    {!aggregatedLoading ? (
                      analyticsAggregatedProgress &&
                      analyticsAggregatedProgress.length > 0 ? (
                        <Bar
                          data={chartData}
                          height={250}
                          options={{
                            maintainAspectRatio: false,
                            scales: {
                              xAxes: [
                                {
                                  barPercentage: 0.5
                                }
                              ],
                              yAxes: [
                                {
                                  stacked: true,
                                  ticks: {
                                    min: 0,
                                    suggestedMax: maxValue + 2
                                  }
                                }
                              ]
                            }
                          }}
                        />
                      ) : (
                        <p className={"text-center p-4 text-secondary"}>
                          In dem ausgewählten Zeitraum wurden keine Übungen
                          gelöst.
                        </p>
                      )
                    ) : (
                      <p className={"text-center p-4 text-secondary"}>
                        Daten werden geladen...
                      </p>
                    )}
                  </CardBody>
                </Card>
                <Card>
                  <CustomTable responsive className={""}>
                    <thead>
                      <tr>
                        <th style={{ minWidth: "15rem" }}>User</th>
                        <th style={{ minWidth: "30rem" }}>Fortschritt</th>
                        <th style={{ minWidth: "10rem" }}>Übungen gelöst</th>
                      </tr>
                    </thead>
                    <tbody>
                      {analyticsUserProgress.map(item => (
                        <tr key={item.id}>
                          <th scope="row">
                            {item.username}
                            <span className="badge badge-secondary mx-1">
                              {getRoleName(item.type)}
                            </span>
                            <br />
                            <small>
                              {item.firstname} {item.lastname}
                            </small>
                          </th>
                          <td className={"align-middle"}>
                            <Progressbar
                              id={item.id}
                              value={{
                                hard: item.hard,
                                unviewed: item.unviewed,
                                once: item.once,
                                correct: item.correct,
                                wrong: item.wrong
                              }}
                            />
                          </td>
                          <td className={"align-middle"}>
                            {item.tries ? item.tries : 0}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </CustomTable>
                </Card>
              </Col>
              <Col md={{ size: 8, offset: 2 }} />
            </Row>
          </Container>
        </MainContainer>
      </Wrapper>
    );
  };
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { courseSlug, lessonId }
    }
  }
) => {
  const course = getCourseBySlug(state, courseSlug) || {};
  return {
    id: parseInt(lessonId),
    analyticsUserProgress: getAnalyticsUserProgress(state) || {},
    analyticsAggregatedProgress: getAnalyticsAggregatedProgress(state) || {},
    course: course,
    lessons: course ? getCourseLessons(state, course.id) : {}
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { courseSlug }
    }
  }
) => ({
  dispatch,
  /**
   * Loads a new page from the server
   * @param {number} [lessonId] The search query
   * @param {boolean} [visualize=true] whether the action should be visualized
   * @returns {Promise} the fetchCoursePage promise
   */
  fetchAnalyticsUserProgress(lessonId = null, visualize = true) {
    return dispatch(
      fetchAnalyticsUserProgress(visualize, courseSlug, lessonId)
    );
  },
  /**
   * Loads a new page from the server
   * @param {string} [startDate] the start of the timeframe
   * @param {string} [endDate=null] the end of the timeframe
   * @param {number} [lessonId=null] The id of the lesson
   * @param {boolean} [visualize=true] whether the action should be visualized
   * @returns {Promise} the fetchCoursePage promise
   */
  fetchAnalyticsAggregatedProgress(
    startDate,
    endDate = null,
    lessonId = null,
    visualize = true
  ) {
    return dispatch(
      fetchAnalyticsAggregatedProgress(
        visualize,
        courseSlug,
        lessonId,
        startDate,
        endDate
      )
    );
  },
  /**
   * Fetches a course
   * @param {boolean} visualize Whether the fetching should be visualized
   * @returns {Promise} The fetch promise
   */
  fetchCourse(visualize = true) {
    return dispatch(fetchCourse(courseSlug, visualize));
  },

  /**
   * Changes the page
   * @param {string} startDate the start date
   * @param {string} endDate the end date
   * @param {number} lessonId the id of the lesson
   * @param {boolean} [visualize=true] whether the action should be visualized
   * @returns {Promise} The fetch promise
   */
  gotoAnalyticsPage(
    startDate = "this week",
    endDate = null,
    lessonId = null,
    visualize = true
  ) {
    dispatch(
      push({
        pathname: `/courses/${courseSlug}/analytics`,
        search: `?lessonId=${lessonId}&startDate=${encodeURIComponent(
          startDate
        )}&endDate=${encodeURIComponent(endDate)}`
      })
    );
    return Promise.all([
      dispatch(
        fetchAnalyticsUserProgress(visualize, courseSlug, getLessonId())
      ),
      dispatch(
        fetchAnalyticsAggregatedProgress(
          visualize,
          courseSlug,
          getLessonId(),
          startDate,
          endDate
        )
      )
    ]);
  }
});

const mergeProps = (mapStateToProps, mapDispatchToProps, ownProps) => ({
  ...mapStateToProps,
  ...mapDispatchToProps,
  ...ownProps
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(Analytics)
);
