import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  deserializeMark,
  renderMark,
  renderMarkButton
} from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { hotkey, createHotkeyPlugin } from "../helpers/hotkey";
import { toggleMark } from "../helpers/handlers";
import { hasMark } from "../helpers/content";

const hotkeys = [
  {
    hotkey: "mod+i",
    action: toggleMark("italic"),
    description: "Formatiert den ausgewählten Text kursiv",
    example: "Das ist <i>kursiver</i> Text."
  }
];

/**
 * Marks the selected text italic
 */
class ItalicMarkButton extends AntonButton {
  name = "italic-mark";
  icon = <FontAwesomeIcon icon={["far", "italic"]} />;
  onMouseDown = toggleMark("italic");
  tooltip = {
    target: "italic-mark",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Text kursiv formatieren"
  };
}

/**
 * A component rendering italic text
 */
class ItalicMark extends React.PureComponent {
  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { attributes, children } = this.props;
    return <i {...attributes}>{children}</i>;
  };
}

/**
 * The italic Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const Italic = options =>
  Object.assign(
    createHotkeyPlugin(hotkeys),
    deserializeMark("italic", "i"),
    deserializeMark("italic", "em"),
    renderMark("italic", ItalicMark),
    renderMarkButton("italic-mark", ItalicMarkButton),
    {
      isActive: hasMark("italic")
    }
  );

export default Italic;
