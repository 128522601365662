import React from "react";
import styled from "styled-components";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand
} from "reactstrap";
import { Link } from "react-router-dom";

import FooterLanding from "../containers/landing/FooterLanding";

const FooterWrapper = styled.footer`
  & > .container {
    overflow-x: auto;
  }
`;

/**
 * The page footer
 * @returns {Component} The component
 */
class Footer extends React.PureComponent {
  /**
   * Renders the component
   * @returns {Component} The component
   */
  render = () => {
    return (
      <FooterWrapper>
        <FooterLanding />
      </FooterWrapper>
    );
  };
}

export default Footer;
