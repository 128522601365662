import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { renderBlockButton, renderNode } from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { hasBlock } from "../helpers/content";
import { markdownBlockSequence } from "../helpers/markdown";
import LatexBlock from "./LatexBlock";
import LatexEditPopover from "./LatexEditPopover";

/**
 * Converts the selected text to a formula
 */
class LatexBlockButton extends AntonButton {
  name = "latex-block";
  icon = <FontAwesomeIcon icon={["far", "function"]} />;
  tooltip = {
    target: "latex-block",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Formel einfügen"
  };
  /**
   * Called when clicked
   * @param {Editor} editor the editor instance
   * @returns {void}
   */
  onMouseDown = editor => editor.insertLatex("");
}

/**
 * Helper function to insert latex
 * @param {Editor} editor the editor
 * @param {string} latex the latex string
 * @param {string} position the position
 * @returns {void}
 */
const insertLatex = (editor, latex, position) => {
  /*editor.setBlocks({
    type: "latex",
    data: { latex, position }
  });*/
  editor.moveStartToStartOfBlock().moveEndToEndOfBlock();
  //.focus();

  const selection = window.getSelection();
  const range = selection.getRangeAt(0);

  editor.registerPopover({
    type: "popper",
    elementReference: { current: range.commonAncestorContainer.parentElement },
    placement: "bottom",
    popover: LatexEditPopover
  });
};

/**
 * The Latex Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const Latex = options =>
  Object.assign(
    renderNode("latex", LatexBlock),
    renderBlockButton("latex", LatexBlockButton),
    markdownBlockSequence("latex", "\\$"),
    {
      isActive: hasBlock("latex"),
      commands: {
        insertLatex: insertLatex
      }
    }
  );

export default Latex;
