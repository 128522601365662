import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  InputGroupAddon,
  InputGroup,
  Input,
  Form,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import styled from "styled-components";

import MainContainer from "../components/MainContainer";
import Wrapper from "../components/Wrapper";
import NavbarLanding from "./landing/NavbarLanding";
import FooterLanding from "./landing/FooterLanding";

/**
 * Container that displays the impressum page
 * @returns {Component} The component
 */
class Impressum extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
  }
  toggle = () => this.setState({ isOpen: !this.state.isOpen });
  render() {
    return (
      <Wrapper fixed={"top"}>
        <NavbarLanding />
        <MainContainer className={"py-4"}>
          <Container>
            <Row>
              <Col md={{ size: "8", offset: "2" }}>
                <h1 className={"mb-4"}>
                  <strong>Impressum</strong>
                </h1>
                <p>wit ist ein Produkt von:</p>
                <p>
                  Verein Deutsch Digital
                  <br />
                  Belchenweg 1<br />
                  5034 Suhr <br />
                </p>
                <p>
                  Kontakt: <a href="mailto:hoi@witapp.io">hoi@witapp.io</a>
                </p>
              </Col>
            </Row>
          </Container>
        </MainContainer>
        <FooterLanding />
      </Wrapper>
    );
  }
}

export default Impressum;
