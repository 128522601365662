import React from "react";
import { Link } from "react-router-dom";
import { FormGroup, FormFeedback, Label, Input, Alert } from "reactstrap";
import { Form } from "formik";

import InputField from "../input/InputField";
import Button from "../Button";

/**
 * Renders the login form
 * @param {Object} formikProps The formik props
 * @returns {Component} The component
 */
const LoginForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  isValid
}) => (
  <Form>
    <FormGroup>
      <Label for="email">E-Mail oder Benutzername</Label>
      <InputField type="text" name="email" placeholder="wolfang@goethe.ch" />
    </FormGroup>
    <FormGroup>
      <Label for="password">Passwort </Label>
      <InputField type="password" name="password" />
      <Link id={"toPasswordReset"} to="/password-reset">
        <small className="text-muted">Passwort vergessen?</small>
      </Link>
    </FormGroup>
    {errors.invalid_credentials && (
      <Alert color="danger">Die Zugangsdaten sind ungültig.</Alert>
    )}
    <Button
      id="submitLoginForm"
      color="primary"
      type="submit"
      block
      loading={isSubmitting}
      disabled={!isValid}
    >
      Anmelden
    </Button>
  </Form>
);

export default LoginForm;
