import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardSubtitle,
  CardText
} from "reactstrap";
import styled from "styled-components";

import MainContainer from "../components/MainContainer";
import Wrapper from "../components/Wrapper";
import NavbarLanding from "./landing/NavbarLanding";
import FooterLanding from "./landing/FooterLanding";
import TeamMichael from "../assets/images/team_michael.png";
import TeamMichaelRetina from "../assets/images/team_michael@2x.png";
import TeamBeat from "../assets/images/team_beat.png";
import TeamBeatRetina from "../assets/images/team_beat@2x.png";
import TeamRahel from "../assets/images/team_rahel.png";
import TeamRahelRetina from "../assets/images/team_rahel@2x.png";
import TeamNico from "../assets/images/team_nico.png";
import TeamNicoRetina from "../assets/images/team_nico@2x.png";
import TeamAstronaut from "../assets/images/team_astronaut.png";
import TeamAstronautRetina from "../assets/images/team_astronaut@2x.png";
import ConversationImage from "../assets/images/conversation.svg";

const ScreenMockup = styled.div`
  border-radius: 3px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

const TeamCard = styled(Card)`
  margin-top: 100px;
`;

const TeamAvatar = styled.div`
  width: 225px;
  height: 225px;
  border-radius: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  img {
    width: 100%;
    height: 100%;
  }
  margin-top: -100px;
`;

/**
 * Container that displays the landing page
 * @returns {Component} The component
 */
class Team extends React.PureComponent {
  render() {
    return (
      <Wrapper fixed={"top"}>
        <NavbarLanding />
        <MainContainer className={"py-4"} id={"home"}>
          <Container>
            <Row>
              <Col
                md={{ size: "8", offset: "2" }}
                className={"text-center my-4"}
              >
                <h2>
                  <strong>Ein starkes Team für die Didaktik von morgen</strong>
                </h2>
                <p className={"lead my-3"}>
                  Unsere Organisationsstruktur gestalten wir wie unser Produkt:
                  möglichst einfach. Dabei sind uns die interdisziplinäre
                  Zusammenarbeit und Kommunikation auf Augenhöhe besonders
                  wichtig.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: "4" }} sm={{ size: "6" }} className={"my-4"}>
                <TeamCard body className="text-center">
                  <TeamAvatar className={"align-self-center mb-3"}>
                    <img
                      src={TeamMichael}
                      srcSet={TeamMichael + " 1x," + TeamMichaelRetina + " 2x"}
                    />
                  </TeamAvatar>
                  <h5>
                    <strong>Michael Ziörjen</strong>
                  </h5>
                  <CardSubtitle>
                    <i>Geschäftsführer & Co-Founder</i>
                  </CardSubtitle>
                  <CardText className={"text-left mt-3"}>
                    Neben dem Studium (Bsc. Information Systems UZH) arbeitete
                    Michael viele Jahre als Frontend-Designer und -entwickler
                    für zahlreiche Kunden. Seit 2018 steckt er alle Energie in
                    wit und gibt Vollgas, wenn es um die Produktentwicklung und
                    -gestaltung geht.
                  </CardText>
                  <a className={"mt-2"} href={"mailto:michael@witapp.io"}>
                    michael@witapp.io
                  </a>
                </TeamCard>
              </Col>
              <Col md={{ size: "4" }} sm={{ size: "6" }} className={"my-4"}>
                <TeamCard body className="text-center">
                  <TeamAvatar className={"align-self-center mb-3"}>
                    <img
                      src={TeamBeat}
                      srcSet={TeamBeat + " 1x," + TeamBeatRetina + " 2x"}
                    />
                  </TeamAvatar>
                  <h5>
                    <strong>Beat Knaus</strong>
                  </h5>
                  <CardSubtitle>
                    <i>Fachberater Didaktik & Co-Founder</i>
                  </CardSubtitle>
                  <CardText className={"text-left mt-3"}>
                    Seit über 20 Jahren unterrichtet Beat Deutsch und Theater an
                    der Neuen Kantonsschule Aarau. Bei wit fließt seine
                    didaktische Innovationsfreude ebenso ein wie seine Vorliebe
                    für Applikationen, die aufs Maximum reduziert sind.
                  </CardText>
                  <a className={"mt-2"} href={"mailto:beat@witapp.io"}>
                    beat@witapp.io
                  </a>
                </TeamCard>
              </Col>
              <Col md={{ size: "4" }} sm={{ size: "6" }} className={"my-4"}>
                <TeamCard body className="text-center">
                  <TeamAvatar className={"align-self-center mb-3"}>
                    <img
                      src={TeamRahel}
                      srcSet={TeamRahel + " 1x," + TeamRahelRetina + " 2x"}
                    />
                  </TeamAvatar>
                  <h5>
                    <strong>Rahel Bryner</strong>
                  </h5>
                  <CardSubtitle>
                    <i>Sales & Marketing</i>
                  </CardSubtitle>
                  <CardText className={"text-left mt-3"}>
                    Als Organisations- und Planungstalent blüht Rahel auf, wenn
                    es darum geht, neue Ideen effizient in die Tat umzusetzen.
                    Neben ihrem Betriebsökonomie-Studium an der FNHW in Olten
                    kümmert sie sich bei wit um den Auftritt nach aussen und die
                    Sales-Prozesse.
                  </CardText>
                  <a className={"mt-2"} href={"mailto:rahel@witapp.io"}>
                    rahel@witapp.io
                  </a>
                </TeamCard>
              </Col>
              <Col md={{ size: "4" }} sm={{ size: "6" }} className={"my-4"}>
                <TeamCard body className="text-center">
                  <TeamAvatar className={"align-self-center mb-3"}>
                    <img
                      src={TeamNico}
                      srcSet={TeamNico + " 1x," + TeamNicoRetina + " 2x"}
                    />
                  </TeamAvatar>
                  <h5>
                    <strong>Nico Hauser</strong>
                  </h5>
                  <CardSubtitle>
                    <i>Frontend Developer</i>
                  </CardSubtitle>
                  <CardText className={"text-left mt-3"}>
                    Nico treibt bei wit die technische Entwicklung voran und
                    sorgt mit seinem unermüdlichen Interesse für neue
                    Technologien dafür, dass die App fehlerfrei und performant
                    läuft. Im Sommer 2018 beginnt er sein Informatikstudium an
                    der ETH Zürich.
                  </CardText>
                  <a className={"mt-2"} href={"mailto:nico@witapp.io"}>
                    nico@witapp.io
                  </a>
                </TeamCard>
              </Col>
              <Col md={{ size: "4" }} sm={{ size: "6" }} className={"my-4"}>
                <TeamCard body className="text-center">
                  <TeamAvatar className={"align-self-center mb-3"}>
                    <img
                      src={TeamAstronaut}
                      srcSet={
                        TeamAstronaut + " 1x," + TeamAstronautRetina + " 2x"
                      }
                    />
                  </TeamAvatar>
                  <h5>
                    <strong>Du?</strong>
                  </h5>
                  <CardSubtitle>
                    <i>Bildungs-Astronaut</i>
                  </CardSubtitle>
                  <CardText className={"text-left mt-3"}>
                    Wenn du dich für die Bildung von morgen interessierst, etwas
                    unkonventionell bist und gerne in einem kleinen
                    interdisziplinären Team arbeiten möchtest, dann melde dich
                    bei uns.
                  </CardText>
                  <a className={"mt-2"} href={"mailto:hoi@witapp.io"}>
                    hoi@witapp.io
                  </a>
                </TeamCard>
              </Col>
            </Row>
          </Container>
        </MainContainer>
        <div className={"py-4 bg-white"}>
          <Container className={"py-4"}>
            <Row>
              <Col
                md={{ size: "6" }}
                className={"flex-column justify-content-center d-flex"}
              >
                <h2>
                  <strong>Ist wit etwas für dich?</strong>
                </h2>
                <p className={"my-3"}>
                  Du bist dir nicht sicher, wie du wit in deinen Unterricht
                  einbinden kannst?
                  <br />
                  Du benötigst Hilfe bei der Einarbeitung deiner bestehenden
                  Materialien?
                  <br />
                  <br />
                  Unsere Experten unterstützen dich bei der Einrichtung und
                  beraten dich gerne, wenn es um inhaltliche oder technische
                  Fragen geht.
                  <br />
                  <br />
                  <Button color="primary" className={"my-2 drift-open-chat"}>
                    Support-Chat starten
                  </Button>
                </p>
              </Col>
              <Col
                md={{ size: "6" }}
                className={
                  "text-center flex-column justify-content-center d-flex"
                }
              >
                <div>
                  <img src={ConversationImage} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterLanding />
      </Wrapper>
    );
  }
}

export default Team;
