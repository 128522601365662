import { combineReducers } from "redux";
import { wrap } from "utilities/reducer";
import { loadingBarReducer as loadingBar } from "react-redux-loading-bar";
import { routerReducer as routing } from "react-router-redux";
import { reducer as notifications } from "react-notification-system-redux";

import authentication, * as fromAuthentication from "./authentication";
import courseInvitation, * as fromCourseInvitations from "./courseInvitation";
import exercise, * as fromExercises from "./exercise";
import lesson, * as fromLessons from "./lesson";
import attachment, * as fromAttachment from "./attachment";
import course, * as fromCourse from "./course";
import analyticsUserProgress, * as fromAnalyticsUserProgress from "./analyticsUserProgress";
import analyticsAggregatedProgress, * as fromAnalyticsAggregatedProgress from "./analyticsAggregatedProgress";
import * as fromCurrentLesson from "./lesson/current";
import online, * as fromOnline from "./online";
import * as fromPlay from "./exercise/play";

/*
   #    #     # ####### #     #
  # #   #     #    #    #     #
 #   #  #     #    #    #     #
#     # #     #    #    #######
####### #     #    #    #     #
#     # #     #    #    #     #
#     #  #####     #    #     #
*/

export const getIsAuthenticated = wrap(
  fromAuthentication.getIsAuthenticated,
  state => state.authentication
);

export const getIsAuthenticating = wrap(
  fromAuthentication.getIsAuthenticating,
  state => state.authentication
);

export const getAuthenticationError = wrap(
  fromAuthentication.getAuthenticationError,
  state => state.authentication
);

export const getAuthenticatedUser = wrap(
  fromAuthentication.getAuthenticatedUser,
  state => state.authentication
);
export const getAuthenticatedUserFetching = wrap(
  fromAuthentication.getAuthenticatedUserFetching,
  state => state.authentication
);
export const getAuthenticatedUserError = wrap(
  fromAuthentication.getAuthenticatedUserError,
  state => state.authentication
);

/*
     _           _     _                         _   _            _ _        _   _
  __| | __ _ ___| |__ | |__   ___   __ _ _ __ __| | (_)_ ____   _(_) |_ __ _| |_(_) ___  _ __  ___
 / _` |/ _` / __| '_ \| '_ \ / _ \ / _` | '__/ _` | | | '_ \ \ / / | __/ _` | __| |/ _ \| '_ \/ __|
| (_| | (_| \__ \ | | | |_) | (_) | (_| | | | (_| | | | | | \ V /| | || (_| | |_| | (_) | | | \__ \
 \__,_|\__,_|___/_| |_|_.__/ \___/ \__,_|_|  \__,_| |_|_| |_|\_/ |_|\__\__,_|\__|_|\___/|_| |_|___/

*/

export const getCourseInvitationById = wrap(
  fromCourseInvitations.getCourseInvitationById,
  state => state.courseInvitation
);

export const getAllCourseInvitations = wrap(
  fromCourseInvitations.getAllCourseInvitations,
  state => state.courseInvitation
);

export const getCourseInvitations = wrap(
  fromCourseInvitations.getCourseInvitations,
  state => state.courseInvitation
);

/**
 *
 * AnalyticsUserProgress
 *
 */
export const getAnalyticsUserProgress = wrap(
  fromAnalyticsUserProgress.getAnalyticsUserProgress,
  state => state.analyticsUserProgress
);

/**
 *
 * AnalyticsAggregatedProgress
 *
 */
export const getAnalyticsAggregatedProgress = wrap(
  fromAnalyticsAggregatedProgress.getAnalyticsAggregatedProgress,
  state => state.analyticsAggregatedProgress
);

/*
####### #     # ####### ######   #####  ###  #####  #######
#        #   #  #       #     # #     #  #  #     # #
#         # #   #       #     # #        #  #       #
#####      #    #####   ######  #        #   #####  #####
#         # #   #       #   #   #        #        # #
#        #   #  #       #    #  #     #  #  #     # #
####### #     # ####### #     #  #####  ###  #####  #######
*/

export const getExerciseById = wrap(
  fromExercises.getExerciseById,
  state => state.exercise
);

export const getExercises = wrap(
  fromExercises.getExercises,
  state => state.exercise
);

export const getPlayCurrentExercisePosition = wrap(
  fromExercises.getPlayCurrentExercisePosition,
  state => state.exercise
);
export const getPlayHearts = wrap(
  fromExercises.getPlayHearts,
  state => state.exercise
);
export const getPlayValidatedExercises = wrap(
  fromExercises.getPlayValidatedExercises,
  state => state.exercise
);
export const getPlayLoadedExercises = wrap(
  fromExercises.getPlayLoadedExercises,
  state => state.exercise
);

export const getMaxExerciseOrder = wrap(
  fromExercises.getMaxExerciseOrder,
  state => state.exercise
);

export const getPlayValidationByExerciseUid = wrap(
  fromExercises.getPlayValidationByExerciseUid,
  state => state.exercise
);

/*
 #       #######  #####   #####  ####### #     #
 #       #       #     # #     # #     # ##    #
 #       #       #       #       #     # # #   #
 #       #####    #####   #####  #     # #  #  #
 #       #             #       # #     # #   # #
 #       #       #     # #     # #     # #    ##
 ####### #######  #####   #####  ####### #     #
*/

export const getLessonById = wrap(
  fromLessons.getLessonById,
  state => state.lesson
);

export const getLessons = wrap(fromLessons.getLessons, state => state.lesson);

export const getLessonChildrenIds = wrap(
  fromLessons.getLessonChildrenIds,
  state => state.lesson
);

export const getCourseLessons = wrap(
  fromLessons.getCourseLessons,
  state => state.lesson
);

export const getCurrentLessonExercise = wrap(
  fromLessons.getCurrentLessonExercise,
  state => state.lesson
);
export const getCurrentLessonHearts = wrap(
  fromLessons.getCurrentLessonHearts,
  state => state.lesson
);
export const getCurrentLessonValidatedExercises = wrap(
  fromLessons.getCurrentLessonValidatedExercises,
  state => state.lesson
);
export const getCurrentLessonError = wrap(
  fromLessons.getCurrentLessonError,
  state => state.lesson
);
export const getCurrentLessonIsValidating = wrap(
  fromLessons.getCurrentLessonIsValidating,
  state => state.lesson
);
export const getCurrentLessonExercises = wrap(
  fromLessons.getCurrentLessonExercises,
  state => state.lesson
);

/*
   #    ####### #######    #     #####  #     # #     # ####### #     # #######
  # #      #       #      # #   #     # #     # ##   ## #       ##    #    #
 #   #     #       #     #   #  #       #     # # # # # #       # #   #    #
#     #    #       #    #     # #       ####### #  #  # #####   #  #  #    #
#######    #       #    ####### #       #     # #     # #       #   # #    #
#     #    #       #    #     # #     # #     # #     # #       #    ##    #
#     #    #       #    #     #  #####  #     # #     # ####### #     #    #
*/

export const getAttachmentById = wrap(
  fromAttachment.getAttachmentById,
  state => state.attachment
);

export const getAttachments = wrap(
  fromAttachment.getAttachments,
  state => state.attachment
);

/*
######     #     #####  #     # ######  #######    #    ######  ######
#     #   # #   #     # #     # #     # #     #   # #   #     # #     #
#     #  #   #  #       #     # #     # #     #  #   #  #     # #     #
#     # #     #  #####  ####### ######  #     # #     # ######  #     #
#     # #######       # #     # #     # #     # ####### #   #   #     #
#     # #     # #     # #     # #     # #     # #     # #    #  #     #
######  #     #  #####  #     # ######  ####### #     # #     # ######
*/

export const getCourseBySlug = wrap(
  fromCourse.getCourseBySlug,
  state => state.course
);

export const getCourseById = wrap(
  fromCourse.getCourseById,
  state => state.course
);

export const getCourses = wrap(fromCourse.getCourses, state => state.course);

export const getCourseRolesById = wrap(
  fromCourse.getCourseRolesById,
  state => state.course
);

export const getCourseRoleById = wrap(
  fromCourse.getCourseRoleById,
  state => state.course
);

export const isCourseAuthor = wrap(
  fromCourse.isCourseAuthor,
  state => state.course
);

export const isCoursePublic = wrap(
  fromCourse.isCoursePublic,
  state => state.course
);

export const isCourseCollaborator = wrap(
  fromCourse.isCourseCollaborator,
  state => state.course
);

export const isCourseSubscriber = wrap(
  fromCourse.isCourseSubscriber,
  state => state.course
);

export const getCoursesWithRoles = wrap(
  fromCourse.getCoursesWithRoles,
  state => state.course
);

/*
 ####### ####### #     # ####### ######
 #     #    #    #     # #       #     #
 #     #    #    #     # #       #     #
 #     #    #    ####### #####   ######
 #     #    #    #     # #       #   #
 #     #    #    #     # #       #    #
 #######    #    #     # ####### #     #

 */

export const isOnline = wrap(fromOnline.isOnline, state => state.online);

const appReducer = combineReducers({
  online,
  loadingBar,
  routing,
  authentication,
  attachment,
  course,
  courseInvitation,
  lesson,
  exercise,
  analyticsUserProgress,
  analyticsAggregatedProgress,
  notifications
});

/**
 * Make sure that the state is removed if the user signed out of the application
 * @param {Object} state The previous state
 * @param {Object} action The action to process
 * @returns {Object} The new state
 */
const rootReducer = (state, action) => {
  if (action.type === "RESET_JWT_TOKEN") {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
