import SoftBreak from "slate-soft-break";
import Lists from "@convertkit/slate-lists";

import ExtendNode from "../features/extend-node";
import RemoveType from "../features/remove-type";
import Paragraph from "../features/paragraph";
import Bold from "../features/bold";
import Italic from "../features/italic";
import Latex from "../features/latex";
import Underline from "../features/underline";
import H1 from "../features/heading-one";
import H2 from "../features/heading-two";
import H3 from "../features/heading-three";
import Quote from "../features/quote";
import Code from "../features/code";
import Image from "../features/image";
import Hyperlink from "../features/hyperlink";
import NoEmpty from "./no-empty";
import Video from "../features/video";
import Popover from "../features/popovers";
import AntonReference from "../features/anton-reference";
import BulletedList from "../features/bulleted-list";
import NumberedList from "../features/numbered-list";
import { DEFAULT_NODE } from "./handlers";

export const plugins = [
  NoEmpty(DEFAULT_NODE),
  SoftBreak({
    shift: true,
    onlyIn: ["code", "quote"]
  }),
  Lists({
    blocks: {
      ordered_list: "numbered-list",
      unordered_list: "bulleted-list",
      list_item: "list-item"
    },
    classNames: {
      ordered_list: "numbered-list",
      unordered_list: "bulleted-list",
      list_item: "list-item"
    }
  }),
  Image(),
  Latex(),
  Bold(),
  Italic(),
  Underline(),
  H1(),
  H2(),
  H3(),
  BulletedList(),
  NumberedList(),
  Quote(),
  Code(),
  Hyperlink(),
  Paragraph(),
  ExtendNode(),
  RemoveType(),
  Video(),
  Popover(),
  AntonReference()
];
