import React from "react";
import { Button, Col, Container, Jumbotron, Row } from "reactstrap";
import { Link } from "react-router-dom";

import Wrapper from "./Wrapper";

/**
 * Renders the 404 page
 * @param {Object} props The component properties
 * @returns {Component} The component
 */
const NoMatch = ({ location }) => (
  <Wrapper header footer>
    <Container className="my-4">
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <Jumbotron>
            <h1 className="display-3">Ups!</h1>
            <p className="lead">Die Seite konnte nicht gefunden werden.</p>
            <hr className="my-2" />
            <p>
              Da hat unser Praktikant wohl etwas an die Wand gefahren, schäm
              dich Klaus!
            </p>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                src="https://giphy.com/embed/KIS4alAucQILe"
                width="100%"
                height="100%"
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen
              />
            </div>
            <p className="lead mt-2">
              <Link to="/">
                <Button block color="primary">
                  Zurück zur Startseite
                </Button>
              </Link>
            </p>
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default NoMatch;
