import { combineReducers } from "redux";

import { wrap } from "../../utilities/reducer";
import user, * as fromAuthenticatedUser from "./user";

/**
 * Stores whether the user is authenticated
 * @param {boolean} state The previous state
 * @param {Object} action The action to proccess
 * @returns {boolean} The next state
 */
const isAuthenticated = (
  state = localStorage.getItem("jwt-token") ? true : false,
  action
) => {
  switch (action.type) {
    case "FETCH_JWT_TOKEN":
      return !action.isFetching && action.token;
    case "RESET_JWT_TOKEN":
      return false;
    default:
      return state;
  }
};

/**
 * Stores whether the user is currenlty being authenticated
 * @param {boolean} state The previous state
 * @param {Object} action The action to proccess
 * @returns {boolean} The next state
 */
const isFetching = (state = false, action) => {
  switch (action.type) {
    case "FETCH_JWT_TOKEN":
      return action.isFetching;
    case "RESET_JWT_TOKEN":
      return false;
    default:
      return state;
  }
};

/**
 * Stores the potential error occured during authentication
 * @param {Error} state The previous state
 * @param {Object} action The action to proccess
 * @returns {Error} The next state
 */
const error = (state = null, action) => {
  switch (action.type) {
    case "FETCH_JWT_TOKEN":
      return action.error;
    case "RESET_JWT_TOKEN":
      return null;
    default:
      return state;
  }
};

export default combineReducers({ isAuthenticated, isFetching, error, user });

/**
 * Checks whether the user is currently authenticated
 * @param {Object} state This part of the redux state
 * @returns {boolean} Whether the user is authenticated
 */
export const getIsAuthenticated = state => state.isAuthenticated;
/**
 * Checks whether the user is currenlty being authenticated
 * @param {Object} state This part of the redux state
 * @returns {boolean} Whether the user is being authenticated
 */
export const getIsAuthenticating = state => state.isFetching;
/**
 * Retrieves the error occured during authentication
 * @param {Object} state This part of the redux state
 * @returns {Error} The error occured during authentication
 */
export const getAuthenticationError = state => state.error;
/**
 * Retrieves the currently authenticated user
 * @param {Object} state This part of the redux state
 * @returns {Object} The authenticated user
 */
export const getAuthenticatedUser = wrap(
  fromAuthenticatedUser.getAuthenticatedUser,
  state => state.user
);
/**
 * Checks whether the authenticated user is being fetched
 * @param {Object} state This part of the redux state
 * @returns {Object} Whether the authenticated user is being fetched
 */
export const getAuthenticatedUserFetching = wrap(
  fromAuthenticatedUser.getAuthenticatedUserFetching,
  state => state.user
);
/**
 * Retrieves the error occured while fetching the authenticated user
 * @param {Object} state This part of the redux state
 * @returns {Error} The error occured while fetching the authenticated user
 */
export const getAuthenticatedUserError = wrap(
  fromAuthenticatedUser.getAuthenticatedUserError,
  state => state.user
);
