import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Container, Row, Col, Card, Alert } from "reactstrap";
import { Formik } from "formik";
import yup from "yup";
import queryString from "query-string";

import Button from "../components/Button";
import { login, resetAuth } from "../actions/authentication";
import { getIsAuthenticating, getIsAuthenticated, isOnline } from "../reducers";
import Wrapper from "../components/Wrapper";
import LoginForm from "../components/forms/LoginForm";
import MainContainer from "../components/MainContainer";
import { updateOnlineStatus } from "../actions/online";

/**
 * Class representing the Login page
 * @extends React.PureComponent
 * @returns {Component} the react component
 */
class Login extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    //parse get parameters
    const { redirect } = queryString.parse(location.search);

    if (redirect) {
      this.state.redirect = redirect;
    }
  }

  /**
   * Submits the forms
   * @param {Object} values that should be submitted
   * @param {Object} props of the form
   * @param {function} setSubmitting function to set the submitting state inside the form
   * @param {function} setErrors function to set the errors inside the form
   * @returns {void}
   */
  handleSubmit = (
    values,
    {
      props,
      setSubmitting,
      setErrors /* setValues, setStatus, and other goodies */
    }
  ) => {
    const { dispatch } = this.props;
    const { redirect } = this.state;

    dispatch(login(values.email, values.password))
      .then(() => (redirect ? dispatch(push(redirect)) : dispatch(push("/"))))
      .catch(error => {
        setSubmitting(false);
        // TODO: transform API errors to Formik's errors
        let errors = { invalid_credentials: error.message };
        setErrors(errors);
        this.setState({ error });
      });
  };

  validationSchema = () =>
    yup.object().shape({
      email: yup.string().required(),
      password: yup.string().required()
    });

  componentDidMount = () => {
    const {
      resetAuthentication,
      isAuthenticated,
      updateOnlineStatus
    } = this.props;
    const { redirect } = this.state;

    if (redirect && isAuthenticated) {
      resetAuthentication();
    }

    updateOnlineStatus();

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
  };

  componentWillUnmount = () => {
    const { updateOnlineStatus } = this.props;

    window.removeEventListener("online", updateOnlineStatus);
    window.removeEventListener("offline", updateOnlineStatus);
  };

  render = () => {
    const { redirect } = this.state;
    const { isOnline } = this.props;

    return (
      <Wrapper header footer>
        <MainContainer>
          <Row>
            <Col md={{ size: 4, offset: 4 }}>
              <h3 className={"mt-4 mb-2 text-center"}>Login</h3>
              <p className={"mt-2 mb-4 text-center"}>Willkommen zurück 👋</p>
              <Card body>
                {!isOnline && (
                  <Alert color="warning">
                    Du bist momentan nicht mit dem Internet verbunden und kannst
                    dich daher weder anmelden noch registrieren!
                  </Alert>
                )}
                {redirect ? (
                  localStorage.getItem("jwt-token") ? (
                    <Alert color="danger">Die Sitzung ist abgelaufen</Alert>
                  ) : (
                    <Alert color="danger">
                      Für diese Aktion musst du dich anmelden
                    </Alert>
                  )
                ) : null}

                <Formik
                  initialValues={{ email: "", password: "" }}
                  onSubmit={this.handleSubmit}
                  component={LoginForm}
                  validationSchema={this.validationSchema}
                />
              </Card>
              <hr />
              {isOnline && (
                <div>
                  <p className={"text-center"}>Du hast noch kein Konto? 😮</p>
                  <Button
                    id={"toRegister"}
                    color="primary"
                    block
                    onClick={() =>
                      this.props.dispatch(
                        push(
                          redirect
                            ? "/register?redirect=" +
                                encodeURIComponent(redirect)
                            : "/register"
                        )
                      )
                    }
                  >
                    Registrieren
                  </Button>
                </div>
              )}
              <Button
                color="link"
                block
                onClick={() => this.props.dispatch(push("/"))}
              >
                Zurück zur Startseite
              </Button>
            </Col>
          </Row>
        </MainContainer>
      </Wrapper>
    );
  };
}

const mapStateToProps = state => ({
  isAuthenticated: getIsAuthenticated(state),
  isAuthenticating: getIsAuthenticating(state),
  isOnline: isOnline(state)
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  /**
   * Resets the jwt token
   * @returns {void}
   */
  resetAuthentication() {
    return dispatch(resetAuth());
  },
  /**
   * Updates the online / offline status
   * @returns {void}
   */
  updateOnlineStatus() {
    return dispatch(updateOnlineStatus(navigator.onLine !== false));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
