// @ts-ignore
import pluralize from "pluralize";
// @ts-ignore
import changeCase from "change-case";

import { fetchApi } from "./api";

export interface GenericAction {
  type: string;
  isFetching: boolean;
  error: string;
  visualize: boolean;
}

export interface DispatchType<ActionType> {
  (action: ActionType): Promise<any> | void;
}

/**
 * Describes an action which fetches a single item
 */
export interface FetchSingleItemAction extends GenericAction {
  itemId: number | string;
  item: {};
}

export interface FetchSingleItemActionFactory {
  (
    isFetching: boolean,
    error: string,
    visualize: boolean,
    itemId: number | string,
    item: {},
    ...attributes: Array<any>
  ): FetchSingleItemAction;
}

/**
 * Describes an action which fetches multiple items
 */
export interface FetchItemsAction extends GenericAction {
  items: Array<{}>;
}

export interface FetchItemsActionFactory {
  (
    isFetching: boolean,
    error: string,
    visualize: boolean,
    items: Array<{}>,
    ...attributes: Array<any>
  ): FetchItemsAction;
}

/**
 * Describes an action to fetch paged items
 */
export interface FetchItemPageAction extends FetchItemsAction {
  page: number;
}
export interface FetchItemPageActionFactory {
  (
    isFetching: boolean,
    error: string,
    visualize: boolean,
    items: Array<{}>,
    page: number,
    ...attributes: Array<any>
  ): FetchItemPageAction;
}

export interface CreateItemAction extends GenericAction {
  item: {};
}

export interface CreateItemActionFactory {
  (
    isFetching: boolean,
    error: string,
    visualize: boolean,
    item: {},
    ...attributes: Array<any>
  ): CreateItemAction;
}

export interface UpdateItemAction extends GenericAction {
  itemId: number | string;
  item: {};
}

export interface UpdateItemActionFactory {
  (
    isFetching: boolean,
    error: string,
    visualize: boolean,
    itemId: number | string,
    item: {},
    ...attributes: Array<any>
  ): UpdateItemAction;
}

export interface DeleteItemAction extends GenericAction {
  itemId: number | string;
}

export interface DeleteItemActionFactory {
  (
    isFetching: boolean,
    error: string,
    visualize: boolean,
    itemId: number | string,
    ...attributes: Array<any>
  ): DeleteItemAction;
}

export interface DeleteSingleItemAction extends GenericAction {
  itemId: number | string;
  item: {};
}

export interface DeleteSingleItemActionFactory {
  (
    isFetching: boolean,
    error: string,
    visualize: boolean,
    itemId: number | string,
    item: {},
    ...attributes: Array<any>
  ): DeleteItemAction;
}

export type ActionType =
  | FetchSingleItemAction
  | FetchItemsAction
  | FetchItemPageAction
  | CreateItemAction
  | UpdateItemAction
  | DeleteItemAction
  | DeleteSingleItemAction;

/**
 * Creates a standardized fetch action factory
 * @param {string} type The action type string
 * @param {...string} attributes Additional action property names
 * @returns {function} A factory to create a fetch action
 */
export const createFetchActionFactory = (
  type: string,
  ...attributes: Array<string>
) => (
  isFetching: boolean = false,
  error: string = null,
  visualize: boolean = false,
  ...args: Array<any>
): GenericAction => ({
  type,
  isFetching,
  error,
  visualize,
  ...attributes.reduce(
    (
      object: { [index: string]: any },
      attribute: string,
      index: number
    ): {} => {
      object[attribute] = args[index];
      return object;
    },
    {}
  )
});

/**
 * Creates a redux action for fetching one item of a type
 * @param {string} name The singular name of the item type
 * @param {...string} attributes Additional action property names
 * @returns {FetchSingleItemActionFactory} The factory to create a FetchSingleItemAction
 */
export const createFetchSingleItemActionFactory = <
  FetchSingleItemActionFactory
>(
  name: string,
  ...attributes: Array<string>
) =>
  createFetchActionFactory(
    "FETCH_" + changeCase.snakeCase(name).toUpperCase(),
    "itemId",
    "item",
    ...attributes
  );

/**
 * Creates a redux thunk that fetches a single item of a certain type
 * @param {function} actionCreator The action that should be dispatched
 * @param {function} endpoint A function generating the endpoint url based on the item id and the passed arguments
 * @param {function} mapItem A function mapping the item into the desired format
 * @returns {function} The redux thunk creator
 */
export const createFetchSingleItemThunkCreator = (
  actionCreator: FetchSingleItemActionFactory,
  endpoint: (itemId: string | number, ...attributes: Array<any>) => string,
  mapItem = (item: {}) => item
) => (
  itemId: number | string,
  visualize: boolean = false,
  ...attributes: Array<any>
) => (dispatch: (action: {}) => void) => {
  dispatch(actionCreator(true, null, visualize, itemId, null, ...attributes));

  return fetchApi(endpoint(itemId, ...attributes), {
    method: "GET"
  })
    .then(({ data: item }: { data?: {} | Array<{}> }) => {
      if (Array.isArray(item)) {
        return;
      }

      const mappedItem = mapItem(item || {});

      dispatch(
        actionCreator(false, null, visualize, itemId, mappedItem, ...attributes)
      );

      return Promise.resolve({ item: mappedItem, originalItem: item });
    })
    .catch((error: Error) => {
      dispatch(
        actionCreator(
          false,
          error.toString(),
          visualize,
          itemId,
          null,
          ...attributes
        )
      );

      return Promise.reject(error);
    });
};

/**
 * Creates a redux action for fetching all items of a type
 * @param {string} name The singular name of the item type
 * @param {...string} attributes Additional action property names
 * @returns {FetchItemsActionFactory} The redux action creator
 */
export const createFetchItemsActionFactory = (
  name: string,
  ...attributes: Array<string>
) =>
  createFetchActionFactory(
    "FETCH_" + changeCase.snakeCase(pluralize(name)).toUpperCase(),
    "items",
    ...attributes
  ) as FetchItemsActionFactory;

/**
 * Creates a redux action for fetching a page of items
 * @param {string} name The singular name of the item type
 * @param {...string} attributes Additional action property names
 * @returns {FetchItemPageActionFactory} The redux action creator
 */
export const createFetchItemPageActionFactory = (
  name: string,
  ...attributes: Array<string>
) =>
  createFetchItemsActionFactory(
    name,
    "page",
    ...attributes
  ) as FetchItemPageActionFactory;

/**
 * Fetches all items by using a pagination
 * @param {FetchItemPageActionFactory} actionCreator The action that should be dispatched
 * @param {function} endpoint A function generating the endpoint url based on the page and the number of items per page
 * @param {function} mapItem A function mapping the item into the desired format
 * @returns {promise} A promise yielding all items or an error
 */
export const createFetchItemsPageThunkFactory = (
  actionCreator: FetchItemPageActionFactory,
  endpoint: (page: number, ...attributes: Array<any>) => string,
  mapItem = (item: {}) => item
) => (
  page: number = 1,
  pageTo: number = -1,
  visualize: boolean = false,
  ...attributes: Array<any>
) => (
  dispatch: DispatchType<FetchItemPageAction>
): Promise<{
  items: Array<{}>;
  originalItems: Array<{}>;
  perPage: number;
  total: number;
}> => {
  dispatch(actionCreator(true, null, visualize, [], page, ...attributes));

  return fetchApi(endpoint(page, ...attributes), {
    method: "GET"
  }).then(
    ({
      data: items,
      meta
    }: {
      data?: {} | Array<{}>;
      meta?: { total: number; per_page: number };
    }) => {
      if (!Array.isArray(items) || !meta) {
        return Promise.reject(new Error("Fetch returned invalid data!"));
      }

      const { total = 0, per_page: perPage = -1 } = meta;

      const mappedItems = items.map(mapItem);
      dispatch(
        actionCreator(
          page < pageTo,
          null,
          visualize,
          mappedItems,
          page,
          ...attributes
        )
      );

      if (
        (page - 1) * perPage + items.length < total &&
        (pageTo > 0 ? page < pageTo : true)
      ) {
        return createFetchItemsPageThunkFactory(
          actionCreator,
          endpoint,
          mapItem
        )(page + 1, pageTo, visualize, ...attributes)(dispatch).then(
          ({ items: nextItems, originalItems: newOriginalItems }) =>
            Promise.resolve({
              items: [...mappedItems, ...nextItems],
              originalItems: [...items, ...newOriginalItems],
              perPage,
              total
            })
        );
      }
      return Promise.resolve({
        items: mappedItems,
        originalItems: items,
        perPage,
        total
      });
    }
  );
};

/**
 * Creates a redux thunk that fetches all items of a certain type
 * @param {FetchItemPageActionFactory} actionCreator The redux action creator to dispatch
 * @param {function} endpoint A function generating the endpoint url based on the page and the number of items per page
 * @param {function} mapItem A function mapping the item into the desired format
 * @returns {function} The redux thunk creator
 */
export const createFetchAllItemsThunkFactory = (
  actionCreator: FetchItemPageActionFactory,
  endpoint: (page: number, ...attributes: Array<any>) => string,
  mapItem = (item: {}) => item
) => (visualize: boolean = false, ...attributes: Array<any>) => (
  dispatch: DispatchType<FetchItemPageAction>
) => {
  dispatch(actionCreator(true, null, visualize, [], -1, ...attributes));

  return createFetchItemsPageThunkFactory(actionCreator, endpoint, mapItem)(
    1,
    -1,
    visualize,
    ...attributes
  )(dispatch)
    .then(({ items }: { items: Array<{}> }) => {
      dispatch(actionCreator(false, null, visualize, items, -1, ...attributes));

      return Promise.resolve(items);
    })
    .catch((error: Error) => {
      dispatch(
        actionCreator(false, error.message, visualize, [], -1, ...attributes)
      );

      return Promise.reject(error);
    });
};

/**
 * Creates a redux thunk that fetches multiple items of a certain type
 * @param {FetchItemsActionFactory} action The redux action creator to dispatch
 * @param {function} endpoint A function generating the endpoint url based on the attributes
 * @param {function} mapItem A function mapping the item into the desired format
 * @returns {function} The redux thunk creator
 */
export const createFetchItemsThunkFactory = (
  action: FetchItemsActionFactory,
  endpoint: (...attributes: Array<any>) => string,
  mapItem = (item: {}) => item
) => (visualize: boolean = false, ...attributes: Array<any>) => (
  dispatch: DispatchType<FetchItemsAction>
) => {
  dispatch(action(true, null, visualize, [], ...attributes));

  return fetchApi(endpoint(...attributes), {
    method: "GET"
  })
    .then(({ data: items }: { data?: {} | Array<{}> }) => {
      if (!Array.isArray(items)) {
        return Promise.reject("Fetch returned invalid data!");
      }

      const mappedItems = items.map(mapItem);
      dispatch(action(false, null, visualize, mappedItems, ...attributes));
      return Promise.resolve({ items: mappedItems, originalItems: items });
    })
    .catch((error: Error) => {
      dispatch(action(false, error.message, visualize, [], ...attributes));

      return Promise.reject(error);
    });
};

/**
 * Creates a redux action for creating an item of a type
 * @param {string} name The singular name of the item type
 * @param {...string} attributes Additional action property names
 * @returns {CreateItemActionFactory} The redux action creator
 */
export const createCreateItemActionCreator = (
  name: string,
  ...attributes: Array<string>
) =>
  createFetchActionFactory(
    "CREATE_" + changeCase.snakeCase(name).toUpperCase(),
    "item",
    ...attributes
  ) as CreateItemActionFactory;

/**
 * Creates a redux thunk that creates an item of a certain type
 * @param {function} actionCreator The action that should be dispatched
 * @param {function} endpoint A function generating the endpoint url based on the item and the passed args
 * @param {function} mapItem A function mapping the item into the desired format
 * @returns {function} The redux thunk creator
 */
export const createCreateItemThunkCreator = (
  actionCreator: CreateItemActionFactory,
  endpoint: (item: {}, ...attributes: Array<any>) => string,
  mapItem = (item: {}) => item
) => (item: {}, visualize: boolean = false, ...attributes: Array<any>) => (
  dispatch: DispatchType<CreateItemAction>
) => {
  dispatch(actionCreator(true, null, visualize, item, ...attributes));

  return fetchApi(endpoint(item, ...attributes), {
    method: "POST",
    body: JSON.stringify(item)
  })
    .then(({ data: item }: { data?: {} | Array<{}> }) => {
      if (Array.isArray(item)) {
        return Promise.reject("Fetch returned invalid data!");
      }

      const mappedItem = mapItem(item || {});

      dispatch(
        actionCreator(false, null, visualize, mappedItem, ...attributes)
      );

      return Promise.resolve({ item: mappedItem, originalItem: item });
    })
    .catch((error: Error) => {
      dispatch(
        actionCreator(false, error.message, visualize, item, ...attributes)
      );

      return Promise.reject(error);
    });
};

/**
 * Creates a redux action for updating an item of a type
 * @param {string} name The singular name of the item type
 * @param {...string} attributes Additional action property names
 * @returns {UpdateItemActionFactory} The redux action creator
 */
export const createUpdateItemActionCreator = (
  name: string,
  ...attributes: Array<string>
): UpdateItemActionFactory =>
  createFetchActionFactory(
    "UPDATE_" + changeCase.snakeCase(name).toUpperCase(),
    "itemId",
    "item",
    ...attributes
  ) as UpdateItemActionFactory;

/**
 * Creates a redux thunk that updates an item of a certain type
 * @param {UpdateItemActionFactory} actionCreator The action that should be dispatched
 * @param {function} endpoint A function generating the endpoint url based on the itemId, the item and the passed attributes
 * @param {function} mapItem A function mapping the item into the desired format
 * @returns {function} The redux thunk creator
 */
export const createUpdateItemThunkCreator = (
  actionCreator: UpdateItemActionFactory,
  endpoint: (
    itemId: number | string,
    item: {},
    ...attributes: Array<any>
  ) => string,
  mapItem = (item: {}) => item
) => (
  itemId: number | string,
  item: {},
  visualize: boolean = false,
  ...attributes: Array<any>
) => (dispatch: DispatchType<UpdateItemAction>) => {
  dispatch(actionCreator(true, null, visualize, itemId, item, ...attributes));

  return fetchApi(endpoint(itemId, item, ...attributes), {
    method: "PUT",
    body: JSON.stringify(item),
    headers: new Headers({
      "Content-Type": "application/json"
    })
  })
    .then(({ data: item }: { data?: {} | Array<{}> }) => {
      if (Array.isArray(item)) {
        return Promise.reject("Fetch returned invalid data!");
      }

      const mappedItem = mapItem(item || {});

      dispatch(
        actionCreator(false, null, visualize, itemId, mappedItem, ...attributes)
      );
      return Promise.resolve({ item, originalItem: item });
    })
    .catch((error: Error) => {
      dispatch(
        actionCreator(
          false,
          error.message,
          visualize,
          itemId,
          item,
          ...attributes
        )
      );

      return Promise.reject(error);
    });
};

/**
 * Creates a redux action for deleting an item of a type
 * @param {string} name The singular name of the item type
 * @param {...string} attributes Additional action property names
 * @returns {DeleteItemActionFactory} The redux action creator
 */
export const createDeleteItemActionCreator = (
  name: string,
  ...attributes: Array<string>
) =>
  createFetchActionFactory(
    "DELETE_" + changeCase.snakeCase(name).toUpperCase(),
    "itemId",
    ...attributes
  ) as DeleteItemActionFactory;

/**
 * Creates a redux thunk that deletes an item of a certain type
 * @param {DeleteItemActionFactory} action The action that should be dispatched
 * @param {function} endpoint A function generating the endpoint url based on the item and the passed attributes
 * @returns {function} The redux thunk creator
 */
export const createDeleteItemThunkCreator = (
  action: DeleteItemActionFactory,
  endpoint: (itemId: number | string, ...atributes: Array<any>) => string
) => (
  itemId: number | string,
  visualize: boolean = false,
  ...attributes: Array<any>
) => (dispatch: DispatchType<DeleteItemAction>) => {
  dispatch(action(true, null, visualize, itemId, ...attributes));

  return fetchApi(endpoint(itemId, ...attributes), {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json"
    })
  })
    .then(() => {
      dispatch(action(false, null, visualize, itemId, ...attributes));

      return Promise.resolve();
    })
    .catch((error: Error) => {
      dispatch(action(false, error.message, visualize, itemId, ...attributes));

      return Promise.reject(error);
    });
};

//TODO: Check whether this is really needed..

/**
 * Creates a redux action for deleting one item of a type
 * @param {string} name The singular name of the item type
 * @param {...string} attributes Additional action property names
 * @returns {DeleteSingleItemActionFactory} The redux action creator
 */
export const createRemoveSingleItemActionCreator = (
  name: string,
  ...attributes: Array<string>
) =>
  createFetchActionFactory(
    "DELETE_" + changeCase.snakeCase(name).toUpperCase(),
    "itemId",
    "item",
    ...attributes
  ) as DeleteSingleItemActionFactory;

/**
 * Creates a redux thunk that deletes a single item of a certain type
 * @param {DeleteSingleItemActionFactory} action The action that should be dispatched
 * @param {function} endpoint A function generating the endpoint url based on the item id and the passed arguments
 * @returns {function} The redux thunk creator
 */
export const createRemoveSingleItemThunkCreator = (
  action: DeleteSingleItemActionFactory,
  endpoint: (itemId: number | string, ...args: Array<any>) => string
) => (itemId: number | string, args: {} = {}, visualize: boolean = false) => (
  dispatch: DispatchType<DeleteItemAction>
) => {
  dispatch(action(true, null, visualize, itemId, null));

  return fetchApi(endpoint(itemId, args), {
    method: "DELETE"
  })
    .then(() => {
      dispatch(action(false, null, visualize, itemId, null));

      return Promise.resolve();
    })
    .catch((error: Error) => {
      dispatch(action(false, error.message, visualize, itemId, null));

      return Promise.reject(error);
    });
};
