// @ts-ignore
import shuffle from "shuffle-array";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
// @ts-ignore
import changeCase from "change-case";

import {
  createCreateItemActionCreator,
  createCreateItemThunkCreator,
  createDeleteItemActionCreator,
  createDeleteItemThunkCreator,
  createFetchActionFactory,
  createFetchItemsActionFactory,
  CreateItemAction,
  CreateItemActionFactory,
  createUpdateItemActionCreator,
  createUpdateItemThunkCreator,
  DeleteItemAction
} from "../utilities/action";
import { fetchApi } from "../utilities/api";
import { associateExercises } from "./lesson";
import { Exercise, ExerciseType } from "../models/Exercise";
import {
  ExerciseMultipleChoice,
  ExerciseMultipleChoiceFactory
} from "../models/ExerciseMultipleChoice";
import {
  ExerciseFlashcard,
  ExerciseFlashcardFactory
} from "../models/ExerciseFlashcard";
import { StaticContent, StaticContentFactory } from "../models/StaticContent";
import {
  ExerciseFreeText,
  ExerciseFreeTextFactory
} from "../models/ExerciseFreeText";

export const itemName = "exercise";

/**
 * Interface for all exercises that are correctable
 */
export interface Correctable {
  correct?: boolean;
  id: number;
  type: ExerciseType;
}

/**
 * Maps a dto to an exercise
 * @param {any} dto the data transfer object
 * @returns {Exercise} the exercise
 */
export const mapExercise = (dto: any): Exercise => {
  const {
    id,
    question,
    author,
    lesson_id,
    type,
    order,
    created_at,
    updated_at,
    /* multiple choice */
    shuffle_answers,
    answers,
    /* flashcards */
    front,
    back,
    /* free-text */
    explanation,
    strict,
    /* static-content */
    content
  } = dto;
  switch (type) {
    case "multiple_choice":
      return ExerciseMultipleChoiceFactory.fromDTO({
        id,
        type,
        question,
        author,
        lesson_id,
        order,
        created_at,
        updated_at,
        shuffle_answers,
        answers
      });
    case "flashcard":
      return ExerciseFlashcardFactory.fromDTO({
        id,
        author,
        lesson_id,
        order,
        created_at,
        updated_at,
        front,
        back,
        type
      });
    case "static_content":
      return StaticContentFactory.fromDTO({
        id,
        author,
        lesson_id,
        order,
        created_at,
        updated_at,
        content,
        type
      });
    case "free_text":
      return ExerciseFreeTextFactory.fromDTO({
        id,
        author,
        lesson_id,
        order,
        created_at,
        updated_at,
        question,
        explanation,
        strict,
        answers,
        type
      });
    default:
      throw "Exercise dto could not be mapped to exercise class.";
  }
};

/**
 * Transforms the uid of an exercise back to the type and the id
 * @param {string} uid : the uid of the exercise
 * @returns {{type: *, exerciseId: number}} object containing the type and the id of the exercise
 */
export const uidToIdAndType = (uid: string): Object => {
  const type = uid.split("-")[0];
  const exerciseId = parseInt(uid.split("-")[1]);
  return { type, exerciseId };
};

/**
 * Returns the type from an exercise uid
 * @param {string} uid the uid of an exericse
 * @returns {string} the type of an exercise
 */
export const typeFromUid = (uid: string): string => {
  return uid.split("-")[0];
};

/**
 * Returns whether a given exercise is of the type flashcard
 * @param {Exercise} exercise the uid of the exercise
 * @returns {boolean} whether the exercise is of type flashcard
 */
export const isFlashcard = (exercise: Exercise): boolean => {
  return exercise.type === ExerciseType.Flashcard;
};

/**
 * Returns whether a given exercise is of the type static content
 * @param {Exercise} exercise the uid of the exercise
 * @returns {boolean} whether the exercise is of type static content
 */
export const isStaticContent = (
  exercise: { type: ExerciseType } | string
): boolean => {
  if (typeof exercise === "string") {
    return typeFromUid(exercise) === ExerciseType.StaticContent;
  } else {
    return exercise.type === ExerciseType.StaticContent;
  }
};

/**
 * Returns whether a given exercise is of the type multiple_choice
 * @param {Exercise} exercise the uid of the exercise
 * @returns {boolean} whether the exercise is of type multiple_choice
 */
export const isMultipleChoice = (exercise: Exercise): boolean => {
  return exercise.type === ExerciseType.Flashcard;
};

/**
 * Action called before and after fetching multiple items
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} items The received items
 * @returns {object} The redux action
 */
export const fetchItems = createFetchItemsActionFactory(itemName);

/**
 * Fetches all exercises from the specified courses
 * @param {string} courseSlug Slug of the course whose exercises should be fetched
 * @param {number} [lessonId=null] Id of the lesson whose exercises should be fetched
 * @param {string} [progress='all'] progress of the exercise ('all','wrong','correct' or 'unviewed')
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} The redux thunk
 */
export const fetchExercises = (
  courseSlug: string,
  lessonId: number = null,
  progress: string = "all",
  visualize: boolean = false
): ThunkAction<Promise<Exercise[]>, {}, {}, AnyAction> => (dispatch: any) => {
  dispatch(fetchItems(true, null, visualize, null));
  return fetchApi(
    `/api/courses/${courseSlug}/lessons/${lessonId}/exercises?progress=${progress}`,
    {
      method: "GET"
    }
  )
    .then(({ data: items }) => {
      let mappedItems;
      if (items instanceof Array) {
        mappedItems = items.map(mapExercise);
      } else {
        mappedItems = [mapExercise(items)];
      }

      //store them
      dispatch(fetchItems(false, null, visualize, mappedItems));
      //associate to lessons
      dispatch(
        associateExercises(
          lessonId,
          mappedItems
            .sort((a, b) => a.order - b.order)
            .map((item: Exercise) => item.uid)
        )
      );

      return Promise.resolve(mappedItems);
    })
    .catch(err => {
      dispatch(fetchItems(false, err, visualize, null));

      return Promise.reject(err);
    });
};

/**
 * Saves whether the given exercises was answered correctly
 * @param {number} exerciseUid The id of the exercise to add
 * @param {ExerciseType} exerciseType the type of the exercise
 * @param {bool} correct Whether the exercise was answered correctly
 * @param {*} payload a payload that can be added to describe the answer given by the user
 * @returns {Object} The redux action
 */
export const addValidatedExercise = (
  exerciseUid: string,
  exerciseType: ExerciseType,
  correct: boolean,
  payload: any = null
) => ({
  type: "PLAY_ADD_VALIDATED_EXERCISE",
  exerciseUid,
  exerciseType,
  correct,
  payload
});

/**
 * Repeat all exercises answered incorrectly
 * @returns {Object} The redux action
 */
export const repeatWrongExercises = () => ({
  type: "PLAY_REPEAT_WRONG"
});

/**
 * Repeat all exercises
 * @returns {Object} The redux action
 */
export const repeatAllExercises = () => ({
  type: "PLAY_REPEAT_ALL"
});

/**
 * Sets the current exercise
 * @param {Array} exercises The exercises to set
 * @param {boolean} [shuffled=false] Whether the exercises should be shuffeled first
 * @returns {string} The redux action
 */
export const setCurrentExercises = (
  exercises: Array<{ uid: string }>,
  shuffled = false
) => ({
  type: "PLAY_SET_EXERCISES",
  exercises: shuffled ? shuffle(exercises, { copy: true }) : exercises
});

/**
 * Groups the exercises and the static contents together
 * @param {Array} exercises the ungrouped exercises
 * @returns {Array} the grouped exercises inside a nested array
 */
/*export const groupExercises = exercises => {
  let groupedExercises: Array<Exercise> = [];
  let currentGroup: Array<Exercise> = [];
  exercises.forEach((e, index) => {
    if (typeFromUid(e) === "static_content") {
      if (currentGroup.length) {
        groupedExercises.push(currentGroup);
      }
      currentGroup = [e];
    } else {
      currentGroup.push(e);
    }
    if (index + 1 === exercises.length) {
      groupedExercises.push(currentGroup);
    }
  });
  return groupedExercises;
};*/

/**
 * Ungroups the exercises and the static contents together
 * @param {Array} groupedExercises the grouped exercises inside a nested array
 * @returns {Array} the ungrouped exercises
 */
/*export const ungroupExercises = groupedExercises => {
  let exercises: Array<Exercise> = [];
  groupedExercises.forEach(group =>
    group.forEach((ex: Exercise) => exercises.push(ex))
  );
  return exercises;
};*/

/**
 * Moves to the next exercise
 * @returns {Error} The redux action
 */
export const nextExercise = () => ({
  type: "PLAY_NEXT_EXERCISE"
});

/** Action dispatched before and after exercises are validated
 * @param {boolean} isFetching whether the validation is being fetched or not
 * @param {Error} error Holds the error if one occured
 * @param {Array} data the data returned from the server
 * @returns {Object} The redux action
 */
const validateAction = createFetchActionFactory("PLAY_VALIDATE", "data");
/**
 * Subscribes the currently authenticated user to a course
 * @param {string} courseSlug The id of the course to subscribe to
 * @param {Array} validatedExercises an array of validated exercises containing objects { id, correct }
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} The redux thunk
 */
export const validateExercises = (
  courseSlug: string,
  validatedExercises: Array<Correctable>,
  visualize: boolean = false
) => (dispatch: any) => {
  dispatch(validateAction(true, null, visualize, null));
  return fetchApi(`/api/courses/${courseSlug}/progress`, {
    method: "POST",
    body: JSON.stringify({
      progresses: validatedExercises.map((ex: Correctable) => ({
        exercise_id: ex.id,
        type: ex.type,
        correct: ex.correct
      }))
    })
  })
    .then(({ data }) => {
      dispatch(validateAction(false, null, visualize, data));

      return Promise.resolve(data);
    })
    .catch(err => {
      dispatch(validateAction(false, err, visualize, null));

      return Promise.reject(err);
    });
};

/**
 * Action called before and after updating an item
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} itemId The id of the item that should be
 * @param {object} item The item that should be / was updated
 * @param {number} courseId The courses's id whose exercise should be updated
 * @param {number} lessonId The lesson's id whose exercise should be updated
 * @param {number} previousUid The exercise's previous uid (including the nonce)
 * @returns {object} The redux action
 */
export const updateExerciseActionFactory = createUpdateItemActionCreator(
  itemName,
  "courseId",
  "lessonId",
  "previousUid"
);

export const saveExerciseActionFactory = createFetchActionFactory(
  "UPDATE_" + changeCase.snakeCase(itemName).toUpperCase(),
  "item",
  "courseId",
  "lessonId",
  "previousUid"
) as CreateItemActionFactory;

/**
 * Updates an existing multiple choice exercise
 * @param {number} exerciseId The exercise's uid that should be updated
 * @param {Object} exercise The new item data
 * @param {boolean} [visualize=false] visualize Whether the progress of this action should be visualized
 * @param {number} courseId The courses's id whose exercise should be updated
 * @param {number} lessonId The lesson's id whose exercise should be updated
 * @param {number} previousUid The exercise's previous uid (including the nonce)
 * @returns {function} the redux thunk
 */
export const updateExerciseMultipleChoice = createUpdateItemThunkCreator(
  updateExerciseActionFactory,
  (exerciseId: string, item: any, courseId: number, lessonId: number) =>
    `/api/courses/${courseId}/lessons/${lessonId}/multiple-choice/${exerciseId}`,
  mapExercise
);

/**
 * Updates an existing static content
 * @param {number} exerciseId The exercise's id that should be updated
 * @param {Object} exercise The new item data
 * @param {boolean} [visualize=false] visualize Whether the progress of this action should be visualized
 * @param {number} courseId The courses's id whose exercise should be updated
 * @param {number} lessonId The lesson's id whose exercise should be updated
 * @param {number} previousUid The exercise's previous uid (including the nonce)
 * @returns {function} the redux thunk
 */
export const updateStaticContent = createUpdateItemThunkCreator(
  updateExerciseActionFactory,
  (exerciseId: number, item: any, courseId: number, lessonId: number) =>
    `/api/courses/${courseId}/lessons/${lessonId}/static-content/${exerciseId}`,
  mapExercise
);

/**
 * Updates an existing Flashcard  exercise
 * @param {number} exerciseId The exercise's id that should be updated
 * @param {Object} exercise The new item data
 * @param {boolean} [visualize=false] visualize Whether the progress of this action should be visualized
 * @param {number} courseId The courses's id whose exercise should be updated
 * @param {number} lessonId The lesson's id whose exercise should be updated
 * @param {number} previousUid The exercise's previous uid (including the nonce)
 * @returns {function} the redux thunk
 */
export const updateExerciseFlashcard = createUpdateItemThunkCreator(
  updateExerciseActionFactory,
  (exerciseId: number, item: any, courseId: number, lessonId: number) =>
    `/api/courses/${courseId}/lessons/${lessonId}/flashcard/${exerciseId}`,
  mapExercise
);

/**
 * Updates an existing free text exercise
 * @param {number} exerciseId The exercise's uid that should be updated
 * @param {Object} exercise The new item data
 * @param {boolean} [visualize=false] visualize Whether the progress of this action should be visualized
 * @param {number} courseId The courses's id whose exercise should be updated
 * @param {number} lessonId The lesson's id whose exercise should be updated
 * @param {number} previousUid The exercise's previous uid (including the nonce)
 * @returns {function} the redux thunk
 */
export const updateExerciseFreeText = createUpdateItemThunkCreator(
  updateExerciseActionFactory,
  (exerciseId: number, item: any, courseId: number, lessonId: number) =>
    `/api/courses/${courseId}/lessons/${lessonId}/free-text/${exerciseId}`,
  mapExercise
);

/**
 * Updates an existing exercise
 * @param {Exercise} exercise The new item data
 * @param {boolean} [visualize=false] visualize Whether the progress of this action should be visualized
 * @param {number} courseId The courses's id whose exercise should be updated
 * @param {number} lessonId The lesson's id whose exercise should be updated
 * @returns {function} the redux thunk
 */
export const updateExercise = (
  exercise: Exercise,
  visualize: boolean,
  courseId: number,
  lessonId: number
) => {
  const previousUid = exercise.uid;

  switch (exercise.type) {
    case ExerciseType.Flashcard:
      return updateExerciseFlashcard(
        exercise.id,
        ExerciseFlashcardFactory.toDTO(<ExerciseFlashcard>exercise),
        visualize,
        courseId,
        lessonId,
        previousUid
      );
    case ExerciseType.MultipleChoice:
      return updateExerciseMultipleChoice(
        exercise.id,
        ExerciseMultipleChoiceFactory.toDTO(<ExerciseMultipleChoice>exercise),
        visualize,
        courseId,
        lessonId,
        previousUid
      );
    case ExerciseType.FreeText:
      return updateExerciseFreeText(
        exercise.id,
        ExerciseFreeTextFactory.toDTO(<ExerciseFreeText>exercise),
        visualize,
        courseId,
        lessonId,
        previousUid
      );
    case ExerciseType.StaticContent:
      return updateStaticContent(
        exercise.id,
        StaticContentFactory.toDTO(<StaticContent>exercise),
        visualize,
        courseId,
        lessonId,
        previousUid
      );
  }
};

/**
 * Moves an exercise from one index to another
 * @param {string} lessonId The id of the lesson
 * @param {number} sourceIndex The index of the lesson to move
 * @param {number} targetIndex The index where the selected lesson should be moved to
 * @returns {Object} The redux action
 */
export const moveExercise = (
  lessonId: string,
  sourceIndex: number,
  targetIndex: number
) => ({
  type: "LESSON_MOVE_EXERCISE",
  lessonId,
  sourceIndex,
  targetIndex
});

/**
 * Action called before and after creating an item
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} item The item that should be / was created
 * @param {number} courseId The courses's id whose exercise should be updated
 * @param {object} lessonId The lessonId the new exercise belongs to
 * @returns {object} The redux action
 */
export const createExerciseActionFactory = createCreateItemActionCreator(
  itemName,
  "courseId",
  "lessonId"
);

/**
 * Action that creates a new exercise and stores it in the local store
 * @param exercise
 * @returns {CreateItemAction}
 */
export const createExercise = (exercise: Exercise): CreateItemAction => {
  return createExerciseActionFactory(false, null, false, exercise);
};

/**
 * Action that removes an exercise in the local store
 * @param exercise
 * @param visualize
 * @param courseId
 * @param lessonId
 * @returns {DeleteItemAction}
 */
export const removeExercise = (
  exercise: Exercise,
  visualize: boolean,
  courseId: number,
  lessonId: number
): DeleteItemAction => {
  return deleteExerciseActionFactory(
    false,
    null,
    visualize,
    exercise.id,
    courseId,
    lessonId,
    exercise.uid
  );
};

/**
 * Creates a new multiple choice exercise
 * @param {string} type the type of the exercise (either "multiple_choice" or "flashcard")
 * @returns {function} The redux thunk
 */
export const saveExerciseMultipleChoice = createCreateItemThunkCreator(
  saveExerciseActionFactory,
  (item: any, courseId: number, lessonId: number) =>
    `/api/courses/${courseId}/lessons/${lessonId}/multiple-choice`,
  mapExercise
);

/**
 * Creates a new flashcard exercise
 * @param {string} type the type of the exercise (either "multiple_choice" or "flashcard")
 * @returns {function} The redux thunk
 */
export const saveExerciseFlashcard = createCreateItemThunkCreator(
  saveExerciseActionFactory,
  (item: any, courseId: number, lessonId: number) =>
    `/api/courses/${courseId}/lessons/${lessonId}/flashcard`,
  mapExercise
);

/**
 * Creates a new free text exercise
 * @param {string} type the type of the exercise (either "multiple_choice" or "flashcard")
 * @returns {function} The redux thunk
 */
export const saveExerciseFreeText = createCreateItemThunkCreator(
  saveExerciseActionFactory,
  (item: any, courseId: number, lessonId: number) =>
    `/api/courses/${courseId}/lessons/${lessonId}/free-text`,
  mapExercise
);

/**
 * Creates a new static content
 * @param {string} type the type of the exercise (either "multiple_choice" or "flashcard")
 * @returns {function} The redux thunk
 */
export const saveStaticContent = createCreateItemThunkCreator(
  saveExerciseActionFactory,
  (item: any, courseId: number, lessonId: number) =>
    `/api/courses/${courseId}/lessons/${lessonId}/static-content`,
  mapExercise
);

/**
 * Saves a exercise based on the type attribute
 * @param {Exercise} exercise that will be created
 * @param {bool} visualize whether the action should be visualized
 * @param {number} courseId the id of the course
 * @param {number} lessonId the id of the lesson
 * @returns {function} redux thunk to create an exercise
 */
export const saveExercise = (
  exercise: Exercise,
  visualize: boolean,
  courseId: number,
  lessonId: number
) => {
  const previousUid = exercise.uid;
  switch (exercise.type) {
    case ExerciseType.Flashcard:
      return saveExerciseFlashcard(
        ExerciseFlashcardFactory.toDTO(<ExerciseFlashcard>exercise),
        visualize,
        courseId,
        lessonId,
        previousUid
      );
    case ExerciseType.MultipleChoice:
      return saveExerciseMultipleChoice(
        ExerciseMultipleChoiceFactory.toDTO(<ExerciseMultipleChoice>exercise),
        visualize,
        courseId,
        lessonId,
        previousUid
      );
    case ExerciseType.FreeText:
      return saveExerciseFreeText(
        ExerciseFreeTextFactory.toDTO(<ExerciseFreeText>exercise),
        visualize,
        courseId,
        lessonId,
        previousUid
      );
    case ExerciseType.StaticContent:
      return saveStaticContent(
        StaticContentFactory.toDTO(<StaticContent>exercise),
        visualize,
        courseId,
        lessonId,
        previousUid
      );
  }
};

/**
 * Saves a new exercise or updates it
 * @param exercise
 * @param visualize
 * @param courseId
 * @param lessonId
 * @returns {Promise}
 */
export const saveOrUpdateExercise = (
  exercise: Exercise,
  visualize: boolean,
  courseId: number,
  lessonId: number
) => {
  if (exercise.id === "new") {
    return saveExercise(exercise, visualize, courseId, lessonId);
  } else {
    return updateExercise(exercise, visualize, courseId, lessonId);
  }
};

/**
 * Removes an exercise from the redux store and deletes it on the server if it has been saved yet
 * @param exercise
 * @param visualize
 * @param courseId
 * @param lessonId
 * @returns {DeleteItemAction}
 */
export const removeOrDeleteExercise = (
  exercise: Exercise,
  visualize: boolean,
  courseId: number,
  lessonId: number
) => {
  if (exercise.id === "new") {
    return removeExercise(exercise, visualize, courseId, lessonId);
  } else {
    return deleteExercise(exercise, visualize, courseId, lessonId);
  }
};

/**
 * Prepares the exerciseOrder array before it's sent to the server
 * @param {Array} exerciseOrder an array of {exerciseId, type} objects
 * @returns {Array} the formatted array for the server
 */
export const prepareExerciseOrder = (
  exerciseOrder: Array<{ exerciseId: number; type: ExerciseType }>
) => exerciseOrder.map(e => ({ exercise_id: e.exerciseId, type: e.type }));

/**
 * Action dispatched before and after deleting an item
 * @param {object} itemId The id of the item that should be / was deleted
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The course's id the exercise is part of
 * @param {number} lessonId The lesson's id the exercise is part of
 * @returns {object} The redux action
 */
export const deleteExerciseActionFactory = createDeleteItemActionCreator(
  itemName,
  "courseId",
  "lessonId",
  "previousUid"
);

/**
 * Deletes an exercise
 * @param {Exercise} exercise uid of the exercise
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The course's id the lesson's part of
 * @param {number} lessonId The lesson's id
 * @returns {function} The redux thunk
 */
export const deleteExercise = (
  exercise: Exercise,
  visualize: boolean,
  courseId: number,
  lessonId: number
) => {
  switch (exercise.type) {
    case ExerciseType.Flashcard:
      return deleteExerciseFlashcard(
        exercise.id,
        visualize,
        courseId,
        lessonId,
        exercise.uid
      );
    case ExerciseType.StaticContent:
      return deleteStaticContent(
        exercise.id,
        visualize,
        courseId,
        lessonId,
        exercise.uid
      );
    case ExerciseType.MultipleChoice:
      return deleteExerciseMultipleChoice(
        exercise.id,
        visualize,
        courseId,
        lessonId,
        exercise.uid
      );
    case ExerciseType.FreeText:
      return deleteExerciseFreeText(
        exercise.id,
        visualize,
        courseId,
        lessonId,
        exercise.uid
      );
  }
};

/**
 * Deletes a multiple choice exercise
 * @param {string} exerciseId id of the exercise
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The course's id the lesson's part of
 * @param {number} lessonId The lesson's id
 * @returns {function} The redux thunk
 */
export const deleteExerciseMultipleChoice = createDeleteItemThunkCreator(
  deleteExerciseActionFactory,
  (exerciseId: number, courseId: number, lessonId: number) =>
    `/api/courses/${courseId}/lessons/${lessonId}/multiple-choice/${exerciseId}`
);

/**
 * Deletes a flashcard exercise
 * @param {number} exerciseId id of the exercise
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The course's id the lesson's part of
 * @param {number} lessonId The lesson's id
 * @returns {function} The redux thunk
 */
export const deleteExerciseFlashcard = createDeleteItemThunkCreator(
  deleteExerciseActionFactory,
  (exerciseId: string, courseId: number, lessonId: number) =>
    `/api/courses/${courseId}/lessons/${lessonId}/flashcard/${exerciseId}`
);

/**
 * Deletes a flashcard exercise
 * @param {number} exerciseId id of the exercise
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The course's id the lesson's part of
 * @param {number} lessonId The lesson's id
 * @returns {function} The redux thunk
 */
export const deleteStaticContent = createDeleteItemThunkCreator(
  deleteExerciseActionFactory,
  (exerciseId: number, courseId: number, lessonId: number) =>
    `/api/courses/${courseId}/lessons/${lessonId}/static-content/${exerciseId}`
);

/**
 * Deletes a free text exercise
 * @param {number} exerciseId id of the exercise
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The course's id the lesson's part of
 * @param {number} lessonId The lesson's id
 * @returns {function} The redux thunk
 */
export const deleteExerciseFreeText = createDeleteItemThunkCreator(
  deleteExerciseActionFactory,
  (exerciseId: number, courseId: number, lessonId: number) =>
    `/api/courses/${courseId}/lessons/${lessonId}/free-text/${exerciseId}`
);
