export interface Dictionary<T> {
  [Key: string]: T;
}

/**
 * Creates a dictionary from an array
 * @param arr the array
 * @param keyProp the keyProp that should be used as the key
 * @returns the dictionary
 */
export function arrayToDictionary<T>(
  arr: Array<T>,
  keyProp: string
): Dictionary<T> {
  let dict: Dictionary<T> = {};
  arr.map((value: T) => {
    dict[value[keyProp]] = value;
  });
  return dict;
}

/**
 * Creates an array from a dictionary
 * @param dict the dictionary
 * @returns the array
 */
export function dictionaryToArray<T>(dict: Dictionary<T>): Array<T> {
  let arr: Array<T> = [];
  Object.keys(dict).forEach((key: string) => {
    arr.push(dict[key]);
  });
  return arr;
}

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce((res, key) => ((res[key] = obj[key]), res), {});
