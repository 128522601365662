/**
 * A user
 */
export interface User {
  id: number;
  username: string;
  lastname: string;
  firstname: string;
  email: string;
  createdAt: string;
  updatedAt: string;
}

export interface UserDTO {
  id: number;
  username: string;
  lastname: string;
  firstname: string;
  email: string;
  created_at: string;
  updated_at: string;
}

export enum UserType {
  Author = "author",
  Collaborator = "collaborator",
  Subscriber = "subscriber"
}

export interface UserProgress {
  correct: number;
  once: number;
  wrong: number;
  total: number;
  tries: number;
}

/**
 * Create Users
 */
export class UserFactory {
  /**
   * Creates a DTO from the user
   * @param user
   * @returns the DTO
   */
  static toDTO(user: User): UserDTO {
    let { createdAt: created_at, updatedAt: updated_at, ...rest } = user;
    return { ...rest, created_at, updated_at };
  }

  /**
   * Creates a user from a DTO
   * @param dto
   * @returns user
   */
  static fromDTO(dto: UserDTO): User {
    let { created_at: createdAt, updated_at: updatedAt, ...rest } = dto;
    return { ...rest, createdAt, updatedAt };
  }
}
