import React from "react";
import { Button as ReactstrapButton } from "reactstrap";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { shadows } from "../utilities/style";

const buttonEffect = keyframes`
  to {
    opacity: 0;
    top: -8px;
    left: -8px;
    bottom: -8px;
    right: -8px;
    border-width: 10px;
  }
`;
const StyledButton = styled(ReactstrapButton)`
  &.btn {
    position: relative;
    transition: all 0.15s ease-in-out;
  }
  &.btn-clicked:after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: inherit;
    border: 0 solid #1890ff;
    opacity: 0.4;
    animation: ${buttonEffect} 0.4s;
    display: block;
  }
  &:active {
    transform: translateY(1px);
  }
  &:focus {
    box-shadow: ${shadows.outline};
  }
`;

/**
 * A custom button component
 */
export default class Button extends ReactstrapButton {
  static propTypes = {
    loading: PropTypes.bool,

    // https://reactstrap.github.io/components/buttons/
    disabled: PropTypes.bool,
    outline: PropTypes.bool,
    active: PropTypes.bool,
    block: PropTypes.bool,
    color: PropTypes.string,
    tag: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.func,
          PropTypes.string,
          PropTypes.shape({
            $$typeof: PropTypes.symbol,
            render: PropTypes.func
          })
        ])
      )
    ]),
    innerRef: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
      PropTypes.string
    ]),
    onClick: PropTypes.func,
    size: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    cssModule: PropTypes.object,
    close: PropTypes.bool
  };

  /**
   * Creates a new instance of the button component
   * @param {Object} props The object properties
   */
  constructor(props) {
    super(props);
    this.state = {
      clicked: false
    };
  }

  /**
   * Lifecycle method called before the component is unmounted
   * @returns {void}
   */
  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  };

  /**
   * Handles a click on the button
   * @param {Event} e The click event
   * @returns {void}
   */
  handleClick = e => {
    const { onClick, disabled, loading } = this.props;
    if (!loading && !disabled) {
      if (onClick) {
        onClick(e);
      }
      // Add click effect
      this.setState({ clicked: true });
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(
        () => this.setState({ clicked: false }),
        400
      );
    }
  };

  /**
   * Renders the button component
   * @returns {Component} The component
   */
  render() {
    const { clicked } = this.state;
    const { loading, children, disabled, className, ...rest } = this.props;
    const locked = disabled || loading;
    return (
      <StyledButton
        {...rest}
        className={className + " " + (clicked ? "btn-clicked" : "")}
        onClick={this.handleClick}
        disabled={locked}
      >
        {loading && (
          <FontAwesomeIcon className={"mr-2"} icon={["far", "spinner"]} spin />
        )}
        {children}
      </StyledButton>
    );
  }
}
