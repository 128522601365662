import { fetchApi } from "../utilities/api";
import { createFetchActionFactory } from "../utilities/action";
import {
  createUpdateItemActionCreator,
  createUpdateItemThunkCreator
} from "../utilities/action";

/**
 * Action called before and after fetching the JWT token
 * @param {boolean} isFetching Whether the action currently is in progress
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {string} token The received token
 * @returns {object} The redux action
 */
const fetchJwtToken = createFetchActionFactory("FETCH_JWT_TOKEN", "token");

/**
 * Verifies the jwt token
 * @param {boolean} isFetching Whether the action currently is in progress
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {boolean} verified Whether the verification was successful
 * @returns {object} The redux action
 */
const verifyJwtToken = createFetchActionFactory("VERIFY_JWT_TOKEN", "verified");

/**
 * Resets the jwt token and by doing that deauthenticates the current user
 * @param {boolean} isFetching Whether the action currently is in progress
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {object} The redux action
 */
export const resetJwtToken = createFetchActionFactory("RESET_JWT_TOKEN");

/**
 * Action called before and after fetching the authenticated user
 * @param {boolean} isFetching Whether the action currently is in progress
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} user The received user
 * @returns {object} The redux action
 */
const fetchAuthUser = createFetchActionFactory("FETCH_AUTH_USER", "user");

/**
 * Updates the authenticated user data
 * @param {boolean} isFetching Whether the action currently is in progress
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} user The received user
 * @returns {object} The redux action
 */
const updateAuthUserAction = createUpdateItemActionCreator("AUTH_USER", "user");

/**
 * Update the user information of the currently authenticated user
 * @param {Object} user updated user object
 * @returns {function} The redux thunk
 */
export const updateAuthUser = user =>
  createUpdateItemThunkCreator(updateAuthUserAction, () => `/api/users/me`)(
    null,
    user,
    false
  );

/**
 * Logs a user in using a jwt token
 * @param {string} login The user's login (email or username)
 * @param {string} password The user's password
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} A redux thunk
 */
export const login = (login, password, visualize = false) => dispatch => {
  dispatch(fetchJwtToken(true, null, visualize));
  dispatch(fetchAuthUser(true, null, visualize));

  return fetchApi("/api/users/login", {
    method: "POST",
    body: JSON.stringify({ login, password })
  })
    .then(({ token, data }) => {
      dispatch(fetchJwtToken(false, null, visualize, token));
      dispatch(fetchAuthUser(false, null, visualize, data));
      localStorage.setItem("jwt-token", token);

      return Promise.resolve(token);
    })
    .catch(error => {
      dispatch(fetchJwtToken(false, error, visualize, null));
      dispatch(fetchAuthUser(false, error, visualize, {}));

      localStorage.removeItem("jwt-token", null);

      return Promise.reject(error);
    });
};

/**
 * Action called before and after registering a user
 * @param {boolean} isFetching Whether the action currently is in progress
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {object} The redux action
 */
const registerUser = createFetchActionFactory("REGISTER_USER");

/**
 * Registers a new user
 * @param {string} firstname The user's (first) name
 * @param {string} lastname The user's lastname
 * @param {string} username The user's username
 * @param {string} email The user's email
 * @param {string} password The user's password
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} A redux thunk
 */
export const register = (
  firstname,
  lastname,
  username,
  email,
  password,
  visualize = false
) => dispatch => {
  dispatch(registerUser(true, null, visualize));

  return fetchApi("/api/users/register", {
    method: "POST",
    body: JSON.stringify({ firstname, lastname, username, email, password })
  })
    .then(({ token, data }) => {
      dispatch(fetchJwtToken(false, null, visualize, token));
      dispatch(fetchAuthUser(false, null, visualize, data));
      dispatch(registerUser(false, null, visualize));
      localStorage.setItem("jwt-token", token);

      return Promise.resolve(token);
    })
    .catch(error => {
      dispatch(fetchJwtToken(false, error, visualize, null));
      dispatch(fetchAuthUser(false, error, visualize, {}));
      dispatch(registerUser(false, error, visualize));

      localStorage.removeItem("jwt-token", null);

      return Promise.reject(error);
    });
};

/**
 * Action called before and after resetting a user's password
 * @param {boolean} isFetching Whether the action currently is in progress
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {object} The redux action
 */
const resetPasswordAction = createFetchActionFactory("RESET_PASSWORD");

/**
 * Requests a password resets for a given user's email address
 * @param {string} email The user's email
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} A redux thunk
 */
export const requestReset = (email, visualize = false) => dispatch => {
  dispatch(resetPasswordAction(true, null, visualize));

  return fetchApi("/api/users/password-reset", {
    method: "POST",
    body: JSON.stringify({ email })
  })
    .then(({ message }) => {
      dispatch(resetPasswordAction(false, null, visualize));

      return Promise.resolve(message);
    })
    .catch(error => {
      dispatch(resetPasswordAction(false, error, visualize));

      return Promise.reject(error);
    });
};

/**
 * Verifies the password reset Token
 * @param {string} email : the email address of the user
 * @param {string} password : the new password
 * @param {string} password_confirmation : confirmation of new password
 * @param {string} token : password reset token that was sent by mail
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} A redux thunk
 */
export const resetPassword = (
  email,
  password,
  password_confirmation,
  token,
  visualize = false
) => dispatch => {
  dispatch(resetPasswordAction(true, null, visualize));

  return fetchApi("/api/users/password-reset", {
    method: "PUT",
    body: JSON.stringify({ email, password, password_confirmation, token })
  })
    .then(({ message }) => {
      dispatch(resetPasswordAction(false, null, visualize));

      return Promise.resolve(message);
    })
    .catch(error => {
      dispatch(resetPasswordAction(false, error, visualize));

      return Promise.reject(error);
    });
};

/**
 * Verifies the passed jwt token
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} A redux thunk
 */
export const verifyToken = visualize => dispatch => {
  dispatch(verifyJwtToken(true, null, visualize));

  return fetchApi("/api/users/me", {
    method: "POST"
  })
    .then(user => {
      dispatch(verifyJwtToken(false, null, visualize, true));
    })
    .catch(error => {
      dispatch(fetchJwtToken(false, null, visualize, false));

      return Promise.reject(error);
    });
};

/**
 * Fetches the authenticated user
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} A redux thunk
 */
export const fetchAuthenticatedUser = visualize => dispatch => {
  dispatch(fetchAuthUser(true, null, visualize));

  return fetchApi("/api/users/me", {
    method: "GET"
  })
    .then(user => {
      dispatch(fetchAuthUser(false, null, visualize, user));

      return Promise.resolve(user);
    })
    .catch(error => {
      dispatch(fetchAuthUser(false, error, visualize));

      return Promise.reject(error);
    });
};

/**
 * Logs the currently authenticated user out
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} A redux thunk
 */
export const logout = visualize => dispatch => {
  dispatch(resetJwtToken(true, null, visualize, false));

  return fetchApi("/api/users/logout", {
    method: "POST"
  })
    .then(() => {
      localStorage.removeItem("jwt-token", null);
      dispatch(resetJwtToken(false, null, visualize, false));
      return Promise.resolve();
    })
    .catch(error => {
      localStorage.removeItem("jwt-token", null);
      dispatch(resetJwtToken(false, error, visualize, false));
      return Promise.reject(error);
    });
};

/**
 * Resets the authentication for the currently authenticated user
 * @param {boolean} visualize Whether to visualize the progress
 * @returns {function} A redux thunk
 */
export const resetAuth = visualize => dispatch => {
  localStorage.removeItem("jwt-token", null);
  return dispatch(resetJwtToken(false, null, visualize, false));
};
