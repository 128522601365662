import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Badge, Progress } from "reactstrap";
import { Popover, PopoverBody } from "reactstrap/lib/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Btn from "./Button";

const StyledProgress = styled(Progress)`
  && {
    height: 0.625rem;
    flex: 1;
  }
`;

const UnviewedProgress = styled.div`
  background-color: #e9ecef !important;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  vertical-align: top;
`;

/**
 * A progressbar component
 * @returns {Component} The component
 */
class Progressbar extends React.PureComponent {
  /**
   * Creates a new instance
   * @param {Object} props The component properties
   * @returns {Progressbar} The new instance
   */
  constructor(props) {
    super(props);
    this.state = { popoverOpen: false };
  }
  /**
   * Toggles whether the popover should be shown
   * @returns {void}
   */
  toggle = () => this.setState({ popoverOpen: !this.state.popoverOpen });

  /**
   * Renders the component
   * @returns {Component} The component
   */
  render = () => {
    const { value, id } = this.props;
    const total =
      value["hard"] +
      value["wrong"] +
      value["once"] +
      value["correct"] +
      value["unviewed"];
    return (
      <div className="d-flex mt-auto">
        <StyledProgress multi className="mt-2">
          <div
            id="ProgressHard"
            className="progress-bar bg-danger"
            role="progressbar"
            style={{ width: (value["hard"] / total) * 100 + "%" }}
            aria-valuenow={value["hard"] / total}
            aria-valuemin="0"
            aria-valuemax="100"
          />
          <div
            id="ProgressWrong"
            className="progress-bar bg-warning"
            role="progressbar"
            style={{ width: (value["wrong"] / total) * 100 + "%" }}
            aria-valuenow={value["wrong"] / total}
            aria-valuemin="0"
            aria-valuemax="100"
          />
          <div
            id="ProgressOnce"
            className="progress-bar bg-info"
            role="progressbar"
            style={{ width: (value["once"] / total) * 100 + "%" }}
            aria-valuenow={value["once"] / total}
            aria-valuemin="0"
            aria-valuemax="100"
          />
          <div
            id="ProgressCorrect"
            className="progress-bar bg-success"
            role="progressbar"
            style={{ width: (value["correct"] / total) * 100 + "%" }}
            aria-valuenow={value["correct"] / total}
            aria-valuemin="0"
            aria-valuemax="100"
          />
          <UnviewedProgress
            id="ProgressUnviewed"
            className="progress-bar"
            role="progressbar"
            style={{ width: (value["unviewed"] / total) * 100 + "%" }}
            aria-valuenow={value["unviewed"] / total}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </StyledProgress>
        <span className={"ml-2 text-secondary"}>
          <strong>
            {total > 0
              ? Math.round(
                  ((value["correct"] +
                    value["once"] / 2 +
                    value["wrong"] / 4 +
                    value["hard"] / 4) /
                    total) *
                    100
                )
              : 0}
            %
          </strong>
        </span>
        <Btn
          id={"popover-" + id}
          color="link"
          onClick={() => this.toggle()}
          className={"pb-0 pl-2 pr-0 pt-1"}
        >
          <StyledFontAwesomeIcon icon={["far", "info-circle"]} />
        </Btn>
        <Popover
          placement="bottom"
          isOpen={this.state.popoverOpen}
          target={"popover-" + id}
          toggle={this.toggle}
        >
          <PopoverBody>
            2 x falsch: <Badge color="danger">{value["hard"]}</Badge>
            <br />1 x falsch: <Badge color="warning">{value["wrong"]}</Badge>
            <br />1 x richtig: <Badge color="info">{value["once"]}</Badge>
            <br />2 x richtig: <Badge color="success">{value["correct"]}</Badge>
            <br />
            ungesehen: <Badge color="light">{value["unviewed"]}</Badge>
            <br />
          </PopoverBody>
        </Popover>
      </div>
    );
  };
}

Progressbar.propTypes = {
  value: PropTypes.object,
  id: PropTypes.number
};

export default Progressbar;
