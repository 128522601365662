import React, { useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, ButtonGroup, Form, Popover, PopoverBody } from "reactstrap";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  VALID_IMAGE_EXTENSIONS,
  VALID_IMAGE_TYPES,
  validateImage
} from "../../../utilities/validation";
import Button from "../../Button";
import AntonContext from "../AntonContext";

const Preview = styled.img`
  height: 100%;
  width: auto;
  border-radius: 2px;
  margin-right: 0.5rem;
`;

/**
 * Renders a popover to edit the image
 * @returns {Component} the react component
 */
const ImageEditPopover = React.memo(({ editor, blocks, close }) => {
  const [file, setFile] = React.useState(null);
  const [src, setSrc] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const { uploadImage } = useContext(AntonContext);

  const onUpload = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      setUploading(true);

      uploadImage(file)
        .then(image => {
          setUploading(false);
          requestAnimationFrame(() =>
            editor.setBlocks({
              type: "image",
              data: { image }
            })
          );
          close();
        })
        .catch(error => {
          setError(error.errors);
          setUploading(false);
        });
    },
    [setUploading, setError, uploadImage, editor, file, close]
  );

  const onChange = useCallback(e => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const errors = [] || validateImage(file);

    setFile(file);
    setError(errors);

    if (errors.length > 0) {
      setSrc(null);
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () => setSrc(reader.result), false);
    reader.readAsDataURL(file);
  }, []);

  const removeImage = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      requestAnimationFrame(() => editor.setBlocks("paragraph"));
      close();
    },
    [editor, close]
  );

  return (
    <PopoverBody>
      <Form inline>
        <React.Fragment>
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="anton-upload-image"
              accept={
                VALID_IMAGE_EXTENSIONS.join(",") +
                "," +
                VALID_IMAGE_TYPES.join(",")
              }
              onChange={onChange}
            />
            <label
              className="custom-file-label justify-content-start"
              htmlFor="anton-upload-image"
              data-browse-text="Wähle ein Bild"
            >
              {src && <Preview src={src} />}
              {file ? file.name : "Kein Bild ausgewählt"}
            </label>
          </div>
          <p className="text-muted small mt-2">
            Erlaubt sind Bilder in den Formaten{" "}
            <strong>.png, .jpg, .gif und .svg</strong> mit einer maximalen
            Grösse von <strong>2 MB</strong>.
          </p>
          {error &&
            error.file &&
            error.file.map((error, index) => (
              <Alert key={index} color="danger" className="mt-2">
                {error}
              </Alert>
            ))}
          <Button
            size="sm"
            className={"mr-1"}
            color={"primary"}
            onClick={onUpload}
            loading={uploading}
            disabled={!file}
          >
            Hochladen
          </Button>
          <Button size="sm" className={"mr-1"} onClick={close}>
            Abbrechen
          </Button>
        </React.Fragment>
      </Form>
    </PopoverBody>
  );
});

export default ImageEditPopover;
