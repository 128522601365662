/**
 * Interface that describes a "minimal" exercise, requiring only necessary properties
 */
import {
  Exercise,
  ExerciseDTO,
  ExerciseFactory,
  ExerciseType,
  IExercise
} from "./Exercise";
import {
  ExerciseFreeTextAnswer,
  ExerciseFreeTextAnswerDTO,
  ExerciseFreeTextAnswerFactory
} from "./ExerciseFreeTextAnswer";
import {
  emptyString,
  jsonToHtml,
  jsonToText
} from "../components/anton/helpers/serializer";
import { ExerciseMultipleChoiceAnswerFactory } from "./ExerciseMultipleChoiceAnswer";
import { ExerciseFlashcard } from "./ExerciseFlashcard";
import { arrayToDictionary, Dictionary, dictionaryToArray } from "./Types";

export interface IExerciseFreeText extends IExercise {
  question?: string;
  questionHtml?: string;
  explanation?: string;
  explanationHtml?: string;
  strict?: boolean;
  answers?: Dictionary<ExerciseFreeTextAnswer>;
  correct?: boolean;
}

/**
 * A Free Text exercise
 */
export interface ExerciseFreeText extends Exercise {
  question: string;
  questionHtml: string;
  explanation: string;
  explanationHtml: string;
  strict: boolean;
  answers: Dictionary<ExerciseFreeTextAnswer>;
  correct?: boolean;
}

/**
 * Create free text exercises
 */
export class ExerciseFreeTextFactory extends ExerciseFactory {
  /**
   * Creates a DTO from the exercise
   * @param exercise
   * @returns the DTO
   */
  static toDTO(exercise: ExerciseFreeText): ExerciseFreeTextDTO {
    let {
      question,
      questionHtml,
      correct,
      explanation,
      explanationHtml,
      strict,
      answers,
      ...rest
    } = exercise;
    let dto = super.toDTO({ ...rest });
    return {
      ...dto,
      question,
      strict,
      explanation,
      answers: dictionaryToArray(answers)
        .filter(emptyAnswerStringFilter)
        .map(answer => ExerciseFreeTextAnswerFactory.toDTO(answer))
    };
  }

  /**
   * Creates an exercise from a DTO
   * @param dto
   * @returns exercise
   */
  static fromDTO(dto: ExerciseFreeTextDTO): ExerciseFreeText {
    let { question, strict, explanation, answers, ...rest } = dto;

    let exercise = super.fromDTO({ ...rest });
    const mappedAnswers = answers.map(ExerciseFreeTextAnswerFactory.fromDTO);
    const answerDictionary = arrayToDictionary(mappedAnswers, "uid");
    strict = !!strict;
    return this.create({
      ...exercise,
      explanation,
      question,
      strict,
      answers: answerDictionary
    });
  }

  /**
   * Creates a new exercise
   * @param params
   * @returns Exercise
   */
  static create(
    params: IExerciseFreeText = {} as IExerciseFreeText
  ): ExerciseFreeText {
    let {
      question = emptyString,
      questionHtml = jsonToHtml(question),
      excerpt = jsonToText(question),
      explanation = null,
      explanationHtml = jsonToHtml(explanation),
      strict = false,
      answers = arrayToDictionary(
        [ExerciseFreeTextAnswerFactory.create()],
        "uid"
      ),
      correct = undefined,
      ...rest
    } = params;
    let exercise = super.create({ ...rest, type: ExerciseType.FreeText });
    return {
      ...exercise,
      question,
      questionHtml,
      explanation,
      explanationHtml,
      strict,
      answers,
      excerpt,
      correct
    };
  }

  /**
   * Duplicates an exercise
   * @param exercise
   * @returns ExerciseFreeText
   */
  static duplicate(exercise: ExerciseFreeText): ExerciseFreeText {
    const answers = arrayToDictionary(
      dictionaryToArray(exercise.answers).map(
        ExerciseFreeTextAnswerFactory.duplicate
      ),
      "uid"
    );
    const newExercise = super.duplicate(exercise);
    return ExerciseFreeTextFactory.create({
      ...exercise,
      ...newExercise,
      answers
    });
  }
}

export interface ExerciseFreeTextDTO extends ExerciseDTO {
  question: string;
  explanation: string;
  strict: boolean;
  answers: Array<ExerciseFreeTextAnswerDTO>;
}

/**
 * Filter that removes all "empty" free textanswers
 * @param {ExerciseFreeTextAnswer} answer The answer
 * @returns {boolean} Whether the passed object describes an empty answer
 */
export const emptyAnswerStringFilter = (answer: ExerciseFreeTextAnswer) =>
  typeof answer.id === "string" ? answer.content !== "" : true;
