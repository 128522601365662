import {
  emptyString,
  jsonToHtml,
  jsonToText
} from "../components/anton/helpers/serializer";
import {
  ExerciseMultipleChoiceAnswer,
  ExerciseMultipleChoiceAnswerDTO,
  ExerciseMultipleChoiceAnswerFactory
} from "./ExerciseMultipleChoiceAnswer";
import {
  Exercise,
  ExerciseDTO,
  ExerciseFactory,
  ExerciseType,
  IExercise
} from "./Exercise";
import { arrayToDictionary, Dictionary, dictionaryToArray } from "./Types";

/**
 * Interface that describes a "minimal" exercise, requiring only necessary properties
 */
export interface IExerciseMultipleChoice extends IExercise {
  question?: string;
  questionHtml?: string;
  shuffleAnswers?: boolean;
  answers?: Dictionary<ExerciseMultipleChoiceAnswer>;
  correct?: boolean;
}

/**
 * Factory for creating an exercise
 */
export class ExerciseMultipleChoiceFactory extends ExerciseFactory {
  /**
   * Creates a DTO from the exercise
   * @param exercise
   * @returns the DTO
   */
  static toDTO(exercise: ExerciseMultipleChoice): ExerciseMultipleChoiceDTO {
    let {
      question,
      questionHtml,
      correct,
      shuffleAnswers: shuffle_answers,
      answers,
      ...rest
    } = exercise;
    let dto = super.toDTO({ ...rest });

    return {
      ...dto,
      question,
      shuffle_answers,
      answers: dictionaryToArray(answers)
        .filter(isNotEmptyMultipleChoiceAnswer)
        .sort((a, b) => a.order - b.order)
        .map(answer => ExerciseMultipleChoiceAnswerFactory.toDTO({ ...answer }))
    };
  }

  /**
   * Creates an exercise from a DTO
   * @param dto
   * @returns exercise
   */
  static fromDTO(dto: ExerciseMultipleChoiceDTO): ExerciseMultipleChoice {
    let { question, shuffle_answers: shuffleAnswers, answers, ...rest } = dto;
    let exercise = super.fromDTO({
      ...rest,
      type: ExerciseType.MultipleChoice
    });
    const mappedAnswers = answers.map(
      ExerciseMultipleChoiceAnswerFactory.fromDTO
    );

    const answerDictionary = arrayToDictionary<ExerciseMultipleChoiceAnswer>(
      mappedAnswers,
      "uid"
    );
    shuffleAnswers = !!shuffleAnswers;
    return ExerciseMultipleChoiceFactory.create({
      ...exercise,
      question,
      shuffleAnswers,
      answers: answerDictionary
    });
  }

  /**
   * Creates a new exercise
   * @param params
   * @returns Exercise
   */
  static create(
    params: IExerciseMultipleChoice = {} as IExerciseMultipleChoice
  ): ExerciseMultipleChoice {
    let {
      question = emptyString,
      questionHtml = jsonToHtml(question),
      excerpt = jsonToText(question),
      shuffleAnswers = true,
      answers = arrayToDictionary(
        [
          ExerciseMultipleChoiceAnswerFactory.create(),
          ExerciseMultipleChoiceAnswerFactory.create()
        ],
        "uid"
      ),
      correct = undefined,
      ...rest
    } = params;
    const exercise = super.create({
      ...rest,
      type: ExerciseType.MultipleChoice
    });
    return {
      ...exercise,
      question,
      questionHtml,
      excerpt,
      shuffleAnswers,
      answers,
      correct
    };
  }

  /**
   * Duplicates an exercise
   * @param exercise
   * @returns ExerciseMultipleChoice
   */
  static duplicate(exercise: ExerciseMultipleChoice): ExerciseMultipleChoice {
    const answers = arrayToDictionary(
      dictionaryToArray(exercise.answers).map(
        ExerciseMultipleChoiceAnswerFactory.duplicate
      ),
      "uid"
    );
    const newExercise = super.duplicate(exercise);

    return ExerciseMultipleChoiceFactory.create({
      ...exercise,
      ...newExercise,
      answers
    });
  }
}

/**
 * A multiple choice exercise
 */
export interface ExerciseMultipleChoice extends Exercise {
  question: string;
  questionHtml: string;
  shuffleAnswers: boolean;
  answers: Dictionary<ExerciseMultipleChoiceAnswer>;
  correct?: boolean;
}

/**
 * A data transfer object for the multiple choice exercises
 */
export interface ExerciseMultipleChoiceDTO extends ExerciseDTO {
  question: string;
  shuffle_answers: boolean;
  answers: Array<ExerciseMultipleChoiceAnswerDTO>;
}

/**
 * Filter that removes all "empty" multiple choice answers
 * @param {ExerciseMultipleChoiceAnswer} answer the answer
 * @returns {boolean} Whether the passed object describes an empty answer
 */
export const isNotEmptyMultipleChoiceAnswer = (
  answer: ExerciseMultipleChoiceAnswer
): boolean => (answer.id === "new" ? answer.content !== emptyString : true);
