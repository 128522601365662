const defaultState = { isFetching: false, error: null, user: {} };

/**
 * The reducer managing the authenticated user
 * @param {Object} state The redux state
 * @param {Object} action The dispatched action
 * @returns {Object} The new state
 */
const authenticatedUserReducer = (state = defaultState, action) => {
	switch (action.type) {
		case "FETCH_AUTH_USER":
			return {
				...state,
				isFetching: action.isFetching,
				error:
					action.error || action.error === null ? action.error : state.error,
				user: action.user ? action.user : state.user
			};
        case "UPDATE_AUTH_USER":
        	if(!action.isFetching && !action.error) {
        		return {
					...state,
					user: action.item
				}
			}
			return state;

		case "RESET_JWT_TOKEN":
			return defaultState;

		default:
			return state;
	}
};

export default authenticatedUserReducer;

/**
 * Returns the authenticated user
 * @param {Object} state This state
 * @returns {Object} The authenticted user
 */
export const getAuthenticatedUser = state => state.user;
/**
 * Checks whether the authenticated user is currently being fetched
 * @param {Object} state This state
 * @returns {boolean} Whether the user being fetched
 */
export const getAuthenticatedUserFetching = state => state.isFetching;
/**
 * Returns the error that occured while authenticating the current user
 * @param {Object} state This state
 * @returns {Error} The error
 */
export const getAuthenticatedUserError = state => state.error;
