import { combineReducers } from "redux";

import {
  createReducer,
  createAllIds,
  createById,
  wrap
} from "../../utilities/reducer";
import current, * as fromCurrentLesson from "./current";
import { isStaticContent } from "../../actions/exercise";

const itemName = "lesson";

export default combineReducers({
  current,
  byId: createById(itemName, "id", (state, action) => {
    switch (action.type) {
      case "CREATE_EXERCISE":
        if (!action.isFetching && !action.error) {
          return {
            ...state,
            [action.item.lessonId]: {
              ...state[action.item.lessonId],
              /* disable the option to shuffle exercises if static content is added */
              shuffleExercises: isStaticContent(action.item)
                ? false
                : state[action.item.lessonId].shuffleExercises,
              disableShuffleExercises: isStaticContent(action.item),
              exerciseUids: state[action.item.lessonId].exerciseUids
                ? [...state[action.item.lessonId].exerciseUids, action.item.uid]
                : [action.item.uid]
            }
          };
        }
        return state;
      case "DELETE_EXERCISE":
        if (!action.isFetching && !action.error) {
          return {
            ...state,
            [action.lessonId]: {
              ...state[action.lessonId],
              /* enable the option to shuffle exercises if static content is removed */
              disableShuffleExercises: !!state[
                action.lessonId
              ].exerciseUids.find(
                uid => isStaticContent(uid) && uid !== action.previousUid
              ),
              exerciseUids: state[action.lessonId].exerciseUids.filter(
                uid => uid !== action.previousUid
              )
            }
          };
        }
        return state;
      case "LESSON_ASSOCIATE_EXERCISES":
        return {
          ...state,
          [action.lessonId]: {
            ...state[action.lessonId],
            /* disable the option to shuffle exercises if static content is added */
            disableShuffleExercises: !!action.exerciseUids.find(uid =>
              isStaticContent(uid)
            ),
            exerciseUids: action.exerciseUids
          }
        };
      case "LESSON_UPDATE_EXERCISE_ORDER":
        if (action.isFetching || action.error) {
          return state;
        }

        const newState = { ...state };
        newState[action.lessonId] = {
          ...newState[action.lessonId],
          exerciseUids: action.exerciseOrder
        };

        return newState;

      case "LESSON_ORDER_EXERCISE":
        const previousExercises = state[action.lessonId].exerciseUids;

        const exerciseUids = new Array(previousExercises.length).fill(null);
        exerciseUids[action.targetIndex] =
          previousExercises[action.sourceIndex];

        for (let i = 0; i < previousExercises.length; i++) {
          //skips the copied index
          if (i == action.sourceIndex) {
            continue;
          }
          //and fills the rest from top to bottom
          for (let j = 0; j < exerciseUids.length; j++) {
            if (exerciseUids[j] === null) {
              exerciseUids[j] = previousExercises[i];
              break;
            }
          }
        }

        return {
          ...state,
          [action.lessonId]: {
            ...state[action.lessonId],
            exerciseUids
          }
        };
      default:
        return state;
    }
  }),
  allIds: createAllIds(itemName)
});

export {
  getAllItems as getLessons,
  getItemById as getLessonById
} from "utilities/reducer";

/**
 * Returns all children
 * @param {Object} state This state
 * @param {number} parentId An optional parent category id
 * @returns {Array} An array of all children
 */
export const getLessonChildrenIds = (state, parentId = 0) =>
  state.allIds
    .map(id => state.byId[id])
    .filter(lesson => lesson.parent === parentId)
    .map(lesson => lesson.id);

export const getCurrentLessonExercise = wrap(
  fromCurrentLesson.getCurrentLessonExercise,
  state => state.current
);
export const getCurrentLessonHearts = wrap(
  fromCurrentLesson.getCurrentLessonHearts,
  state => state.current
);
export const getCurrentLessonValidatedExercises = wrap(
  fromCurrentLesson.getCurrentLessonValidatedExercises,
  state => state.current
);
export const getCurrentLessonError = wrap(
  fromCurrentLesson.getCurrentLessonError,
  state => state.current
);
export const getCurrentLessonIsValidating = wrap(
  fromCurrentLesson.getCurrentLessonIsValidating,
  state => state.current
);
export const getCurrentLessonExercises = wrap(
  fromCurrentLesson.getCurrentLessonExercises,
  state => state.current
);

/**
 * Returns all the lessons for a given course
 * @param {Object} state This state
 * @param {number} courseId the id of the course
 * @returns {Array} array of lessons
 */
export const getCourseLessons = (state, courseId) =>
  state.allIds
    .map(id => state.byId[id])
    .filter(lesson => lesson.courseId === courseId);
