import { combineReducers } from "redux";
/**
 * Handles the state of the currently played lesson
 * @param {Object} state The previous state
 * @param {Object} action The action to proccess
 * @returns {Object} The next state
 */
const currentLessonReducer = (
	state = {
		hearts: 3,
		exercise: 0,
		exercises: [],
		validatedExercises: [
			/*{id, correct, message, answer}*/
		],
		isValidating: false,
		error: null
	},
	action
) => {
	switch (action.type) {
		case "FETCH_EXERCISE_VALIDATION":
			return action.isFetching || action.error !== null
				? {
						...state,
						isFetching: action.isFetching,
						error: action.error
				  }
				: {
						...state,
						hearts: action.hearts,
						validatedExercises: state.validatedExercises
							.map(exercise => exercise.id)
							.includes(action.exerciseId)
							? state.validatedExercises
							: [
									...state.validatedExercises,
									{
										id: action.exerciseId,
										correct: action.correct,
										message: action.message,
										answer: action.answer
									}
							  ],
						isFetching: action.isFetching,
						error: action.error
				  };
		case "CURRENT_LESSON_SET_EXERCISE":
			return { ...state, exercise: action.exercise };
		case "CURRENT_LESSON_SET_EXERCISES":
			return { ...state, exercises: action.exercises };
		case "CURRENT_LESSON_REPEAT_WRONG":
			return {
				...state,
				exercise: 0,
				exercises: state.validatedExercises
					.filter(exercise => exercise.correct === false)
					.map(ex => ex.id),
				validatedExercises: []
			};
		case "CURRENT_LESSON_REPEAT_ALL":
			return { ...state, exercise: 0, validatedExercises: [] };
		case "CURRENT_LESSON_NEXT_EXERCISE":
			return { ...state, exercise: state.exercise + 1 };
		case "CURRENT_LESSON_PREVIOUS_EXERCISE":
			return { ...state, exercise: state.exercise - 1 };
		case "CURRENT_LESSON_ADD_VALIDATED_EXERCISE":
			return {
				...state,
				validatedExercises: [
					...state.validatedExercises,
					{ id: action.exerciseId, correct: action.correct }
				]
			};
		default:
			return state;
	}
};

export default currentLessonReducer;

/**
 * Returns the current exercise index
 * @param {Object} state This part of the redux state
 * @returns {number} The currents exercise index
 */
export const getCurrentLessonExercise = state => state.exercise;

/**
 * Returns the currently loaded exercises
 * @param {Object} state This part of the redux state
 * @returns {number} The currents exercise index
 */
export const getCurrentLessonExercises = state => state.exercises;

/**
 * Returns the user's hearts for the current lesson
 * @param {Object} state This part of the redux state
 * @returns {number} The amount of hearts the user has
 */
export const getCurrentLessonHearts = state => state.hearts;
/**
 * Returns the array of all validated exercises
 * @param {Object} state This part of the redux state
 * @returns {Array} The array of exercises already answered
 */
export const getCurrentLessonValidatedExercises = state =>
	state.validatedExercises;

/**
 * Returns whether the current exercise is being validated
 * @param {Object} state This part of the redux state
 * @returns {Array} Whether the current exercise is being validated
 */
export const getCurrentLessonIsValidating = state => state.isValidating;

/**
 * Returns the error of the current lesson
 * @param {Object} state This part of the redux state
 * @returns {Array} The error of the current lesson
 */
export const getCurrentLessonError = state => state.error;
