import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import Button from "components/Button";
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  Alert
} from "reactstrap";
import yup from "yup";
import { Formik } from "formik";

import { requestReset } from "../actions/authentication";
import { getIsAuthenticating } from "../reducers";
import Wrapper from "./Wrapper";
import Spinner from "./Spinner";
import Flexbar from "./Flexbar";
import RegisterForm from "./forms/RegisterForm";
import PasswordResetForm from "./forms/PasswordResetForm";
import { login, resetPassword } from "../actions/authentication";
import Btn from "./Button";

/**
 * The password reset component
 * @returns {Component} The new component
 */
class PasswordReset extends React.PureComponent {
  /**
   * Creates a new instance
   * @param {Object} props The component properties
   * @returns {PasswordReset} The new instance
   */
  constructor(props) {
    super(props);
    this.state = { success: false };
  }
  /**
   * Returns the validation schema
   * @returns {Object} The validation schema
   */
  validationSchema = () => {
    return yup.object().shape({
      email: yup
        .string()
        .email()
        .required(),
      password: yup
        .string()
        .min(6)
        .required(),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwörter müssen übereinstimmen.")
        .required()
    });
  };
  /**
   * Function called when form is submitted
   * @param {Object} values The form values
   * @param {Object} formikProps The formik props
   * @returns {void}
   */
  handleSubmit = (
    values,
    {
      props,
      setSubmitting,
      setErrors /* setValues, setStatus, and other goodies */
    }
  ) => {
    const { dispatch, token } = this.props;

    dispatch(
      resetPassword(
        values.email,
        values.password,
        values.password_confirmation,
        token
      )
    )
      .then(() => {
        this.setState({ success: true });
        setSubmitting(false);
      })
      .catch(error => {
        setSubmitting(false);
        // TODO: transform API errors to Formik's errors
        let errors = { password_link_sending_failed: error.message };
        setErrors(errors);
        this.setState({ error });
      });
  };
  /**
   * Renders the component
   * @returns {Component} The component
   */
  render = () => {
    const { isAuthenticating } = this.props;
    const { error, success } = this.state;
    let errorMessage;
    if (error) {
      switch (error.message) {
        case "blabla":
          errorMessage = "Blabla";
          break;
        default:
          errorMessage = "Es ist ein Fehler aufgetreten.";
          break;
      }
    }
    return (
      <Wrapper header footer>
        <Container>
          <Row>
            <Col md={{ size: 4, offset: 4 }}>
              <h3 className={"my-4"}>Passwort zurücksetzen</h3>
              <Card body>
                {!success && (
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      password_confirmation: ""
                    }}
                    onSubmit={this.handleSubmit}
                    component={PasswordResetForm}
                    validationSchema={this.validationSchema}
                  />
                )}
                {success && (
                  <div>
                    <Alert className="my-2" color="success">
                      Das Passwort wurde erfolgreich geändert.
                    </Alert>
                    <Link to={"/login"}>
                      <Btn block color="primary">
                        Login
                      </Btn>
                    </Link>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    );
  };
}

/**
 * Maps some properties of the redux state to the component's properties
 * @param {Object} state The redux state
 * @param {Object} props The component properties
 * @returns {Object} The properties to inject into the component's properties
 */
const mapStateToProps = (
  state,
  {
    match: {
      params: { token }
    }
  }
) => ({
  isAuthenticating: getIsAuthenticating(state),
  token
});

export default connect(mapStateToProps)(PasswordReset);
