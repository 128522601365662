import { ExerciseFlashcard } from "./ExerciseFlashcard";
import { generateNonce } from "./Exercise";
import { Dictionary } from "./Types";

/**
 * Interface that describes a "minimal" free text answer, requiring only necessary properties
 */
export interface IExerciseFreeTextAnswer {
  id?: number | string;
  uid?: string;
  content?: string;
  createdAt?: string;
  updatedAt?: string;
}

/**
 * A answer for a free text exercise
 */
export class ExerciseFreeTextAnswer implements IExerciseFreeTextAnswer {
  id: number | string;
  uid: string;
  content: string;
  createdAt: string;
  updatedAt: string;
}

/**
 * Create exercise free text answers
 */
export class ExerciseFreeTextAnswerFactory {
  /**
   * Creates a DTO from the exercise
   * @param answer
   * @returns the DTO
   */
  static toDTO(answer: ExerciseFreeTextAnswer): ExerciseFreeTextAnswerDTO {
    let { id, content, uid } = answer;
    return {
      id: typeof id === "number" ? id : null,
      uid,
      content
    };
  }

  /**
   * Creates an exercise from a DTO
   * @param dto
   * @returns exercise
   */
  static fromDTO(dto: ExerciseFreeTextAnswerDTO): ExerciseFreeTextAnswer {
    let { created_at: createdAt, updated_at: updatedAt, ...rest } = dto;

    return ExerciseFreeTextAnswerFactory.create({
      ...rest,
      createdAt,
      updatedAt
    });
  }

  /**
   * Creates a new free text exercise answer
   * @param params
   * @returns Exercise
   */
  static create(
    params: IExerciseFreeTextAnswer = {} as IExerciseFreeTextAnswer
  ): ExerciseFreeTextAnswer {
    let {
      id = "new",
      uid = generateNonce(),
      content = "",
      createdAt = "",
      updatedAt = ""
    } = params;
    return { id, uid, content, createdAt, updatedAt };
  }

  /**
   * Duplicates an exercise answer
   * @param answer
   * @returns ExerciseFreeTextAnswer
   */
  static duplicate(answer: ExerciseFreeTextAnswer): ExerciseFreeTextAnswer {
    const { content } = answer;
    return ExerciseFreeTextAnswerFactory.create({ content });
  }

  /**
   * Checks if there are empty answers inside a dictionary
   * @param answers
   * @returns true if there are empty answers
   */
  static hasEmptyAnswers(answers: Dictionary<ExerciseFreeTextAnswer>): boolean {
    let emptyAnswers = false;
    Object.values(answers).forEach(answer => {
      if (!answer.content || answer.content.trim() === "") {
        emptyAnswers = true;
        return;
      }
    });
    return emptyAnswers;
  }
}

/**
 * A data transfer object for the free text exercise answers
 */
export interface ExerciseFreeTextAnswerDTO {
  id?: number;
  uid: string;
  content: string;
  created_at?: string;
  updated_at?: string;
}
