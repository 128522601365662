import React from "react";
import { FormGroup, Label, Alert } from "reactstrap";
import { Form } from "formik";

import Spinner from "../Spinner";
import Flexbar from "../Flexbar";
import Button from "../Button";
import InputField from "../input/InputField";
import WarnOnLeaving from "../WarnOnLeaving";

/**
 * Renders the register form
 * @param {Object} formikProps The formik props
 * @returns {Component} The component
 */
const RegisterForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting
}) => (
  <Form>
    <FormGroup>
      <Label for="name">Vorname</Label>
      <InputField type="text" name="firstname" placeholder="Wolfgang" />
    </FormGroup>
    <FormGroup>
      <Label for="lastname">Nachname</Label>
      <InputField type="text" name="lastname" placeholder="Goethe" />
    </FormGroup>
    <FormGroup>
      <Label for="email">E-Mail</Label>
      <InputField type="email" name="email" placeholder="wolfang@goethe.ch" />
    </FormGroup>
    <FormGroup>
      <Label for="username">Benutzername</Label>
      <InputField type="text" name="username" placeholder="Goethe49" />
    </FormGroup>
    <FormGroup>
      <Label for="password">Passwort</Label>
      <InputField type="password" name="password" placeholder="••••••••••" />
    </FormGroup>
    {errors.mimimi && <Alert color="danger">{errorMessage}</Alert>}

    <Button
      color="primary"
      type="submit"
      block
      disabled={isSubmitting}
      id={"submitRegisterForm"}
    >
      <Flexbar center>
        {isSubmitting && <Spinner color="#fff" size={1.5} margin={0.5} />}Registrieren
      </Flexbar>
    </Button>

    <WarnOnLeaving />
  </Form>
);
export default RegisterForm;
