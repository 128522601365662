import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardHeader,
  CardBody
} from "reactstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import MainContainer from "../components/MainContainer";
import Wrapper from "../components/Wrapper";
import NavbarLanding from "./landing/NavbarLanding";
import FooterLanding from "./landing/FooterLanding";
import ConversationImage from "../assets/images/conversation.svg";
import { colors } from "../utilities/style";

const PricingCard = styled(Card)``;

const Price = styled.h1`
  font-size: 4rem;
`;

/**
 * Container that displays the landing page
 * @returns {Component} The component
 */
class Pricing extends React.PureComponent {
  render() {
    return (
      <Wrapper fixed={"top"}>
        <NavbarLanding />
        <MainContainer className={"py-4"} id={"home"}>
          <Container>
            <Row>
              <Col md={{ size: "8" }} className={"my-4"}>
                <h2>
                  <strong>
                    Unser Preismodell ist einfach:
                    <br />
                    kostenlos für immer.
                  </strong>
                </h2>
                <p className={"lead my-3"}>
                  wit kann in der Basisvariante kostenlos verwendet werden. Für
                  erfahrene Benutzer und Bildungsinstitutionen bieten wir
                  umfangreiche Zusatzfunktionen zu einem monatlichen Fixpreis.
                </p>
              </Col>
              <Col md={{ size: "12" }} className={""}>
                <Card
                  body
                  inverse
                  className={"mb-4"}
                  style={{
                    backgroundColor: colors.grey_3,
                    borderColor: colors.black
                  }}
                >
                  <h6>
                    <strong>Jetzt wit-Pionier werden! 🚀</strong>
                  </h6>
                  <p className={"mb-0"}>
                    wit befindet sich momentan in der <b>Testphase</b> und kann
                    kostenlos ausprobiert werden. <br />
                    Mit deiner Registrierung nimmst du automatisch an unserem
                    Testprogramm teil und kannst wit komplett <b>
                      kostenlos
                    </b>{" "}
                    und <b>unverbindlich</b> ausprobieren.
                  </p>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: "4" }} sm={{ size: "6" }} className={"my-4"}>
                <PricingCard>
                  <CardHeader className={"text-center"}>
                    <strong>Basic</strong>
                  </CardHeader>
                  <CardBody className={"text-center"}>
                    <h1 className={"text-primary"}>
                      <strong>Gratis</strong>
                    </h1>
                  </CardBody>
                  <hr className={"my-0"} />
                  <CardBody>
                    <ul className={"list-unstyled"}>
                      <li>
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className={"mr-2 text-primary"}
                        />
                        5 öffentliche eigene / abonnierte Lehrgänge{" "}
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className={"mr-2 text-primary"}
                        />
                        max. 20 Benutzer pro Lehrgang
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className={"mr-2 text-primary"}
                        />
                        unbegrenzte Lektionen
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className={"mr-2 text-primary"}
                        />
                        unbegrenzte Übungen
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className={"mr-2 text-primary"}
                        />
                        detaillierte persönliche Auswertung
                      </li>
                    </ul>
                    <Link to={"/register"}>
                      <Button outline color={"primary"} block>
                        Kostenlos registrieren
                      </Button>
                    </Link>
                  </CardBody>
                </PricingCard>
              </Col>
              <Col md={{ size: "4" }} sm={{ size: "6" }} className={"my-4"}>
                <PricingCard>
                  <CardHeader className={"text-center bg-primary text-white"}>
                    <strong>Pro</strong>
                  </CardHeader>
                  <CardBody
                    className={
                      "py-2 text-center flex-row justify-content-center d-flex align-items-center"
                    }
                  >
                    <p className={"text-primary align-self-start pt-3"}>CHF</p>
                    <Price className={"text-primary mx-2 align-self-center"}>
                      <strong>5.-</strong>
                    </Price>
                    <p className={"align-self-start pt-3 text-secondary"}>
                      pro Benutzer pro Monat <br />
                    </p>
                  </CardBody>
                  <hr className={"my-0"} />
                  <CardBody>
                    <ul className={"list-unstyled"}>
                      <li>
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className={"mr-2 text-primary"}
                        />
                        unbegrenzte öffentliche Lehrgänge
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className={"mr-2 text-primary"}
                        />
                        unbegrenzte private Lehrgänge
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className={"mr-2 text-primary"}
                        />
                        unbegrenzte Benutzer pro Lehrgang
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className={"mr-2 text-primary"}
                        />
                        unbegrenzte Lektionen
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className={"mr-2 text-primary"}
                        />
                        unbegrenzte Übungen
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className={"mr-2 text-primary"}
                        />
                        detaillierte Auswertung aller Benutzer
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className={"mr-2 text-primary"}
                        />
                        Lehrgänge als Vorlage verwenden
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className={"mr-2 text-primary"}
                        />
                        Import / Export von Übungen
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className={"mr-2 text-primary"}
                        />
                        Premium Support
                      </li>
                    </ul>
                    <Link to={"/register"}>
                      <Button color={"primary"} block>
                        Registrieren
                      </Button>
                    </Link>
                  </CardBody>
                </PricingCard>
              </Col>
              <Col md={{ size: "4" }} sm={{ size: "6" }} className={"my-4"}>
                <PricingCard>
                  <CardHeader className={"text-center"}>
                    <strong>Enterprise</strong>
                  </CardHeader>
                  <CardBody>
                    <CardText>
                      Für grosse Teams und Institutionen stellen wir gerne ein
                      massgeschneidertes Angebot zusammen.
                    </CardText>
                    <Button
                      outline
                      color={"primary"}
                      block
                      onClick={() =>
                        (window.location.href = "mailto:hoi@witapp.io")
                      }
                    >
                      Kontakt aufnehmen
                    </Button>
                  </CardBody>
                </PricingCard>
              </Col>
            </Row>
          </Container>
        </MainContainer>
        <div className={"py-4 bg-white"}>
          <Container className={"py-4"}>
            <Row>
              <Col
                md={{ size: "6" }}
                className={"flex-column justify-content-center d-flex"}
              >
                <h2>
                  <strong>Ist wit etwas für dich?</strong>
                </h2>
                <p className={"my-3"}>
                  Du bist dir nicht sicher, wie du wit in deinen Unterricht
                  einbinden kannst?
                  <br />
                  Du benötigst Hilfe bei der Einarbeitung deiner bestehenden
                  Materialien?
                  <br />
                  <br />
                  Unsere Experten unterstützen dich bei der Einrichtung und
                  beraten dich gerne, wenn es um inhaltliche oder technische
                  Fragen geht.
                  <br />
                  <br />
                  <Button color="primary" className={"my-2 drift-open-chat"}>
                    Support-Chat starten
                  </Button>
                </p>
              </Col>
              <Col
                md={{ size: "6" }}
                className={
                  "text-center flex-column justify-content-center d-flex"
                }
              >
                <div>
                  <img src={ConversationImage} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterLanding />
      </Wrapper>
    );
  }
}

export default Pricing;
