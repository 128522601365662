import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Input, Popover, ButtonGroup } from "reactstrap";
import PropTypes from "prop-types";

import { MQ } from "../../../utilities/mathquill";
import { AntonPopoverBody } from "./AntonPopover";

/**
 * Renders a popover to edit the hyperlink
 * @returns {Component} the react component
 */

const LatexEditPopover = React.memo(({ editor, blocks, range, close }) => {
  const [latex, setLatex] = useState(() => {
    const node = blocks.find(node => node.type === "latex");
    return node ? node.data.get("latex") : "";
  });
  const inputRef = useRef(null);
  //const mathField = useRef(null);

  const removeLatex = useCallback(
    () => {
      requestAnimationFrame(() => editor.setBlocks("paragraph"));
      close();
    },
    [editor, close]
  );

  const updateLatex = useCallback(
    () => {
      if (latex) {
        const latexBlock = blocks.find(block => block.type === "latex");
        //is a immutable list, not an array thus .size
        if (latexBlock) {
          //update existing latex blocks
          const data = latexBlock.data.toJS();
          if (data.latex !== latex.trim()) {
            requestAnimationFrame(() =>
              editor.setNodeByKey(latexBlock.key, {
                data: { ...data, latex }
              })
            );
          }
        } else {
          //add new link
          requestAnimationFrame(() =>
            editor.setBlocksAtRange(range, {
              type: "latex",
              data: { latex, position: "align-left" }
            })
          );
        }
      } else {
        removeLatex();
      }

      close();
    },
    [editor, range, close, removeLatex, blocks, latex]
  );

  /*useEffect(
    () => {
      mathField.current = MQ.MathField(inputRef.current, {
        handlers: {
          edit: () => {
            setLatex(mathField.current.latex());
          }
        }
      });
      //if we update it everytime, most latex commands won't work as expected..
      if (mathField.current.latex().trim().length === 0) {
        mathField.current.latex(latex);
      }
    },
    [inputRef.current, latex]
  );*/

  const onInputChange = useCallback(event => {
    setLatex(event.target.value);
  }, []);

  const onBlur = useCallback(e => updateLatex(), [updateLatex]);
  const onKeyDown = useCallback(
    e => {
      if (e.key === "Enter") {
        //e.preventDefault(); //no new line

        updateLatex();
      }
    },
    [updateLatex]
  );

  //react autofocus scrolls to the element
  useEffect(
    () => {
      if (inputRef.current) {
        editor.blur();
        inputRef.current.focus({ preventScroll: true });
      }
    },
    [inputRef, editor]
  );

  /*useEffect(
    () => {
      let input;
      if (inputRef.current) {
        input = inputRef.current.querySelector("textarea");

        if (input) {
          input.focus();
          //input.addEventListener("blur", onBlur);
        }
      }

      //return () => {
      //  if (input) {
      //    input.removeEventListener("blur", onBlur);
      //  }
      //};
    },
      [inputRef.current]
  );*/

  return (
    <AntonPopoverBody>
      <div className={"d-flex justify-content-center"}>
      {/*<div ref={inputRef} />*/}
      <Input
        type="text"
        name="latex"
        id="latex"
        value={latex}
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        placeholder="Füge eine Latex-Formel hinzu"
        onBlur={onBlur}
        innerRef={inputRef}
        autoComplete="off"
        bsSize="sm"
      />
      <ButtonGroup className={"ml-1"}>
        <Button
          size="sm"
          color={"link"}
          onClick={updateLatex}
        >
          <FontAwesomeIcon icon={["far", "check"]} />
        </Button>
        <Button
          size="sm"
          color={"link"}
          onMouseDown={removeLatex}
        >
          <FontAwesomeIcon icon={["far", "trash-alt"]} />
        </Button>
      </ButtonGroup>

      </div>
    </AntonPopoverBody>
  );
});

LatexEditPopover.propTypes = {};

export default LatexEditPopover;
