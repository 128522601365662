import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { getIsAuthenticated } from "../reducers";

/**
 * Renders the component if the user is authenticated
 * @param {Object} props The component properties
 * @returns {Component} The component
 */
const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

/**
 * Maps some properties of the redux state to the component's properties
 * @param {Object} state The redux state
 * @param {Object} props The component properties
 * @returns {Object} The properties to inject into the component's properties
 */
const mapStateToProps = state => ({
  isAuthenticated: getIsAuthenticated(state)
});

export default connect(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(PrivateRoute);
