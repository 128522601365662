import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import Button from "components/Button";
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  Alert
} from "reactstrap";
import { getIsAuthenticating } from "reducers";
import yup from "yup";
import { Formik } from "formik";

import { requestReset } from "../actions/authentication";
import Wrapper from "./Wrapper";
import Spinner from "./Spinner";
import Flexbar from "./Flexbar";
import RegisterForm from "./forms/RegisterForm";
import PasswordResetRequestForm from "./forms/PasswordResetRequestForm";
import { login } from "../actions/authentication";

/**
 * The password reset request component
 * @returns {Component} The component
 */
class PasswordResetRequest extends React.PureComponent {
  /**
   * Creates a new instance
   * @param {Object} props The component properties
   * @returns {void}
   */
  constructor(props) {
    super(props);
    this.state = { success: false };
  }
  /**
   * Creates a validation schema
   * @returns {Object} The validation schema
   */
  validationSchema = () =>
    yup.object().shape({
      email: yup
        .string()
        .email()
        .required()
    });
  /**
   * Method called when the form is submitted
   * @param {Object} values The formik values
   * @param {Object} formikProps The formik properties
   * @returns {void}
   */
  handleSubmit = (
    values,
    {
      props,
      setSubmitting,
      setErrors /* setValues, setStatus, and other goodies */
    }
  ) => {
    const { dispatch } = this.props;

    dispatch(requestReset(values.email))
      .then(() => {
        this.setState({ success: true });
        setSubmitting(false);
      })
      .catch(error => {
        setSubmitting(false);
        // TODO: transform API errors to Formik's errors
        let errors = { password_link_sending_failed: error.message };
        setErrors(errors);
        this.setState({ error });
      });
  };
  /**
   * Renders a component
   * @returns {Component} The component
   */
  render = () => {
    const { isAuthenticating } = this.props;
    const { error, success } = this.state;
    let errorMessage;
    if (error) {
      switch (error.message) {
        case "blabla":
          errorMessage = "Blabla";
          break;
        default:
          errorMessage = "Es ist ein Fehler aufgetreten.";
          break;
      }
    }
    return (
      <Wrapper header footer>
        <Container>
          <Row>
            <Col md={{ size: 4, offset: 4 }}>
              <h3 className={"my-4"}>Passwort zurücksetzen</h3>
              <Card body>
                <Formik
                  initialValues={{
                    email: ""
                  }}
                  onSubmit={this.handleSubmit}
                  component={PasswordResetRequestForm}
                  validationSchema={this.validationSchema}
                />
                {success && (
                  <Alert className="my-2" color="success">
                    Ein Link zum Zurücksetzen des Passworts wurde an die E-Mail
                    Adresse gesendet.
                  </Alert>
                )}
              </Card>
              <hr />
              <p>Erinnerst Du dich an Dein Passwort?</p>
              <Button
                color="primary"
                block
                onClick={() => this.props.dispatch(push("/login"))}
              >
                Anmelden
              </Button>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    );
  };
}

/**
 * Maps some properties of the redux state to the component's properties
 * @param {Object} state The redux state
 * @returns {Object} The properties to inject into the component's properties
 */
const mapStateToProps = state => ({
  isAuthenticating: getIsAuthenticating(state)
});

export default connect(mapStateToProps)(PasswordResetRequest);
