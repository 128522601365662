/**
 * On return, if at the end of a node type that should not be extended,
 * create a new paragraph below it.
 * @param {Object} event The keyboard event
 * @param {Editor} editor The slate change object
 * @param {function} next the function that is called next
 * @returns {boolean|void} Whether the loop should be broken
 */
import {onBackspace} from "./remove-type";

/**
 * Method called when the enter key is pressed
 * @param {Object} event the event
 * @param {Editor} editor the slate editor
 * @param {function} next calls the next function in the middleware stack
 * @returns {void}
 */
const onEnter = (event, editor, next) => {
  if (event.key !== "Enter") return next();

  const { value } = editor;
  const { selection } = value;
  const { start, end, isExpanded } = selection;
  if (isExpanded) return next();

  const { startBlock } = value;
  
  if (start.offset === 0 && startBlock.text.length === 0) {
    return onBackspace(event, editor, next);
  }
  if (end.offset !== startBlock.text.length) return next();

  if (
    ![
      "heading-one",
      "heading-two",
      "heading-three",
      "quote",
      "image",
      "latex"
    ].includes(startBlock.type)
  ) {
    return next();
  }

  event.preventDefault();

  editor.splitBlock().setBlocks('paragraph')

  /*marks
    .filter(mark => ["bold", "italic", "underline"].includes(mark.type))
    .reduce((change, mark) => change.addMark(mark.type), change);
  editor.insertText("\0") /*.deleteBackward(1)*/;
};

/**
 * Creates an extend node plugin
 * @returns {Object} the plugin
 */
const ExtendNode = () => ({
  onKeyDown: onEnter
});

export default ExtendNode;
