import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {deserializeBlock,  renderMarkButton, renderNode} from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { toggleBlock } from "../helpers/handlers";
import { hasBlock } from "../helpers/content";
import { markdownBlockSequence } from "../helpers/markdown";

/**
 * Marks the selected text as h2
 */
class H2MarkButton extends AntonButton {
  name = "heading-two-mark";
  icon = <FontAwesomeIcon icon={["far","h2"]} />;
  onMouseDown = toggleBlock("heading-two");
  tooltip = {
    target: "heading-two-mark",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Text zu Titel der Grösse 2 formatieren"
  }
}

/**
 * Renders a title of second order
 */
class H2Mark extends React.PureComponent {
  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { attributes, children } = this.props;
    return <h4 {...attributes}>{children}</h4>;
  };
}

/**
 * The H2 Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const H2 = (options) => Object.assign(
  renderNode("heading-two", H2Mark),
  renderMarkButton("heading-two-mark",H2MarkButton),
  deserializeBlock("heading-two","h2"),
  markdownBlockSequence("heading-two", "##"),
  {
    isActive: hasBlock("heading-two"),
  }
);

export default H2;
