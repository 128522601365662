/**
 * The different rules a user can have
 */
import {
  getAuthenticatedUser,
  getLessonById,
  isCourseAuthor,
  isCourseCollaborator,
  isCoursePublic
} from "../reducers/index";

export const roles: Array<{ type: string; name: string }> = [
  {
    type: "author",
    name: "Autor"
  },
  {
    type: "collaborator",
    name: "Mitwirkender"
  },
  {
    type: "subscriber",
    name: "Abonnent"
  }
];

//TODO: import correct type
type RoleByIdStateType = { [index: string]: any };

/*
|--------------------------------------------------------------------------
| Lessons
|--------------------------------------------------------------------------
*/

/**
 * Returns the display name for a specified role type
 * @param {string} type The role type whose name should be found
 * @returns {string} The role type's name
 */
export const getRoleName = (type: string): string =>
  roles.find(role => role.type === type).name;

/**
 * Checks whether the current user can edit a given lesson
 * @param {RoleByIdStateType} state The redux state
 * @param {number|string} courseId The course's id the lesson belongs to
 * @param {number|string} lessonId The lesson's id that should be checked
 * @returns {boolean} Whether the user can edit the lesson
 */
export const canEditLesson = (
  state: RoleByIdStateType,
  courseId: number | string,
  lessonId: number | string
) => isCourseAuthor(state, courseId) || isCourseCollaborator(state, courseId);

/**
 * Checks whether the current user can delete a given lesson
 * @param {RoleByIdStateType} state The redux state
 * @param {number|string} courseId The course's id the lesson belongs to
 * @param {number|string} lessonId The lesson's id that should be checked
 * @returns {boolean} Whether the current user can delete a given lesson
 */
export const canDeleteLesson = (
  state: RoleByIdStateType,
  courseId: number | string,
  lessonId: number | string
) => canEditLesson(state, courseId, lessonId);

/**
 * Checks whether the current user can add a lesson to a given course
 * @param {RoleByIdStateType} state The redux state
 * @param {number|string} courseId The course's id
 * @returns {boolean} Whether the current user can add a lesson to a given course
 */
export const canAddLesson = (
  state: RoleByIdStateType,
  courseId: number | string
) => isCourseAuthor(state, courseId) || isCourseCollaborator(state, courseId);

/*
|--------------------------------------------------------------------------
| Courses
|--------------------------------------------------------------------------
*/

/**
 * Checks whether the current user can edit a given course
 * @param {Object} state The redux state
 * @param {number|string} courseId The course's id
 * @returns {boolean} Whether the current user can edit a given course
 */
export const canEditCourse = (
  state: RoleByIdStateType,
  courseId: number | string
) => isCourseAuthor(state, courseId);

export const canDuplicateCourse = (
  state: RoleByIdStateType,
  courseId: number | string
) => isCourseAuthor(state, courseId);

export const canForkCourse = (
  state: RoleByIdStateType,
  courseId: number | string
) => !isCourseAuthor(state, courseId) && isCoursePublic(state, courseId);
/*
|--------------------------------------------------------------------------
| User Management
|--------------------------------------------------------------------------
*/

/**
 * Checks whether the current user can remove a user from a course
 * @param {RoleByIdStateType} state The redux state
 * @param {number|string} courseId The course's id
 * @param {number|string} userId The user's id that should be removed
 * @returns {boolean} Whether the current user can remove a user from a course
 */
export const canRemoveUser = (
  state: RoleByIdStateType,
  courseId: number | string,
  userId: number | string
) =>
  isCourseAuthor(state, courseId) && getAuthenticatedUser(state).id !== userId;

/**
 * Checks whether the current user can change the role of another user on a course
 * @param {RoleByIdStateType} state The redux state
 * @param {number|string} courseId The course's id
 * @returns {boolean} Whether the current user can change the role of another user on a course
 */
export const canChangeUserRole = (
  state: RoleByIdStateType,
  courseId: number | string,
  userId: number | string
) =>
  isCourseAuthor(state, courseId) && getAuthenticatedUser(state).id !== userId;

/**
 * Checks whether the current user can manage users for a course
 * @param {RoleByIdStateType} state The redux state
 * @param {number|string} courseId The course's id
 * @returns {boolean} Whether the current user can manage users for a course
 */
export const canManageUsers = (
  state: RoleByIdStateType,
  courseId: number | string
) => isCourseAuthor(state, courseId);
