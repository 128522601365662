/**
 * Check if the current selection has a mark with `type` in it.
 * @param {string} type The mark type
 * @param {Editor} editor The current editor
 * @returns {boolean} Whether the given value containes active marks of the given type
 */
export const hasMark = type => editor =>
  editor.value && editor.value.activeMarks.some(mark => mark.type === type);

/**
 * Check if the any of the currently selected blocks are of `type`.
 * @param {string} type The block type
 * @param {Editor} editor The current editor
 * @returns {boolean} Whether the given value contains blocks of the given type
 */
export const hasBlock = type => editor =>
	editor.value && editor.value.blocks.some(node => node.type === type);

/**
 * A query helper to check if the current selection has any hyperlinks.
 *
 * @param {string} type The inline type
 * @param {Editor} editor The current editor
 * @returns {void}
 */
export const hasInline = type => editor =>
  editor.value && editor.value.inlines.some(inline => inline.type === type);
