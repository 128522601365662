import * as React from "react";
import styled from "styled-components";

import { colors } from "../utilities/style";

const StyledWrapper = styled<WrapperProps, "div">("div")`
  background-color: ${({ background }) =>
    background ? background : colors.bg_light};
  min-height: 100vh;
  margin-top: ${({ fixed }) => (fixed === "top" ? "56px" : "inherit")};
  overflow-x: hidden;
`;

interface WrapperProps {
  background: string;
  children: React.ReactNode;
  fixed?: string;
}

/**
 * The wrapper component
 * @returns {Component} component
 */
class Wrapper extends React.PureComponent<WrapperProps, any> {
  /**
   * Renders the component
   * @returns {Component} The component
   */
  render() {
    const { background, children, fixed = "" } = this.props;
    return (
      <StyledWrapper
        className="fill-vh-100"
        background={background}
        fixed={fixed}
      >
        {children}
      </StyledWrapper>
    );
  }
}

export default Wrapper;
