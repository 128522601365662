import React from "react";
import { NavLink } from "react-router-dom";
import { withBreadcrumbs } from "react-router-breadcrumbs-hoc";
import { Breadcrumb } from "reactstrap";
import styled from "styled-components";
import { connect } from "react-redux";
import { Nav, NavItem } from "reactstrap/lib/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getCourseBySlug, getLessonById } from "../reducers";

/**
 * Maps the redux state to the component properties
 * @param {function} state The redux state
 * @returns {Object} The properties to inject into the component
 */
const mapStateToPropsCourse = (
  state,
  {
    match: {
      params: { courseSlug }
    }
  }
) => ({
  course: getCourseBySlug(state, courseSlug) || {}
});

const CourseBreadcrumb = connect(mapStateToPropsCourse)(
  ({ course: { slug, name }, match }) => <span>{name}</span>
);

/**
 * Maps the redux state to the component properties
 * @param {function} state The redux state
 * @returns {Object} The properties to inject into the component
 */
const mapStateToPropsLesson = (
  state,
  {
    match: {
      params: { lessonId }
    }
  }
) => ({
  lesson: getLessonById(state, lessonId) || {}
});

const LessonEditBreadcrumb = connect(mapStateToPropsLesson)(
  ({
    lesson: { title },
    match: {
      params: { lessonId }
    }
  }) => <span>{lessonId && title} bearbeiten</span>
);

/**
 * Renders the breadcrumb for the root url
 * @returns {Component} The component
 */
const HomeBreadcrumb = () => <FontAwesomeIcon icon={["far", "home"]} />;

const routes = [
  { path: "/", breadcrumb: HomeBreadcrumb },
  { path: "/search", breadcrumb: "Lehrgang suchen" },
  { path: "/courses/:courseSlug", breadcrumb: CourseBreadcrumb },
  {
    path: "/courses/:courseSlug/analytics",
    breadcrumb: "Auswertung"
  },
  {
    path: "/courses/:courseSlug/lessons/new",
    breadcrumb: "Neue Lektion"
  },
  {
    path: "/courses/:courseSlug/lessons/:lessonId/edit",
    breadcrumb: LessonEditBreadcrumb
  },
  { path: "/profile", breadcrumb: "Profil verwalten" }
];

const StyledBreadcrumb = styled(Breadcrumb)`
  &.breadcrumb {
    margin-bottom: 0;
    padding-left: 0;
  }

  .breadcrumb-item {
    max-width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;

    white-space: nowrap;
  }
`;

const StyledNav = styled(Nav)`
  && {
    flex: 1;
    flex-direction: row;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

/**
 * Renders all breadcrumbs
 * @param {Object} props The breadcrumb properties
 * @returns {Component} The component
 */
const Breadcrumbs = ({ breadcrumbs }) => (
  <StyledNav navbar>
    {breadcrumbs.map(({ breadcrumb, path, match }, index, all) => (
      <NavItem key={path}>
        {index + 1 === all.length ? (
          <span>{breadcrumb}</span>
        ) : (
          <NavLink to={match.url}>{breadcrumb}</NavLink>
        )}
        {index + 1 < all.length ? (
          <FontAwesomeIcon
            className={"mx-3 text-secondary"}
            icon={["far", "angle-right"]}
          />
        ) : null}
      </NavItem>
    ))}
  </StyledNav>
);

export default withBreadcrumbs(routes)(Breadcrumbs);
