import React from "react";
import { hot } from "react-hot-loader/root";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { setConfig } from 'react-hot-loader'

//components
import Login from "./containers/Login";
import Registration from "./containers/Registration";
import PasswordResetRequest from "./components/PasswordResetRequest";
import PasswordReset from "./components/PasswordReset";
import PrivateRoute from "./components/PrivateRoute";
import NoMatch from "./components/NoMatch";
//containers
import CourseInvite from "./containers/CourseInvite";
import Profile from "./containers/Profile";
import Landing from "./containers/Landing";
import Impressum from "./containers/Impressum";
import Team from "./containers/Team";
import Pricing from "./containers/Pricing";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import NotificationSystem from "./components/NotificationSystem";
import LoggedOutRoute from "./components/LoggedOutRoute";
import { AnalyticsTracker } from "./components/GoogleAnalyticsTracker";
import Changelog from "./containers/Changelog";
import { universalWithLoadingBar } from "./utilities/universal";
import Analytics from "./containers/Analytics";
// icons
import InitializeIcons from "./utilities/icons";

InitializeIcons();

setConfig({
  ignoreSFC: true, // RHL will be __completely__ disabled for SFC
  pureRender: true, // RHL will not change render method
});

const Home = universalWithLoadingBar(props =>
  import(/* webpackChunkName: "home" */ "./containers/Home")
);

const Lesson = universalWithLoadingBar(props =>
  import(/* webpackChunkName: "lesson" */ "./containers/Lesson")
);

const EditLesson = universalWithLoadingBar(props =>
  import(/* webpackChunkName: "edit-lesson" */ "./containers/EditLesson")
);

const Courses = universalWithLoadingBar(props =>
  import(/* webpackChunkName: "courses" */ "./containers/Courses")
);

const Course = universalWithLoadingBar(props =>
  import(/* webpackChunkName: "course" */ "./containers/Course")
);

/**
 * The root component rendering the whole app
 */
class App extends React.PureComponent {
  /**
   * Lifecycle method for catching an error when rendering the tree below
   * @param {Error} error The error
   * @param {Object} errorInfo More information about the error
   * @returns {void}
   */
  componentDidCatch(error, errorInfo) {
    if (window.Rollbar) {
      window.Rollbar.error(error);
    }
  }

  /**
   * Renders the component
   * @returns {Component} The component
   */
  render = () => {
    const { history, store } = this.props;

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div>
            <NotificationSystem />
            <AnalyticsTracker />
            <Switch>
              <LoggedOutRoute
                exact
                path="/"
                redirectPath="/home"
                component={Landing}
              />
              <PrivateRoute exact path="/home" component={Home} />
              <Route exact path="/changelog" component={Changelog} />
              <Route exact path="/impressum" component={Impressum} />
              <Route exact path="/privacy" component={PrivacyPolicy} />
              <Route exact path="/team" component={Team} />
              <Route exact path="/pricing" component={Pricing} />
              <Route exact path="/search" component={Courses} />
              <Route exact path="/courses" component={Courses} />
              <Route exact path="/courses/page/:page" component={Courses} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Registration} />
              <Route
                exact
                path="/password-reset"
                component={PasswordResetRequest}
              />
              <Route
                exact
                path="/password-reset/:token"
                component={PasswordReset}
              />
              <PrivateRoute exact path="/profile" component={Profile} />
              <PrivateRoute
                exact
                path="/courses/:courseSlug/lessons/new"
                component={EditLesson}
              />
              <Route
                exact
                path="/courses/:courseSlug/play/:lessonId/:progress?"
                component={Lesson}
              />

              <PrivateRoute
                exact
                path="/courses/:courseSlug/lessons/:lessonId/edit"
                component={EditLesson}
              />
              <PrivateRoute
                exact
                path="/courses/:courseSlug/analytics"
                component={Analytics}
              />
              <Route
                exact
                path="/courses/:courseSlug/invites/:courseInvitationToken"
                component={CourseInvite}
              />
              <Route exact path="/courses/:courseSlug" component={Course} />
              <Route component={NoMatch} />
            </Switch>
          </div>
        </ConnectedRouter>
      </Provider>
    );
  };
}

export default hot(App);
