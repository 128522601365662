import * as React from 'react';
import styled from "styled-components";
import { colors } from "utilities/style";
import { PopoverBody } from "reactstrap";

export const AntonPopoverBody = styled(PopoverBody)`
  && {
    padding: 0.375rem;
    }
`;


