import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { renderMarkButton, renderNode } from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { hotkey, createHotkeyPlugin } from "../helpers/hotkey";
import { hasInline } from "../helpers/content";
import HyperlinkMark from "./HyperlinkMark";
import HyperlinkEditPopover from "./HyperlinkEditPopover";

/**
 * Toggles a hyperlink
 * @param {Editor} editor the editor instance
 * @returns {void}
 */
const toggleHyperlink = editor => {
  if (editor.hasHyperlinks()) {
    editor.unwrapLink();
  } else {
    //editor.wrapLink(); don't wrap it as a link yet
    //select link
    editor.moveStartToStartOfInline().moveEndToEndOfInline();
    //find the selection in the dom
    //const domRange = findDOMRange(editor.value.selection);
    //get the lowest element containing all selected elements so we can show the
    //popover there
    //const element = domRange.commonAncestorContainer;

    editor.registerPopoverAtCurrentPosition({
      popover: HyperlinkEditPopover
    });

    /*const range = editor.value.selection; //store selection

    //remove the focus and set the cursor after the first character
    editor.moveAnchorForward(1).moveFocusForward(1);
    const p = editor.value.selection.start;
    editor.select({ isFocused: true, anchor: p, focus: p });

    //then wrap the previously stored range
    editor.wrapLinkAtRange(range, "");*/
  }
};

/**
 * Marks the selected text bold
 */
class HyperlinkMarkButton extends AntonButton {
  name = "hyperlink-mark";
  icon = <FontAwesomeIcon icon={["far", "link"]} />;
  onMouseDown = toggleHyperlink;
  tooltip = {
    target: "hyperlink-mark",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Text verlinken"
  };
}

const hotkeys = [
  {
    hotkey: "mod+k",
    action: toggleHyperlink,
    description: "Verlinkt den ausgewählten Text",
    example: "Das ist <a href='#'>verlinkter</strong> Text."
  }
];

/**
 * A change helper to standardize wrapping links.
 *
 * @param {editor} editor the editor instance
 * @param {string} href the url that is put inside the href attribute
 * @returns {void}
 */
const wrapLink = (editor, href) => {
  editor.wrapInline({
    type: "hyperlink",
    data: { url: href }
  });
};

/**
 * A change helper to standardize wrapping links.
 *
 * @param {editor} editor the editor instance
 * @param {Object} range the document range
 * @param {string} href the url that is put inside the href attribute
 * @returns {void}
 */
const wrapLinkAtRange = (editor, range, href) => {
  editor.wrapInlineAtRange(range, {
    type: "hyperlink",
    data: { url: href }
  });
};

/**
 * A change helper to standardize unwrapping links.
 *
 * @param {Editor} editor the editor instance
 * @returns {void}
 */
const unwrapLink = editor => {
  editor.unwrapInline("hyperlink");
};

/**
 * A change helper to standardize wrapping links.
 *
 * @param {editor} editor the editor instance
 * @param {Object} range the document range
 * @returns {void}
 */
const unwrapLinkAtRange = (editor, range) => {
  editor.unwrapInlineAtRange(range, {
    type: "hyperlink"
  });
};

/**
 * Deserializes a link element
 * @param {Object} element The element to deserialize
 * @param {function} next A callback function to render the children
 * @returns {Object} The deserialized data
 */
const deserializeLink = (element, next) => {
  if (element.tagName.toLowerCase() === "a") {
    return {
      object: "inline",
      type: "hyperlink",
      nodes: next(element.childNodes),
      data: {
        href: element.getAttribute("href")
      }
    };
  }
};

/**
 * A query helper to get all the hyperlinks in the current selection
 *
 * @param {Editor} editor the editor instance
 * @returns {void}
 */
const getHyperlinks = editor => {
  const { value } = editor;
  return value.inlines.filter(inline => inline.type === "hyperlink");
};

/**
 * The Hyperlink Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const Hyperlink = options =>
  Object.assign(
    renderNode("hyperlink", HyperlinkMark),
    renderMarkButton("hyperlink", HyperlinkMarkButton),
    createHotkeyPlugin(hotkeys),
    {
      deserialize: deserializeLink,
      isActive: hasInline("hyperlink"),
      commands: {
        wrapLink,
        unwrapLink,
        wrapLinkAtRange,
        unwrapLinkAtRange
      },
      queries: {
        hasHyperlinks: hasInline("hyperlink"),
        getHyperlinks: getHyperlinks
      }
    }
  );

export default Hyperlink;
