import styled from "styled-components";
import React, { useRef, useMemo, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { colors } from "../../../utilities/style";
import LatexEditPopover from "./LatexEditPopover";
import { MQ } from "../../../utilities/mathquill";

const BlockMathContainer = styled.div`
  margin-bottom: 0.25rem;
  ${({ onClick }) => (onClick ? "cursor:pointer !important;" : "")};
  pointer-events: none;
  user-select: none;

  & > div {
    position: relative;
    ${({ position }) => {
      switch (position) {
        case "align-right":
          return "float:right;";
        case "align-middle":
          return "left:50%;transform: translateX(-50%);";
        case "align-left":
        default:
          return "";
      }
    }};
  }

  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const LatexWrapper = styled.div`
  background: ${colors.grey_1};
  color: ${colors.fontLight};
  border-radius: 5px;
  display: block;

  ${({ latex }) =>
    latex ? `background: transparent; color: ${colors.font};` : ""}

  ${({ serializer, isFocused, isSelected }) => {
    if (!serializer && (isFocused || isSelected)) {
      return `background: ${colors.green_1}; cursor: pointer;`;
    }
  }}
  &&:hover {
    ${({ serializer }) =>
      serializer ? "" : `background: ${colors.green_1}; cursor: pointer;`}
  }
`;

const LatexBlock = React.memo(
  ({
    children,
    node,
    attributes,
    serializer = false,
    isSelected,
    isFocused,
    editor
  }) => {
    const ref = useRef(null);

    const latex = useMemo(() => node.data.get("latex"), [node]);
    const position = node.data.get("position") || "align-left";

    const latexHtml = useMemo(
      () => {
        const parent = document.createElement("div");
        const latexContainer = document.createElement("div");

        latexContainer.appendChild(document.createTextNode(latex));
        MQ.StaticMath(latexContainer);
        parent.appendChild(latexContainer);

        return parent.innerHTML;
      },
      [latex]
    );

    const openPopover = useCallback(
      () => {
        editor.moveStartToStartOfBlock().moveEndToEndOfBlock();

        editor.registerPopover({
          elementReference: ref,
          placement: "bottom",
          popover: LatexEditPopover
        });
      },
      [editor, ref.current]
    );

    if (serializer) {
      if (latex) {
        return (
          <BlockMathContainer
            position={position}
            dangerouslySetInnerHTML={{ __html: latexHtml }}
            {...attributes}
          />
        );
      }

      return <div />;
    }

    return (
      <LatexWrapper
        innerRef={ref}
        className={"my-2 p-2"}
        serializer={serializer}
        latex={latex}
        isFocused={isFocused}
        isSelected={isSelected}
        onClick={openPopover}
      >
        <BlockMathContainer
          position={position}
          dangerouslySetInnerHTML={{ __html: latexHtml }}
          {...attributes}
        />
      </LatexWrapper>
    );
  }
);

export default LatexBlock;
