import React from "react";
import { Link } from "react-router-dom";
import { FormGroup, FormFeedback, Label, Input, Alert } from "reactstrap";
import { Form } from "formik";

import Flexbar from "../Flexbar";
import Spinner from "../Spinner";
import InputField from "../input/InputField";
import Btn from "../Button";

/**
 * Renders the password reset form
 * @param {Object} formikProps The formik props
 * @returns {Component} The component
 */
const PasswordResetForm = ({
	values,
	errors,
	touched,
	handleChange,
	handleBlur,
	handleSubmit,
	isSubmitting
}) => 
	<Form>
		<FormGroup>
			<Label for="email">E-Mail Adresse</Label>
			<InputField type="text" name="email" placeholder="wolfang@goethe.ch" />
		</FormGroup>
		<FormGroup>
			<Label for="password">Neues Passwort</Label>
			<InputField type="password" name="password" />
		</FormGroup>
		<FormGroup>
			<Label for="password_confirmation">Passwort wiederholen</Label>
			<InputField type="password" name="password_confirmation" />
		</FormGroup>
		<Btn id="submitPasswordResetForm" color="primary" type="submit" block loading={isSubmitting}>
			Passwort zurücksetzen
		</Btn>
		{errors.password_link_sending_failed && 
			<Alert className="my-2" color="danger">
				Das Passwort konnte nicht zurückgesetzt werden.
			</Alert>
		}
	</Form>
;

export default PasswordResetForm;
