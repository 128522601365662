/**
 * The anton reference plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const AntonReference = options => ({
  queries: {
    getAnton: editor => editor.props.editor
  }
});

export default AntonReference;
