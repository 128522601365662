import ReactDOM from "react-dom";
import React, { useMemo, useEffect } from "react";
import { Popover as ReactstrapPopover } from "reactstrap";
import styled from "styled-components";

import { getCurrentSelectionRect, getScrollY } from "../../../utilities/selection";

const PopoversWrapper = styled.div``;
const AbsolutePopover = styled.div`
  margin-top: 0.5rem;
  transform: translateX(-50%);
  .arrow {
    left: 50%;
    transform: translateX(-50%);
  }
`;

/**
 * A command to register popovers
 * @param {editor} editor the editor instance
 * @param {Object} popover the popover object
 * @returns {void}
 */
const registerPopover = (editor, popover) => {
  const anton = editor.getAnton();
  anton.addPopover({
    ...popover,
    marks: editor.value.marks,
    blocks: editor.value.blocks,
    inlines: editor.value.inlines,
    range: editor.value.selection
  });
};

/**
 * A method to register popovers at the current position
 * @param {editor} editor the editor instance
 * @param {Object} popover the popover object
 * @returns {void}
 */
const registerPopoverAtCurrentPosition = (editor, popover) => {
  const anton = editor.getAnton();
  const selectionRect = getCurrentSelectionRect();
  const scrollY = getScrollY();
  anton.addPopover({
    type: "absolute",
    top: selectionRect.bottom + scrollY,
    left: selectionRect.left + selectionRect.width / 2,
    ...popover,
    marks: editor.value.marks,
    blocks: editor.value.blocks,
    inlines: editor.value.inlines,
    range: editor.value.selection
  });
};

const root = document.getElementById("root");

/**
 * Renders the active popovers
 * @param {Object} props The component props
 * @returns {Component} The rendrered component
 */
const Popovers = ({ editor, injectCurrentEditorInstance, editorWrapper }) => {
  if (!editorWrapper) {
    return null;
  }
  const anton = editor.getAnton();
  const popovers = anton.popovers;
  const editorRect = editorWrapper.getBoundingClientRect();

  return ReactDOM.createPortal(
    <PopoversWrapper>
      {popovers.map((popover, index) => {
        const {
          type = "popper",
          top,
          left,
          placement,
          elementReference,
          marks,
          blocks,
          inlines,
          range,
          popover: Popover,
          onClose
        } = popover;

        switch (type) {
          case "absolute":
            return (
              <AbsolutePopover
                key={index}
                style={{ position: "absolute", top, left }}
                className={"popover bs-popover-bottom"}
              >
                <Popover
                  editor={editor}
                  injectCurrentEditorInstance={injectCurrentEditorInstance}
                  marks={marks}
                  blocks={blocks}
                  inlines={inlines}
                  range={range}
                  close={() => {
                    onClose && onClose();
                    anton.removePopover(popover);
                  }}
                />
                <span className={"arrow"} />
              </AbsolutePopover>
            );
          case "popper":
          default:
            //if the dom element was removed, remove popover as well
            if (
              !elementReference.current ||
              !document.body.contains(elementReference.current)
            ) {
              onClose && onClose();
              anton.removePopover(popover);
            }

            return (
              <ReactstrapPopover
                key={index}
                target={elementReference}
                placement={placement}
                isOpen
              >
                <Popover
                  editor={editor}
                  injectCurrentEditorInstance={injectCurrentEditorInstance}
                  marks={marks}
                  blocks={blocks}
                  inlines={inlines}
                  range={range}
                  close={() => {
                    onClose && onClose();
                    anton.removePopover(popover);
                  }}
                />
              </ReactstrapPopover>
            );
        }
      })}
    </PopoversWrapper>,
    root
  );
};

/**
 * The popover plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const Popover = options => ({
  commands: {
    registerPopover,
    registerPopoverAtCurrentPosition
  },
  belowEditor: Popovers
});

export default Popover;
