import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {deserializeBlock, renderMarkButton, renderNode} from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { toggleBlock } from "../helpers/handlers";
import { hasBlock } from "../helpers/content";
import { markdownBlockSequence } from "../helpers/markdown";

/**
 * Marks the selected text as h1
 */
class H1MarkButton extends AntonButton {
  name = "heading-one-mark";
  icon = <FontAwesomeIcon icon={["far","h1"]} />;
  onMouseDown = toggleBlock("heading-one");
  tooltip = {
    target: "heading-one-mark",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Überschrift 1"
  }
}

/**
 * Renders a title of first order
 */
class H1Mark extends React.PureComponent {
  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { children, attributes } = this.props;

    return <h3 {...attributes}>{children}</h3>;
  };
}

/**
 * The H1 Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const H1 = (options) => Object.assign(
  renderNode("heading-one", H1Mark),
  renderMarkButton("heading-one-mark",H1MarkButton),
  deserializeBlock("heading-one","h1"),
  markdownBlockSequence("heading-one", "#"),
  {
    isActive: hasBlock("heading-one"),
  }
);

export default H1;
