import * as React from "react";
import styled, { keyframes } from "styled-components";

const cubemove = keyframes`
	0%, 39%, 100% { opacity: 0; }
  	40% { opacity: 1; }
`;

const StyledSpinner = styled.div`
  width: ${({ size = 1 }) => size}rem;
  height: ${({ size = 1 }) => size}rem;
  position: relative;
  display: inline-block;

  margin-right: ${({ margin = 0 }) => margin}rem;

  & > div {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  & > div:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: ${({ size = 1 }) => size / 6.66666}rem;
    height: ${({ size = 1 }) => size / 4.5}rem;
    background-color: ${({ color = "#fff" }) => color};
    border-radius: 100%;
    -webkit-animation: ${cubemove} 0.9s infinite ease-in-out both;
    animation: ${cubemove} 0.9s infinite ease-in-out both;
  }
  & > div:nth-child(2) {
    transform: rotate(40deg);
  }
  & > div:nth-child(3) {
    transform: rotate(80deg);
  }
  & > div:nth-child(4) {
    transform: rotate(120deg);
  }
  & > div:nth-child(5) {
    transform: rotate(160deg);
  }
  & > div:nth-child(6) {
    transform: rotate(200deg);
  }
  & > div:nth-child(7) {
    transform: rotate(240deg);
  }
  & > div:nth-child(8) {
    transform: rotate(280deg);
  }
  & > div:nth-child(9) {
    transform: rotate(320deg);
  }
  & > div:nth-child(2):before {
    animation-delay: -0.8s;
  }
  & > div:nth-child(3):before {
    animation-delay: -0.7s;
  }
  & > div:nth-child(4):before {
    animation-delay: -0.6s;
  }
  & > div:nth-child(5):before {
    animation-delay: -0.5s;
  }
  & > div:nth-child(6):before {
    animation-delay: -0.4s;
  }
  & > div:nth-child(7):before {
    animation-delay: -0.3s;
  }
  & > div:nth-child(8):before {
    animation-delay: -0.2s;
  }
  & > div:nth-child(9):before {
    animation-delay: -0.1s;
  }
`;

interface Props {
  size: number;
  margin: number;
  color: string;
}

interface State {}

/**
 * A spinner component
 * @returns {Component} The component
 */
class Spinner extends React.PureComponent<Props, State> {
  /**
   * Renders the component
   * @returns {Component} The new component
   */
  render() {
    return (
      <StyledSpinner {...this.props}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </StyledSpinner>
    );
  }
}

export default Spinner;
