import {Container, Nav, Navbar, NavbarBrand, NavItem, NavLink, UncontrolledTooltip} from "reactstrap";
import React from "react";

import logoSvg from "../../assets/images/wit-logo-grey.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * The navbar of the landing page
 * @returns {Component} the component
 */
class FooterLanding extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }
  toggle = () => this.setState({ isOpen: !this.state.isOpen });
  render() {
    const yays = ['Yayy! 🎉','Wuhee! 🚀','Hallo Freund 👋','Wow! 🌈', 'Kekse gehen immer. 🍪', 'Yo soy un pingüino. 🐧'];
    return(
      <Container>
        <Navbar light expand="md">
          <NavbarBrand href="/">
            <img id={"footerLogo"} src={logoSvg} alt={"wit Logo"}/>
            <UncontrolledTooltip placement="right" target="footerLogo" delay={{ show: 0, hide: 0 }}>
              { yays[Math.floor(Math.random()*yays.length)]}
            </UncontrolledTooltip>
          </NavbarBrand>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="/changelog">Changelog</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/impressum">Impressum</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/privacy">Datenschutz</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="mailto:hoi@witapp.io">Kontakt</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" className={"drift-open-chat"}><FontAwesomeIcon icon={["far", "comments"]}/> Chat</NavLink>
            </NavItem>
          </Nav>
        </Navbar>
      </Container>
    )
  }
}

export default FooterLanding;
