import { combineReducers } from "redux";

import {
	createReducer,
	createAllIds,
	createById,
	wrap
} from "../../utilities/reducer";

const itemName = "courseInvitation";

export default combineReducers({
	byId: createById(itemName),
	allIds: createAllIds(itemName)
});

/**
 * Retrieves course invitations
 * @param {Object} state This part of the redux state
 * @param {number} courseId The id of the course whose invitations should be returned
 * @returns {Array} An array of invitations
 */
export const getCourseInvitations = (state, courseId) =>
	state.allIds
		.map(id => state.byId[id])
		.filter(item => item.courseId === courseId);

export {
	getAllItems as getAllCourseInvitations,
	getItemById as getCourseInvitationById
} from "utilities/reducer";
