import React from "react";
import { Button } from "reactstrap";
import styled from "styled-components";
import {UncontrolledTooltip} from "reactstrap";

import { colors } from "../../../utilities/style";

const StyledButton = styled(Button)`
  position: relative;
  color: ${({ active }) => (active ? colors.primary : "#fff")} !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${colors.primary} !important;
  }
`;

/**
 * Renders a button
 */
class AntonButton extends React.Component {
  name = "Default";
  icon = "*";
  onMouseDown = () => {};
  tooltip = false;

  /**
   * Wraps the event with the necessary elements from the editor
   * @param {Event} e the original event
   * @returns {void}
   */
  wrapMouseDown = (e) => this.props.mouseDownWrapper(e, this.onMouseDown);

  /**
   * Called when the component is updated
   * @param {Object} nextProps The new props
   * @param {Object} nextState The new state
   * @returns {boolean} Whether the component should be updated
   */
  shouldComponentUpdate = (nextProps, nextState) => {
    return nextProps.active !== this.props.active;
  };

  render = () => {
    const { active = false, mouseDownWrapper} = this.props;

    return (
      <React.Fragment>
          <div id={this.name}>
            <StyledButton
              color="link"
              onMouseDown={this.wrapMouseDown}
              active={active}
              type="button"
            >
              {this.icon}
            </StyledButton>
          </div>
          {this.tooltip && <UncontrolledTooltip key={`tooltip-${this.name}`} {...this.tooltip} boundariesElement={'window'}/>}
      </React.Fragment>
    );
  };
}

export default AntonButton;
