import { User, UserDTO, UserFactory } from "./User";

export enum ExerciseType {
  MultipleChoice = "multiple_choice",
  Flashcard = "flashcard",
  StaticContent = "static_content",
  FreeText = "free_text"
}

/**
 * Factory for creating an exercise
 */
export class ExerciseFactory {
  /**
   * Creates a DTO from the exercise
   * @param exercise
   * @returns the DTO
   */
  static toDTO(exercise: Exercise): ExerciseDTO {
    let {
      id,
      author,
      order,
      lessonId: lesson_id,
      updatedAt: updated_at,
      createdAt: created_at,
      uid,
      type
    } = exercise;
    return {
      id: typeof id === "number" ? id : undefined,
      uid,
      author: author ? UserFactory.toDTO(author) : undefined,
      order,
      lesson_id,
      updated_at,
      created_at
    };
  }

  /**
   * Creates an exercise from a DTO
   * @param dto
   * @returns exercise
   */
  static fromDTO(dto: ExerciseDTO): Exercise {
    let {
      id,
      created_at: createdAt,
      updated_at: updatedAt,
      lesson_id: lessonId,
      author,
      type,
      ...rest
    } = dto;
    return ExerciseFactory.create({
      ...rest,
      type: <ExerciseType>type,
      id,
      author: UserFactory.fromDTO(author),
      lessonId,
      createdAt,
      updatedAt
    });
  }

  /**
   * Creates a new exercise
   * @param params
   * @returns Exercise
   */
  static create(params: IExercise = {} as IExercise): Exercise {
    let {
      id = "new",
      type,
      author = null,
      lessonId = null,
      order = 0,
      excerpt,
      createdAt = "",
      updatedAt = "",
      uid = `${type}-${generateNonce()}`
    } = params;
    return {
      id,
      author,
      lessonId,
      order,
      type,
      uid,
      excerpt,
      createdAt,
      updatedAt
    };
  }

  /**
   * Duplicates an exercise
   * @param exercise
   * @returns Exercise
   */
  static duplicate(exercise: Exercise): Exercise {
    const { type, author, lessonId, excerpt } = exercise;
    return ExerciseFactory.create({
      type,
      author,
      lessonId,
      excerpt
    });
  }
}

/**
 * Interface that describes a "minimal" exercise, requiring only necessary properties
 */
export interface IExercise {
  id?: number | string;
  type?: ExerciseType;
  uid?: string;
  author?: User;
  order?: number;
  lessonId: number;
  excerpt?: string;
  createdAt?: string;
  updatedAt?: string;
}

/**
 * An exercise
 */
export abstract class Exercise implements IExercise {
  id: number | string;
  uid: string;
  author: User;
  order: number;
  lessonId: number;
  type: ExerciseType;
  excerpt: string;
  createdAt: string;
  updatedAt: string;
}

export interface ExerciseDTO {
  id?: number;
  uid: string;
  author: UserDTO;
  order: number;
  lesson_id: number;
  type?: string;
  created_at?: string;
  updated_at?: string;
}

/**
 * Creates a unique nonce string
 * @returns {string} the nonce
 */
export const generateNonce = (): string =>
  Math.random()
    .toString(36)
    .substring(2, 15) +
  Math.random()
    .toString(36)
    .substring(2, 15);
