import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";

import { shadows, borders, colors } from "../../utilities/style";

const PopoverPreviewWrapper = styled.div`
  && {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0.5rem;
    background-color: #fff;
    
    font-size: 0.8rem;
    width: ${({ wrapper }) => (wrapper ? "250px" : "auto")};
    padding: ${({ wrapper }) => (wrapper ? "0.075rem 0.125rem" : "0")};
  
    color: #000;
  
    z-index: 10;
    user-select: none;
  }
  .arrow {
    left: 50%;
    transform: translateX(-50%);
  }
`;

/**
 * Renders a popover preview
 * @param {Object} props The component props
 * @returns {Component} The rendered component
 */
const PopoverPreview = ({
  open,
  elementReference,
  placement,
  editor,
  popover,
  shouldAutoOpen,
  wrapper,
  children
}) => {
  const [actualPopoverOpen, setActualPopoverOpen] = useState(false);
  const openPopover = useCallback(
    () => {
      //editor.moveStartToStartOfInline().moveEndToEndOfInline();

      editor.registerPopover({
        elementReference,
        placement,
        onClose: () => setActualPopoverOpen(false),
        popover
      });
      setActualPopoverOpen(true);
    },
    [setActualPopoverOpen, editor, elementReference, placement, popover]
  );

  useEffect(() => {
    if (!actualPopoverOpen && shouldAutoOpen && shouldAutoOpen(editor)) {
      openPopover();
    }
  });

  if (actualPopoverOpen || !open) {
    return null;
  }

  return (
    <PopoverPreviewWrapper className={"popover bs-popover-bottom"} wrapper={wrapper}>
      <React.Fragment>
        {children(openPopover)}
        <span className={"arrow"} />
      </React.Fragment>
    </PopoverPreviewWrapper>
  );
};

export default PopoverPreview;
