import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/**
 * Embeds a video based on it's url
 */
class VideoEmbed extends React.PureComponent {
  render() {
    const { url } = this.props;
    return (
      <div className="embed-responsive embed-responsive-16by9">
        <iframe className="embed-responsive-item" src={url} allowFullScreen />
      </div>
    );
  }
}

VideoEmbed.propTypes = {
  url: PropTypes.string.isRequired
};

export default VideoEmbed;
