import {
  createFetchSingleItemActionFactory,
  createFetchSingleItemThunkCreator,
  createFetchItemsActionFactory,
  createFetchItemPageActionFactory,
  createFetchAllItemsThunkFactory,
  createUpdateItemActionCreator,
  createUpdateItemThunkCreator,
  createCreateItemActionCreator,
  createCreateItemThunkCreator,
  createDeleteItemActionCreator,
  createDeleteItemThunkCreator
} from "../utilities/action";
import { fetchApi } from "../utilities/api";
import { jsonToHtml } from "../components/anton/helpers/serializer";
import {
  fetchItems as fetchExercisesAction,
  mapExercise,
  prepareExerciseOrder
} from "./exercise";
import { createFetchActionFactory } from "../utilities/action";
import { getExercises } from "../reducers";

export const itemName = "lesson";

/**
 * Maps an item so we can store it in the state
 * @param {Object} item The item to map
 * @returns {Object} The mapped item
 */
export const mapItem = ({
  id,
  title,
  description,
  order,
  course_id: courseId,
  parent_id: parentId,
  creator_id: creatorId,
  exercises_count: exercisesCount,
  shuffle_exercises: shuffleExercises,
  user_progress_detailed: userProgressDetailed,
  updated_at: updatedAt,
  created_at: createdAt
}) => ({
  id,
  title,
  description,
  order,
  descriptionHtml: jsonToHtml(description),
  courseId,
  parentId,
  creatorId,
  exercisesCount,
  shuffleExercises,
  userProgressDetailed,
  updatedAt: updatedAt,
  savedAt: updatedAt,
  createdAt: createdAt
});

/**
 * Action called before and after fetching an item
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} itemId The requested item id
 * @param {object} item The received item
 * @param {string} courseSlug The slug of the requested lesson's course
 * @returns {object} The redux action
 */
export const fetchItem = createFetchSingleItemActionFactory(
  itemName,
  "courseSlug"
);

/**
 * Fetches a single item
 * @param {number} lessonId The id of the requested id
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseSlug The slug of the requested lesson's course
 * @returns {function}
 */
export const fetch = createFetchSingleItemThunkCreator(
  fetchItem,
  (courseSlug, lessonId) => `/api/courses/${courseSlug}/lessons/${lessonId}`,
  mapItem
);

/**
 * Fetches a single item
 * @param {string} courseSlug The slug of the requested course
 * @param {number} lessonId the id of the lesson that should be fetched
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} A redux thunk
 */
export const fetchLesson = (courseSlug, lessonId, visualize = false) => (
  dispatch,
  getState
) => {
  dispatch(fetchItem(true, null, visualize, lessonId));

  return fetchApi(`/api/courses/${courseSlug}/lessons/${lessonId}`, {
    method: "GET"
  })
    .then(({ data: item }) => {
      const mappedItem = mapItem(item);

      if (item.exercises) {
        const mappedExercises = item.exercises.map(mapExercise);
        mappedItem.exerciseUids = mappedExercises.map(ex => ex.uid);
        dispatch(fetchExercisesAction(false, null, visualize, mappedExercises));
      }

      dispatch(fetchItem(false, null, visualize, lessonId, mappedItem));

      return Promise.resolve({ item: mappedItem, originalItem: item });
    })
    .catch(error => {
      dispatch(fetchItem(false, error, visualize, lessonId));

      return Promise.reject(error);
    });
};

/**
 * Action called before and after fetching multiple items
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} items The received items
 * @returns {object} The redux action
 */
export const fetchItems = createFetchItemsActionFactory(itemName);

/**
 * Action called before and after fetching an item page
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} items The received items
 * @returns {object} The redux action
 */
export const fetchItemPage = createFetchItemPageActionFactory(itemName);

/**
 * Fetches all items
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} The redux thunk
 */
export const fetchAll = createFetchAllItemsThunkFactory(
  fetchItemPage,
  page => `/api/lessons/?page=${page}`,
  mapItem
);

/**
 * Action called before and after updating an item
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} item The item that should be / was updated
 * @param {object} courseId The course's id whose lesson should be updated
 * @returns {object} The redux action
 */
export const updateLessonAction = createUpdateItemActionCreator(
  itemName,
  "courseId"
);

/**
 * Updates a lesson
 * @param {number} lessonId The lesson's id that should be updated
 * @param {object} lesson The new lesson data
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The course's id whose lesson should be updated
 * @returns {function} The redux thunk
 */

export const updateLesson = createUpdateItemThunkCreator(
  updateLessonAction,
  (lessonId, lesson, courseId) =>
    `/api/courses/${courseId}/lessons/${lessonId}`,
  mapItem
);

/**
 * Action dispatched before and after creating a new item
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} item The item that should be / was created
 * @param {number} courseId The course's id to which the new lesson should be added to
 */
export const createLessonAction = createCreateItemActionCreator(
  itemName,
  "courseId"
);

/**
 * Creates a lesson
 * @param {object} lesson The lesson data
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The course's id to which the new lesson should be added to
 * @returns {function} The redux thunk
 */
export const createLesson = createCreateItemThunkCreator(
  createLessonAction,
  (item, courseId) => `/api/courses/${courseId}/lessons`,
  mapItem
);

/**
 * Action dispatched before and after deleting an item
 * @param {object} itemId The id of the item that should be / was deleted
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The course's id the lesson is part of
 * @returns {object} The redux action
 */
export const deleteLessonAction = createDeleteItemActionCreator(
  itemName,
  "courseId"
);

/**
 * Deletes a lesson
 * @param {number} lessonId The lesson's id
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The course's id the lesson's part of
 * @returns {function} The redux thunk
 */
export const deleteLesson = createDeleteItemThunkCreator(
  deleteLessonAction,
  (lessonId, courseId) => `/api/courses/${courseId}/lessons/${lessonId}`,
  mapItem
);

/**
 * Associates the received exercises with a lesson
 * @param {number} lessonId The lesson id which the exercises should be associated with
 * @param {Array} exerciseUids The array of exercise ids that should be associated with the lesson
 * @returns {function} The redux action
 */
export const associateExercises = (lessonId, exerciseUids) => ({
  type: "LESSON_ASSOCIATE_EXERCISES",
  lessonId,
  exerciseUids
});

/**
 * Order an exercise from one index to another inside a lesson
 * @param {string} courseSlug The slug of the course whose lesson holds the exercises
 * @param {number} lessonId the id of the lesson in which the exercise is reordered
 * @param {number} sourceIndex The index of the exercise to move
 * @param {number} targetIndex The index where the selected exercise should be moved to
 * @returns {Object} The redux action
 */
export const orderExercise = (
  courseSlug,
  lessonId,
  sourceIndex,
  targetIndex
) => ({
  type: "LESSON_ORDER_EXERCISE",
  courseSlug,
  lessonId,
  sourceIndex,
  targetIndex
});

/** Action dispatched before and after the new order is sent to the server
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {array} lessonOrder Array of lessonIds in the new order
 * @returns {object} The redux action
 */
const updateExerciseOrderAction = createFetchActionFactory(
  "LESSON_UPDATE_EXERCISE_ORDER",
  "courseId",
  "lessonId",
  "exerciseOrder"
);

/**
 * Updates the exercise order of a lesson
 * @param {string} courseId The course's slug the given lesson belongs to
 * @param {number} lessonId The lesson's id whose exercise order should be updated
 * @param {Array} exerciseOrder Array of exercise ids and exercise types
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} The redux thunk
 */
export const updateExerciseOrder = (
  courseId,
  lessonId,
  exerciseOrder,
  visualize = false
) => (dispatch, getState) => {
  dispatch(
    updateExerciseOrderAction(
      true,
      null,
      visualize,
      courseId,
      lessonId,
      exerciseOrder
    )
  );

  return fetchApi(`/api/courses/${courseId}/lessons/${lessonId}/order`, {
    method: "PUT",
    body: JSON.stringify({ order: prepareExerciseOrder(exerciseOrder) })
  })
    .then(({ order }) => {
      const exercises = getExercises(getState());
      const exerciseUids = order.map(
        ({ exercise_id, type }) =>
          exercises.find(
            exercise => exercise.id == exercise_id && exercise.type == type
          ).uid
      );

      dispatch(
        updateExerciseOrderAction(
          false,
          null,
          visualize,
          courseId,
          lessonId,
          exerciseUids
        )
      );

      return Promise.resolve(order);
    })
    .catch(err => {
      dispatch(
        updateExerciseOrderAction(
          false,
          err,
          visualize,
          courseId,
          lessonId,
          exerciseOrder
        )
      );

      return Promise.reject(err);
    });
};
