import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { renderBlockButton, renderNode } from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { hasBlock } from "../helpers/content";
import VideoBlock from "./VideoBlock";
import VideoEditPopover from "./VideoEditPopover";

/**
 * Inserts an image
 */
class VideoBlockButton extends AntonButton {
  name = "video-block";
  icon = <FontAwesomeIcon icon={["far", "film"]} />;
  tooltip = {
    target: "video-block",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Video einfügen"
  };
  /**
   * Called when clicked
   * @param {Editor} editor the slate editor
   * @returns {void}
   */
  onMouseDown = editor => editor.insertVideo("");
}

/**
 * Helper function to insert a video
 * @param {Editor} editor the slate editor
 * @param {string} url the video url
 * @param {string} type the type (vimeo or youtube)
 * @returns {void}
 */
const insertVideo = (editor, url, type = "youtube") => {
  editor.moveStartToStartOfBlock().moveEndToEndOfBlock();
  //.focus();

  const selection = window.getSelection();
  const range = selection.getRangeAt(0);

  editor.registerPopover({
    type: "popper",
    elementReference: { current: range.commonAncestorContainer.parentElement },
    placement: "bottom",
    popover: VideoEditPopover
  });
};

/**
 * The video Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const Video = options =>
  Object.assign(
    renderNode("video", VideoBlock),
    renderBlockButton("video", VideoBlockButton),
    {
      isActive: hasBlock("video"),
      commands: {
        insertVideo: insertVideo
      }
    }
  );

export default Video;
