import { combineReducers } from "redux";

import {
  createReducer,
  createAllIds,
  createById,
  wrap,
  cleanObject,
  removeKey
} from "../../utilities/reducer";
import play, * as fromPlay from "./play";
import { arrayToDictionary } from "../../models/Types";

const itemName = "exercise";
const uniqueProperty = "uid";

export default combineReducers({
  byId: createById(itemName, uniqueProperty, (state, action) => {
    switch (action.type) {
      case "DELETE_EXERCISE":
        return !action.isFetching && action.previousUid
          ? removeKey(state, action.previousUid)
          : state;
      case "UPDATE_EXERCISE":

        const uid =
          action.previousUid ||
          (action && action.item && action.item[uniqueProperty]);

        if(!action.isFetching && uid) {

          /*if (action.item.answers && state[uid] && state[uid].answers) {
            const oldAnswersIdMapping = [];

            Object.keys(state[uid].answers).map((key) => {
              const answer = state[uid].answers[key];
              oldAnswersIdMapping[answer.id] = key;
            });

            // TODO: was passiert hier, wenn vorher 2 antworten neu waren?
            //  die uid's sind dann beide unter 'new' gespeichert
            //  evtl. nicht mehr als 1 neue antwort zulassen?
            Object.keys(action.item.answers).map((key) => {
              const answer = action.item.answers[key];
              if (oldAnswersIdMapping[answer.id]) {
                const oldUid = oldAnswersIdMapping[answer.id];
                const newUid = answer.uid;
                answer.uid = oldAnswersIdMapping[answer.id];
                Object.defineProperty(action.item.answers, oldUid,
                  Object.getOwnPropertyDescriptor(action.item.answers, newUid));
                delete action.item.answers[newUid];
              }
            });

          }*/



          return {
            ...state,
            [uid]: {
              ...state[uid],
              ...cleanObject(action.item),
              [uniqueProperty]: uid, //override new uid
              _isFetching: action.isFetching,
              _error: action.error
            }
          }
        }
        return state;
      default:
        return state;
    }
  }),
  allIds: createAllIds(itemName, uniqueProperty, (state, action) => {
    switch (action.type) {
      case "DELETE_EXERCISE":
        return !action.isFetching && action.previousUid
          ? state.filter(id => id !== action.previousUid)
          : state;
      default:
        return state;
    }
  }),
  play
});

export {
  getAllItems as getExercises,
  getItemById as getExerciseById
} from "../../utilities/reducer";

export const getPlayCurrentExercisePosition = wrap(
  fromPlay.getPlayCurrentExercisePosition,
  state => state.play
);
export const getPlayHearts = wrap(fromPlay.getPlayHearts, state => state.play);
export const getPlayValidatedExercises = wrap(
  fromPlay.getPlayValidatedExercises,
  state => state.play
);
export const getPlayLoadedExercises = wrap(
  fromPlay.getPlayLoadedExercises,
  state => state.play
);
export const getPlayValidationByExerciseUid = wrap(
  fromPlay.getPlayValidationByExerciseUid,
  state => state.play
);

/**
 * Gets the maximum exercise order for a lesson
 * @param {Object} state This party of the state
 * @param {number} lessonId The lesson's id
 * @returns {number} The max exercise order
 */
export const getMaxExerciseOrder = (state, lessonId) =>
  state.allIds
    .map(id => state.byId[id])
    .filter(exercise => exercise.lessonId == lessonId)
    .reduce(
      (maxOrder, exercise) =>
        exercise.order > maxOrder ? exercise.order : maxOrder,
      0
    );
