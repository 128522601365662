const API_URL: string = process.env.API_URL || "";

/**
 * Custom Error which can include more data than the standard JS error.
 */
class ApiError extends Error {
  errors: Array<string>;
  statusCode: number;
  statusText: string;
  message: string;

  /**
   * Constructs an api error
   * @param {string} message The error message
   * @param {number} statusCode The returned status code
   * @param {string} statusText The returned status text
   * @param {Array<string>} errors An array of occured errors
   * @param {...*} params Additional parameters
   */
  constructor(
    message: string = "",
    statusCode: number = 200,
    statusText: string = "",
    errors: Array<string> = [],
    ...params: Array<any>
  ) {
    super(...params);

    this.message = message;
    this.statusCode = statusCode;
    this.statusText = statusText;
    this.errors = errors;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
  }
}

/**
 * Fetches data from the api
 * @param {string} url The url to fetch
 * @param {Object} options Options for fetching the data
 * @returns {Promise} The fetch response
 */
export const fetchApi = (
  url: string,
  options: {
    headers?: Headers;
    body?: string;
    method?: string;
  } = {}
): Promise<{
  data?: {} | Array<{}>;
  meta?: { total: number; per_page: number };
  message?: string;
  errors?: Array<string>;
}> => {
  const token: string = localStorage.getItem("jwt-token");
  const headers: Headers = options.headers || new Headers();

  if (!headers.get("Content-Type") && typeof options.body === "string") {
    headers.append("Content-Type", "application/json");
  }

  if (!headers.get("X-Requested-With")) {
    headers.append("X-Requested-With", "XMLHttpRequest");
  }

  if (!headers.get("Authorization") && token) {
    headers.append("Authorization", "Bearer " + token);
  }

  options.headers = headers;

  return fetch(API_URL + url, options)
    .then((response: Response) => {
      return response.status === 204
        ? Promise.resolve({})
        : response
            .json()
            .then(
              (json: {
                data?: {} | Array<{}>;
                meta?: { total: number; per_page: number };
                message?: string;
                errors?: Array<string>;
              }) => {
                if (response.ok) {
                  return Promise.resolve(json);
                }

                if (
                  response.status === 401 &&
                  window.location.pathname !== "/login"
                ) {
                  localStorage.removeItem("jwt-token");

                  // TODO: clear the whole redux store

                  //session expired
                  window.location.href =
                    "/login?redirect=" +
                    encodeURIComponent(window.location.pathname); //we can't use react-router in here as we don't have access to the store

                  return Promise.resolve({});
                }
                return Promise.reject(
                  new ApiError(
                    json.message,
                    response.status,
                    response.statusText,
                    json.errors
                  )
                );
              }
            )
            .catch((e: Error) =>
              Promise.reject(
                e instanceof ApiError
                  ? e
                  : new ApiError(
                      e.message,
                      response.status,
                      response.statusText
                    )
              )
            );
    })
    .catch((e: Error) => {
      const error = e instanceof ApiError ? e : new ApiError(e.message);
      window.dispatchEvent(
        new CustomEvent("failedFetch", { detail: { error } })
      );

      return Promise.reject(error);
    });
};
