import React from "react";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import moment from "moment";
import omit from "lodash/omit";
import { DateRangePicker } from "react-dates";
import { DateRangePickerPhrases } from "react-dates/src/defaultPhrases";
import DateRangePickerShape from "react-dates";
import {
  START_DATE,
  END_DATE,
  HORIZONTAL_ORIENTATION,
  ANCHOR_LEFT
} from "react-dates/constants";
import isInclusivelyAfterDay from "react-dates";
import { Button } from "reactstrap";

const availableDates = [
  { name: "Heute", start: moment(), end: moment() },
  {
    name: "Diese Woche",
    start: moment().startOf("week"),
    end: moment().endOf("week")
  },
  {
    name: "Letzte Woche",
    start: moment()
      .startOf("week")
      .subtract(14, "days"),
    end: moment()
      .startOf("week")
      .subtract(7, "days")
  },
  { name: "14 Tage", start: moment().subtract(14, "days"), end: moment() },
  {
    name: "Dieser Monat",
    start: moment().startOf("month"),
    end: moment().endOf("month")
  },
  {
    name: "Letzter Monat",
    start: moment()
      .subtract(1, "month")
      .startOf("month"),
    end: moment()
      .subtract(1, "month")
      .endOf("month")
  },
  {
    name: "Dieses Jahr",
    start: moment().startOf("year"),
    end: moment().endOf("year")
  },
  {
    name: "Letztes Jahr",
    start: moment()
      .subtract(1, "year")
      .startOf("year"),
    end: moment()
      .subtract(1, "year")
      .endOf("year")
  }
];

const propTypes = {
  // example props for the demo
  autoFocus: PropTypes.bool,
  autoFocusEndDate: PropTypes.bool,
  stateDateWrapper: PropTypes.func,
  setNewDates: PropTypes.func,
  initialStartDate: momentPropTypes.momentObj,
  initialEndDate: momentPropTypes.momentObj,

  ...omit(DateRangePickerShape, ["focusedInput", "onFocusChange"])
};

const defaultProps = {
  // example props for the demo
  autoFocus: false,
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,

  // input related props
  startDateId: START_DATE,
  startDatePlaceholderText: "Startdatum",
  endDateId: END_DATE,
  endDatePlaceholderText: "Enddatum",
  disabled: false,
  required: false,
  screenReaderInputMessage: "",
  showClearDates: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,
  block: false,
  small: false,
  regular: false,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  /**
   * when the previous month has been clicked
   * @returns {void}
   */
  onPrevMonthClick() {},
  /**
   * when the next month has been clicked
   * @returns {void}
   */
  onNextMonthClick() {},
  /**
   * when the calendar is closed clicked
   * @returns {void}
   */
  onClose() {},

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,

  // internationalization
  displayFormat: () => moment.localeData().longDateFormat("L"),
  monthFormat: "MMMM YYYY",
  phrases: DateRangePickerPhrases,

  stateDateWrapper: date => date
};

/**
 * Custom wrapper for the DateRangePicker
 */
class DateRangePickerWrapper extends React.Component {
  constructor(props) {
    super(props);

    let focusedInput = null;
    if (props.autoFocus) {
      focusedInput = START_DATE;
    } else if (props.autoFocusEndDate) {
      focusedInput = END_DATE;
    }

    this.state = {
      focusedInput,
      startDate: props.startDate,
      endDate: props.endDate
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }
  /**
   * Called when the date in the datepicker is changed
   * @param {momentObj} startDate the start Date
   * @param {momentObj} endDate the end Date
   * @returns {void}
   */
  onDatesChange({ startDate, endDate }) {
    const { stateDateWrapper } = this.props;
    this.setState({
      startDate: startDate && stateDateWrapper(startDate),
      endDate: endDate && stateDateWrapper(endDate)
    });
    this.props.onDatesChange({ startDate, endDate });
  }

  /**
   * Called when the focusedInput field changes
   * @param {string} focusedInput the focused input field
   * @returns {void}
   */
  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }

  /**
   * Called when a custom date button is pressed
   * @param {string} name the name of the selected date
   * @param {momentObj} startDate the start date
   * @param {momentObj} endDate the end date
   * @returns {void}
   */
  setNewDates(name, startDate, endDate) {
    this.setState({
      selectedDate: name,
      focusedInput: null,
      startDate,
      endDate
    });
    this.props.setNewDates(startDate, endDate);
  }

  render() {
    const { focusedInput, startDate, endDate } = this.state;

    // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
    // example wrapper but are not props on the SingleDatePicker itself and
    // thus, have to be omitted.
    const props = omit(this.props, [
      "autoFocus",
      "autoFocusEndDate",
      "initialStartDate",
      "initialEndDate",
      "stateDateWrapper"
    ]);

    return (
      <div>
        <DateRangePicker
          {...props}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          renderCalendarInfo={() => (
            <div className={"row px-3 pb-3"}>
              {availableDates.map(d => (
                <div className={"col-3"} key={d.name}>
                  <Button
                    onClick={() => this.setNewDates(d.name, d.start, d.end)}
                    color="link"
                    block
                  >
                    {d.name}
                  </Button>
                </div>
              ))}
            </div>
          )}
        />
      </div>
    );
  }
}

DateRangePickerWrapper.propTypes = propTypes;
DateRangePickerWrapper.defaultProps = defaultProps;

export default DateRangePickerWrapper;
