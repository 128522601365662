import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter } from "react-router";
import { Container, Row, Col, Card, CardTitle } from "reactstrap";
import yup from "yup";
import { Formik } from "formik";
import { error, success } from "react-notification-system-redux";

import MainContainer from "../components/MainContainer";
import Wrapper from "../components/Wrapper";
import Header from "./Header";
import { getAuthenticatedUser, getIsAuthenticated } from "../reducers";
import ProfileForm from "../components/forms/ProfileForm";
import { updateAuthUser } from "../actions/authentication";

/**
 * Page to edit an user's profile
 * @extends React.PureComponent
 * @returns {Component} the react component
 */
class Profile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      invitation: null,
      acceptError: null,
      isAcceptLoading: false
    };
  }
  validationSchema = () => {
    return yup.object().shape({
      firstname: yup.string().required(),
      lastname: yup.string().required(),
      email: yup
        .string()
        .email()
        .required()
    });
  };

  /**
   * Submits the forms
   * @param {Object} values that should be submitted
   * @param {Object} props of the form
   * @param {function} setSubmitting function to set the submitting state inside the form
   * @param {function} setErrors function to set the errors inside the form
   * @returns {void}
   */
  handleSubmit = (values, { props, setSubmitting, setErrors }) => {
    const {
      updateAuthUser,
      successNotification,
      errorNotification
    } = this.props;
    const { redirect } = this.state;

    updateAuthUser({
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email
    })
      .then(() => {
        successNotification({
          message: "Profil wurde erfolgreich aktualisiert."
        });
        setSubmitting(false);
      })
      .catch(error => {
        setSubmitting(false);
        // TODO: transform API errors to Formik's errors
        setErrors(error.errors);
        errorNotification({
          message: "Profil konnte nicht aktualisiert werden."
        });
      });
  };
  componentWillMount() {}
  render() {
    const {
      user: { firstname, lastname, email, username }
    } = this.props;
    return (
      <Wrapper header footer column>
        <Header />
        <MainContainer>
          <Container>
            <Row>
              <Col md={{ size: 6, offset: 3 }}>
                <Card body>
                  <CardTitle>Profil bearbeiten</CardTitle>
                  <Formik
                    initialValues={{
                      firstname: firstname,
                      lastname: lastname,
                      username: username,
                      email: email
                    }}
                    onSubmit={this.handleSubmit}
                    component={ProfileForm}
                    validationSchema={this.validationSchema}
                  />
                </Card>
              </Col>
            </Row>
          </Container>
        </MainContainer>
      </Wrapper>
    );
  }
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { courseSlug }
    }
  }
) => {
  return {
    user: getAuthenticatedUser(state),
    courseSlug,
    isAuthenticated: getIsAuthenticated(state)
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { courseSlug, courseInvitationToken }
    }
  }
) => ({
  /**
   * Update the user information of the currently authenticated user
   * @param {Object} user updated user object
   * @returns {Promise} the updateAuthUser Promise
   */
  updateAuthUser(user) {
    return dispatch(updateAuthUser(user));
  },
  /**
   * Displays a success notification
   * @param {Object} notification An object containing notification properties
   * @returns {Promise} The promise describing the progress of the action
   */
  successNotification(notification) {
    return dispatch(
      success({ dismissible: "click", position: "bc", ...notification })
    );
  },
  /**
   * Displays an error notification
   * @param {Object} notification An object containing notification properties
   * @returns {Promise} The promise describing the progress of the action
   */
  errorNotification(notification) {
    return dispatch(
      error({ dismissible: "click", position: "bc", ...notification })
    );
  }
});

const mergeProps = (mapStateToProps, mapDispatchToProps, ownProps) => ({
  ...ownProps,
  ...mapStateToProps,
  ...mapDispatchToProps
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(Profile)
);
