import React from "react";
import PropTypes from "prop-types";
import { Input, FormFeedback } from "reactstrap";
import { Field } from "formik";
import styled from "styled-components";
import get from "lodash/get";

import { colors } from "../../utilities/style";

/**
 * An input field that can be used inside formik forms
 * @returns {Component} The component
 */
class InputField extends React.Component {
  render = () => {
    const {
      type,
      name,
      placeholder,
      checkedValue,
      disabled,
      tabIndex
    } = this.props;
    return (
      <Field
        name={name}
        render={({
          field: { name, value, onChange, onBlur },
          form: { errors, touched, setFieldTouched }
        }) => {
          const t = get(touched, name, false),
            e = get(errors, name, false),
            valid = !e && t,
            invalid = e && t;

          const checkbox = ["checkbox", "radio"].includes(type);
          return (
            <div>
              <Input
                type={type}
                name={name}
                placeholder={placeholder}
                value={checkbox ? checkedValue : value}
                className="px-1"
                tabIndex={0}
                data-hj-whitelist
                valid={valid ? valid : invalid ? false : null}
                /*invalid={invalid} reactstrap docs are useless*/
                onChange={e => {
                  onChange(e);
                  setFieldTouched(name, true);
                }}
                disabled={disabled}
                onBlur={onBlur}
                {...(checkbox
                  ? {
                      checked:
                        value === checkedValue || value === true || value === 1
                    }
                  : {})}
              />
              <FormFeedback>{invalid ? e : ""}</FormFeedback>
            </div>
          );
        }}
      />
    );
  };
}

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  checkedValue: PropTypes.string,
  tabIndex: PropTypes.number
};

export default InputField;
