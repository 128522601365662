import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  deserializeBlock,
  renderMarkButton,
  renderNode
} from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { toggleBlock } from "../helpers/handlers";

//use a 'div' here so we can nest the popovers
export const StyledParagraph = styled.div`
  margin: 0;
  min-height: 1em;
`;

/**
 * Converts the selected text to a paragraph
 */
class ParagraphMarkButton extends AntonButton {
  name = "paragraph-mark";
  icon = <FontAwesomeIcon icon={["far", "eraser"]} />;
  tooltip = {
    target: "paragraph-mark",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Formatierung löschen"
  };
  /**
   * Toggled on mouse down
   * @param {Editor} editor
   * @returns {void}
   */
  onMouseDown = editor => {
    editor.value.marks.forEach(mark => editor.removeMark(mark.type));
    editor.value.blocks.forEach(block => toggleBlock(block.type)(editor)); //editor.unwrapBlock(block.type));
  };
}

/**
 * Renders a paragraph
 */
class ParagraphBlock extends React.PureComponent {
  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { children, attributes } = this.props;
    return <StyledParagraph {...attributes}>{children}</StyledParagraph>;
  };
}

/**
 * The Paragraph Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const Paragraph = options =>
  Object.assign(
    renderNode("paragraph", ParagraphBlock),
    renderMarkButton("paragraph-mark", ParagraphMarkButton),
    deserializeBlock("paragraph", "p"),
    {
      isActive: () => false
    }
  );

export default Paragraph;
