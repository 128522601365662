import {Button, Collapse, Container, Nav, Navbar, NavbarBrand, NavItem, NavLink} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import React from "react";
import { Link } from "react-router-dom";

import Brand from "../../components/Brand";
import {colors} from "../../utilities/style";

const MainNavbar = styled(Navbar)`
  background-color: white;
  && .navbar-toggler {
    background-color: ${colors.primary};
    background-color: ${colors.primary};
  }
  /* && .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.75);
  }
  && .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 1);
  }*/
`;

/**
 * The navbar of the landing page
 * @returns {Component} the component
 */
class NavbarLanding extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }
  toggle = () => this.setState({ isOpen: !this.state.isOpen });
  render() {
    return(
      <MainNavbar expand="md" fixed={"top"}>
        <Container>
          <NavbarBrand href="/">
            <Brand />
          </NavbarBrand>
          <Button onClick={this.toggle} color={"primary"} className={"navbar-toggler btn-primary"}>
            <FontAwesomeIcon icon={this.state.isOpen ? ["far","times"] : ["far","bars"]}/>
          </Button>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink href="/#home">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/pricing">Preise</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/team">Team</NavLink>
              </NavItem>
            </Nav>
            <Nav className="ml-auto" navbar>
              <NavItem className="ml-4">
                <NavLink href="/login">Login</NavLink>
              </NavItem>
              <Link to={"/register"}>
                <Button color={"success"} className="ml-2 btn my-2 my-sm-0">
                  Registrieren
                </Button>
              </Link>
            </Nav>
          </Collapse>
        </Container>
      </MainNavbar>
    )
  }
}

export default NavbarLanding;
