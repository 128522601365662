import {
  createFetchActionFactory,
  createFetchSingleItemActionFactory,
  createFetchSingleItemThunkCreator,
  createFetchItemsActionFactory,
  createFetchItemPageActionFactory,
  createFetchAllItemsThunkFactory,
  createFetchItemsPageThunkFactory,
  createCreateItemActionCreator,
  createCreateItemThunkCreator,
  createUpdateItemActionCreator,
  createUpdateItemThunkCreator,
  createDeleteItemActionCreator,
  createDeleteItemThunkCreator
} from "../utilities/action";
import { getAuthenticatedUser } from "../reducers";
import { fetchApi } from "../utilities/api";
import { mapItem as mapLesson, fetchItems as fetchLessons } from "./lesson";
import { jsonToHtml } from "../components/anton/helpers/serializer";

export const itemName = "course";

/**
 * Maps an item so we can store it in the state
 * @param {Object} item The item to map
 * @returns {Object} The mapped item
 */
export const mapItem = ({
  id,
  name,
  description,
  slug,
  status,
  users,
  lessons,
  users_count,
  lessons_count,
  fork_of,
  created_at,
  updated_at,
  user_type: userType,
  user_progress_detailed: userProgressDetailed,
  public_stats: publicStats
}) => ({
  id,
  name,
  description,
  descriptionHtml: jsonToHtml(description),
  slug,
  status,
  users,
  lessonIds: lessons
    ? lessons.sort((a, b) => a.order - b.order).map(lesson => lesson.id)
    : null,
  usersCount: users_count,
  lessonsCount: lessons_count,
  createdAt: created_at,
  updatedAt: updated_at,
  savedAt: updated_at,
  forkOf: fork_of,
  userProgressDetailed,
  publicStats,
  userType
});

/**
 * Action called before and after fetching an item
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} item The received item
 * @returns {object} The redux action
 */
export const fetchItem = createFetchSingleItemActionFactory(itemName);

/**
 * Receives a new user role for the specified course
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The dashbaord's id the received role is for
 * @param {string} role The received user role
 * @returns {object}
 */
const receiveUserRole = createFetchActionFactory(
  "RECEIVE_USER_ROLE",
  "courseId",
  "role"
);

/**
 * Fetches a single item
 * @param {string} courseSlug The slug of the requested course
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} A redux thunk
 */
export const fetchCourse = (courseSlug, visualize = false) => (
  dispatch,
  getState
) => {
  const { id: userId } = getAuthenticatedUser(getState());

  dispatch(fetchItem(true, null, visualize, courseSlug));

  return fetchApi(`/api/courses/${courseSlug}`, {
    method: "GET"
  })
    .then(({ data: item }) => {
      const mappedItem = mapItem(item);

      dispatch(fetchItem(false, null, visualize, courseSlug, mappedItem));

      dispatch(
        receiveUserRole(
          false,
          null,
          visualize,
          mappedItem.id,
          (mappedItem.users.find(user => user.id == userId) || {}).type
        )
      );

      dispatch(
        fetchLessons(false, null, visualize, item.lessons.map(mapLesson))
      );

      return Promise.resolve({ item: mappedItem, originalItem: item });
    })
    .catch(error => {
      dispatch(fetchItem(false, error, visualize, courseSlug));

      return Promise.reject(error);
    });
};

/**
 * Action called before and after fetching multiple items
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} items The received items
 * @returns {object} The redux action
 */
export const fetchItems = createFetchItemsActionFactory(itemName);

/**
 * Action called before and after fetching an item page
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} items The received items
 * @param {number} page The fetched page
 * @param {string} [query] The search query
 * @param {string} [orderBy] What field the results should be orderd by
 * @param {string} [order] The result order (ASC/DESC)
 * @returns {object} The redux action
 */
export const fetchItemPage = createFetchItemPageActionFactory(
  itemName,
  "query",
  "orderBy",
  "order"
);

/**
 * Fetches all items
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {string} [query] The search query
 * @param {string} [orderBy] What field the results should be orderd by
 * @param {string} [order] The result order (ASC/DESC)
 * @returns {function} The redux thunk
 */
export const fetchAllCourses = createFetchAllItemsThunkFactory(
  fetchItemPage,
  (page, query, orderBy, order) =>
    `/api/courses?page=${page}${query ? `&s=${query}` : ""}${
      orderBy ? `&orderBy=${orderBy}` : ""
    }${order ? `&direction=${order}` : ""}`,
  mapItem
);

/**
 * Fetches the specified pages
 * @param {number} [page=1}] The first page to fetch
 * @param {number} [pageTo=-1}] The last page to fetch (-1 = until no more pages are available)
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {string} [query] The search query
 * @param {string} [orderBy] What field the results should be orderd by
 * @param {string} [order] The result order (ASC/DESC)
 * @returns {function} The redux thunk
 */
export const fetchCoursePage = createFetchItemsPageThunkFactory(
  fetchItemPage,
  (page, query, orderBy, order) =>
    `/api/courses?page=${page}${query ? `&s=${query}` : ""}${
      orderBy ? `&orderBy=${orderBy}` : ""
    }${order ? `&direction=${order}` : ""}`,
  mapItem
);

/**
 * Receives a new user role for the specified course
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {array} items All courses the current user has a role in
 * @returns {object}
 */
const fetchUserCoursesAction = createFetchActionFactory(
  "FETCH_USER_COURSES",
  "items"
);

/**
 * Fetches all courses from the currently authenticated user
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} The redux thunk
 */
export const fetchUserCourses = (visualize = false) => dispatch => {
  dispatch(fetchItems(true, null, visualize));

  return fetchApi(`/api/users/me/courses`, {
    method: "GET"
  })
    .then(({ data: items }) => {
      const mappedItems = items.map(mapItem);
      dispatch(fetchUserCoursesAction(false, null, false, mappedItems));
      dispatch(fetchItems(false, null, visualize, mappedItems));

      return Promise.resolve(mappedItems);
    })
    .catch(err => {
      dispatch(fetchItems(false, err));

      return Promise.reject(err);
    });
};

/**
 * Action called before and after creating a new item
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} item The item that should be / was created
 * @returns {object} The redux action
 */
export const createItem = createCreateItemActionCreator(itemName);

/**
 * Creates a new item
 * @param {object} item The item's data
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} The redux thunk
 */
export const createCourse = createCreateItemThunkCreator(
  createItem,
  () => `/api/courses`,
  mapItem
);

/**
 * Action called before and after updating an item
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {object} item The item that should be / was created
 * @returns {object} The redux action
 */
export const updateItem = createUpdateItemActionCreator(itemName);

/**
 * Creates a new item
 * @param {object} item The item's data
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} The redux thunk
 */
export const updateCourse = createUpdateItemThunkCreator(
  updateItem,
  id => `/api/courses/${id}`,
  mapItem
);

/** Action dispatched before and after a new course subscription
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {array} users The (new) course users
 * @param {number} courseId The id of the course to subscribe to
 * @param {number} userId The new subscriber's id
 * @returns {object} The redux action
 */
const subscribeAction = createFetchActionFactory(
  "CREATE_COURSE_SUBSCRIBER",
  "users",
  "courseId",
  "userId"
);

/**
 * Subscribes the currently authenticated user to a course
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The id of the course to subscribe to
 * @returns {function} The redux thunk
 */
export const subscribe = (visualize = false, courseId) => dispatch => {
  dispatch(subscribeAction(true, null, visualize, [], courseId, null));

  return fetchApi(`/api/courses/${courseId}/subscribe`, {
    method: "POST",
    body: JSON.stringify({})
  })
    .then(({ data: subscribers }) => {
      dispatch(
        subscribeAction(false, null, visualize, subscribers, courseId, null)
      );

      return Promise.resolve({ subscribers });
    })
    .catch(error => {
      dispatch(
        subscribeAction(false, error.message, visualize, [], courseId, null)
      );

      return Promise.reject(error);
    });
};

/** Action dispatched before and after a user unsubscribed from a course
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} userId The subscriber's user id
 * @param {number} courseId The id of the course to subscribe to
 * @param {boolean} users The (new) course users
 * @returns {object} The redux action
 */
const removeUserAction = createDeleteItemActionCreator("COURSE_USER", "userId");

/**
 * Removes the currenlty authenticated user from a course
 * @param {number} courseId The course's id
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} The redux thunk
 */
export const unsubscribe = createDeleteItemThunkCreator(
  removeUserAction,
  courseId => `/api/courses/${courseId}/unsubscribe`
);

/**
 * Adds the user as a subscriber to the course
 * @param {number} userId The user's id
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The id of the course to subscribe to
 * @returns {function} The redux thunk
 */
export const addSubscriber = (userId, visualize, courseId) =>
  createCreateItemThunk(
    subscribeAction,
    (users, courseId) => `/api/courses/${courseId}/subscribers`
  )({ user_id: userId }, visualize, courseId);

/**
 * Removes a user from a course
 * @param {number} courseId The course's id
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} userId the if of the user that should be removed
 * @returns {function} The redux thunk
 */
export const removeUser = createDeleteItemThunkCreator(
  removeUserAction,
  (courseId, userId) => `/api/courses/${courseId}/users/${userId}`
);

export const changeUserRoleAction = createUpdateItemActionCreator(
  "COURSE_USER",
  "userId"
);

/**
 * Changes the role of the given user for the given course
 * @param {number} courseId id of the course that should be changed
 * @param {number} userId id of the user that should be changed
 * @param {string} role the new role of the user ('subscriber', 'collaborator' oder 'author')
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} The redux thunk
 */
export const changeUserRole = (courseId, userId, role, visualize = false) =>
  createUpdateItemThunkCreator(
    changeUserRoleAction,
    (courseId, update, userId) => `/api/courses/${courseId}/users/${userId}`
  )(courseId, { role }, visualize, userId);

// TODO rename because "move" will be implemented later to move a lesson to another course
/**
 * Moves a lesson from one index to another
 * @param {string} courseSlug The slug of the course whose lesson should be moved
 * @param {number} sourceIndex The index of the lesson to move
 * @param {number} targetIndex The index where the selected lesson should be moved to
 * @returns {Object} The redux action
 */
export const moveLesson = (courseSlug, sourceIndex, targetIndex) => ({
  type: "COURSE_MOVE_LESSON",
  courseSlug,
  sourceIndex,
  targetIndex
});

/** Action dispatched before and after a user unsubscribed from a course
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {array} lessonOrder Array of lessonIds in the new order
 * @returns {object} The redux action
 */
const updateLessonOrderAction = createFetchActionFactory(
  "COURSE_UPDATE_LESSON_ORDER",
  "courseId",
  "lessonOrder"
);

/**
 * Updates the lesson order of a course
 * @param {string} courseId The course's slug whose lesson order should be updated
 * @param {Array} lessonOrder Array of lesson ids
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @returns {function} The redux thunk
 */
export const updateLessonOrder = (
  courseId,
  lessonOrder,
  visualize = false
) => dispatch => {
  dispatch(
    updateLessonOrderAction(true, null, visualize, courseId, lessonOrder)
  );

  return fetchApi(`/api/courses/${courseId}/lessons/order`, {
    method: "PUT",
    body: JSON.stringify({ order: lessonOrder })
  })
    .then(({ order }) => {
      dispatch(
        updateLessonOrderAction(false, null, visualize, courseId, order)
      );

      return Promise.resolve(order);
    })
    .catch(err => {
      dispatch(
        updateLessonOrderAction(false, err, visualize, courseId, lessonOrder)
      );

      return Promise.reject(err);
    });
};

/**
 * Action dispatched before and after deleting an item
 * @param {object} itemId The id of the item that should be / was deleted
 * @param {boolean} isFetching Whether it is currently being fetched
 * @param {string} error If there was an error during the request, this field should contain it
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The course's id the lesson is part of
 * @returns {object} The redux action
 */
export const deleteCourseAction = createDeleteItemActionCreator(
  itemName,
  "courseId"
);

/**
 * Deletes a lesson
 * @param {number} lessonId The lesson's id
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The course's id the lesson's part of
 * @returns {function} The redux thunk
 */
export const deleteCourse = createDeleteItemThunkCreator(
  deleteCourseAction,
  courseId => `/api/courses/${courseId}`,
  mapItem
);

/**
 * Subscribes the currently authenticated user to a course
 * @param {boolean} [visualize=false] Whether the progress of this action should be visualized
 * @param {number} courseId The id of the course that should be duplicated
 * @param {string} newName the new name of the course
 * @returns {function} The redux thunk
 */
export const duplicateCourse = (
  visualize = false,
  courseId,
  newName
) => dispatch => {
  dispatch(createItem(true, null, visualize, null));

  return fetchApi(`/api/courses/${courseId}/duplicate`, {
    method: "POST",
    body: JSON.stringify({ name: newName })
  })
    .then(({ data: item }) => {
      const mappedItem = mapItem(item || {});
      dispatch(createItem(false, null, visualize, mappedItem));

      return Promise.resolve({ item: mappedItem, originalItem: item });
    })
    .catch(error => {
      dispatch(createItem(false, error.message, visualize, null));

      return Promise.reject(error);
    });
};
