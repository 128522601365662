/**
 * Handles the state inside the exercises while they are played
 * @param {Object} state The previous state
 * @param {Object} action The action to proccess
 * @returns {Object} The next state
 */
const playReducer = (
	state = {
		hearts: 3,
		currentExercisePosition: 0,
		loadedExercises: [
		  /*{uid, nonce}*/
    ],
		validatedExercises: [
			/*{uid, type, correct, payload}*/
		],
		isValidating: false
	},
	action
) => {
	switch (action.type) {
		case "PLAY_SET_EXERCISE":
			return { ...state, currentExercisePosition: action.exercise };
		case "PLAY_SET_EXERCISES":
			return { ...state,
        loadedExercises: action.exercises.map(ex => ({uid: ex.uid, nonce: nonce()})),
        currentExercisePosition: 0,
        validatedExercises: []
			};
		case "PLAY_REPEAT_WRONG":
			return {
				...state,
				currentExercisePosition: 0,
				loadedExercises: state.validatedExercises
					.filter(exercise => exercise.correct === false)
					.map(ex => ({uid: ex.uid, nonce: nonce()})),
				validatedExercises: []
			};
    case "PLAY_REPEAT_ALL":
			return { ...state, loadedExercises: state.loadedExercises.map(ex => ({...ex, nonce: nonce()})), currentExercisePosition: 0, validatedExercises: [] };
		case "PLAY_NEXT_EXERCISE":
			return {
				...state,
				currentExercisePosition: state.currentExercisePosition + 1
			};
		case "PLAY_PREVIOUS_EXERCISE":
			return {
				...state,
				currentExercisePosition: state.currentExercisePosition - 1
			};
		case "PLAY_VALIDATE":
			return { ...state, isValidating: action.isValidating };
		case "PLAY_ADD_VALIDATED_EXERCISE":
			return {
				...state,
				validatedExercises: [
					...state.validatedExercises,
					{ uid: action.exerciseUid, type: action.exerciseType, correct: action.correct, payload: action.payload }
				]
			};
		default:
			return state;
	}
};

export default playReducer;

/**
 * Creates a unique nonce string
 * @returns {string} the nonce
 */
const nonce = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

/**
 * Returns the current exercise index
 * @param {Object} state This part of the redux state
 * @returns {number} The currents exercise index
 */
export const getPlayCurrentExercisePosition = state =>
	state.currentExercisePosition;

/**
 * Returns the currently loaded exercises
 * @param {Object} state This part of the redux state
 * @returns {number} The currents exercise index
 */
export const getPlayLoadedExercises = state => state.loadedExercises;

/**
 * Returns the user's hearts for the current lesson
 * @param {Object} state This part of the redux state
 * @returns {number} The amount of hearts the user has
 */
export const getPlayHearts = state => state.hearts;
/**
 * Returns the array of all validated exercises
 * @param {Object} state This part of the redux state
 * @returns {Array} The array of exercises already answered
 */
export const getPlayValidatedExercises = state => state.validatedExercises;

/**
 * Returns the validation for an exercise by it's id
 * @param {Object} state This part of the redux state
 * @param {string} exerciseUid the uid of the exercise
 * @returns {T} the validation
 */
export const getPlayValidationByExerciseUid = (state, exerciseUid) =>
  state.validatedExercises.find(exercise => exercise.uid === exerciseUid);
