import { combineReducers } from "redux";

import { createAllIds, createById } from "../../utilities/reducer";
import { itemName as analyticsItemName } from "../../actions/analyticsUserProgress";

export default combineReducers({
  byId: createById(analyticsItemName),
  allIds: createAllIds(analyticsItemName)
});

export {
  getAllItems as getAnalyticsUserProgress,
  getItemById as getAnalyticsUserProgressById
} from "utilities/reducer";
