import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faAngleDown,
  faAnalytics,
  faAngleRight,
  faArrowsH,
  faBars,
  faBold,
  faBookmark,
  faBriefcase,
  faCalculator,
  faCheck,
  faCheckCircle,
  faClone,
  faClock,
  faCode,
  faCodeBranch,
  faCopy,
  faComments,
  faDesktopAlt,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faEraser,
  faEdit,
  faFileImage,
  faFunction,
  faH1,
  faH2,
  faH3,
  faHome,
  faInfoCircle,
  faInfoSquare,
  faItalic,
  faKeyboard,
  faLink,
  faList,
  faListAlt,
  faListOl,
  faListUl,
  faLock,
  faMobileAlt,
  faNewspaper,
  faPaintBrush,
  faPaste,
  faPencilAlt,
  faPlus,
  faQuestionCircle,
  faQuoteLeft,
  faRepeat,
  faSearch,
  faSpinner,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTrashAlt,
  faUnderline,
  faUser,
  faUserGraduate,
  faUnlink,
  faUsers,
  faFilm,
  faSave
} from "@fortawesome/pro-regular-svg-icons";
import {
  faAlarmClock,
  faCommentsAlt,
  faFileCheck,
  faGlobe,
  faSmile,
  faUsers as falUsers
} from "@fortawesome/pro-light-svg-icons";
import {
  faCheck as fasCheck,
  faExclamationSquare
} from "@fortawesome/pro-solid-svg-icons";

/**
 * Adds all the icons to the fontawesome library
 * @constructor
 */
const InitializeIcons = () =>
  library.add(
    faHome,
    faAngleRight,
    faAnalytics,
    faSpinner,
    faAngleDown,
    faUsers,
    faFilm,
    faFunction,
    faBookmark,
    faComments,
    falUsers,
    faLock,
    faCodeBranch,
    faEllipsisV,
    faExclamationSquare,
    faEdit,
    faInfoCircle,
    faKeyboard,
    faTimes,
    faPlus,
    faBold,
    faListUl,
    faCode,
    faH1,
    faH2,
    faH3,
    faLink,
    faFileImage,
    faArrowsH,
    faAlignLeft,
    faAlignCenter,
    faAlignRight,
    faTrashAlt,
    faItalic,
    faCalculator,
    faCheck,
    faClock,
    faListOl,
    faEraser,
    faQuoteLeft,
    faUnderline,
    faSave,
    faCopy,
    faInfoSquare,
    faEllipsisH,
    faQuestionCircle,
    faPaste,
    faClone,
    faPencilAlt,
    faList,
    faNewspaper,
    faThumbsUp,
    faThumbsDown,
    faTimes,
    faPaintBrush,
    faMobileAlt,
    faDesktopAlt,
    faCode,
    faUserGraduate,
    faBriefcase,
    faCodeBranch,
    faUser,
    faSearch,
    faAlarmClock,
    faGlobe,
    faFileCheck,
    faSmile,
    faCommentsAlt,
    faRepeat,
    faCheckCircle,
    fasCheck,
    faLock,
    faDownload,
    faUsers,
    faUnlink,
    faListAlt,
    faBars
  );

export default InitializeIcons;
