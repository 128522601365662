import { combineReducers } from "redux";
import changeCase from "change-case/change-case";
import pluralize from "pluralize";

import { createAllIds, createById } from "../../utilities/reducer";
import { itemName as analyticsItemName } from "../../actions/analyticsAggregatedProgress";


/**
 * Reducer for the aggregatedProgresses
 * @param {Object} state the state that the reducer is applied to
 * @param {Object} action the action that the reducer should act on
 * @returns {Array} the new state
 */
const aggregatedProgressReducer = (state = [], action) => {
  switch (action.type) {
    case "FETCH_" + changeCase.snakeCase(pluralize(analyticsItemName)).toUpperCase():
      return action.isFetching || action.error || !action.items
        ? state
        : action.items;
    default:
      return state
  }
};

/**
 * Returns the state
 * @param {*} state the redux state
 * @returns {*} the state
 */
export const getAnalyticsAggregatedProgress = (state) => state;

export default aggregatedProgressReducer;
