import { combineReducers } from "redux";
// @ts-ignore
import pluralize from "pluralize";
// @ts-ignore
import changeCase from "change-case";
// @ts-ignore
import pickBy from "lodash/pickBy";

import { ActionType } from "./action";

export interface GenericReducerStateType {
  allIds: Array<string | number>;
  byId: { [index: string]: any };
}

/**
 * Removes a key from an object
 * @param {Object} obj The object
 * @param {string} deleteKey The name of the key to remove
 * @returns {Object} The object without the deleted key
 */
export function removeKey(obj: {}, deleteKey: number | string): {} {
  let clone: { [index: string]: any } = Object.assign({}, obj);
  delete clone[deleteKey];
  return clone;
}

/**
 * Removes all 'undefined' keys from an object
 * @param {Object} object The object that should be cleaned
 * @returns {Object} The cleaned object
 */
export const cleanObject = (object: {}): {} =>
  pickBy(object, (obj: {}) => obj !== undefined);

/**
 * Creates a reducer holding all ids/unique properties of a certain item type
 * @param {string} name The singular name of the items
 * @param {string} [uniqueProperty="id"] A unique property of this item type
 * @param {function} [customCases=null] An optional function to handle custom cases
 * @returns {function} The reducer
 */
export const createAllIds = (
  name: string,
  uniqueProperty: string = "id",
  customCases: (
    state: Array<string | number>,
    action: {
      type: string;
      isFetching: boolean;
      error: string;
      itemId?: number | string;
      item?: {};
      items?: Array<{}>;
    }
  ) => Array<string | number> = null
) => (
  state: Array<string | number> = [],
  action: {
    type: string;
    isFetching: boolean;
    error: string;
    itemId?: number | string;
    item?: { [index: string]: any };
    items?: Array<{}>;
  }
): Array<string | number> => {
  if (customCases) {
    let returnValue = customCases(state, action);

    if (returnValue !== state) {
      return returnValue;
    }
  }

  switch (action.type) {
    case "FETCH_" + changeCase.snakeCase(name).toUpperCase():
      return action.itemId
        ? !action.isFetching && action.item && !action.error
          ? state.includes(action.itemId)
            ? state
            : [...state, action.itemId]
          : state.filter(itemId => itemId !== action.itemId)
        : state;

    case "FETCH_" + changeCase.snakeCase(pluralize(name)).toUpperCase():
      return action.items
        ? !action.isFetching && !action.error
          ? [
              ...state,
              ...action.items
                .map((item: { [index: string]: any }) => item[uniqueProperty])
                .filter(id => !state.includes(id))
            ]
          : state
        : state;
    case "CREATE_" + changeCase.snakeCase(name).toUpperCase():
      return !action.isFetching &&
        action.item &&
        action.item[uniqueProperty] &&
        !action.error &&
        !state.includes(action.item[uniqueProperty])
        ? [...state, action.item[uniqueProperty]]
        : state;
    case "DELETE_" + changeCase.snakeCase(name).toUpperCase():
      return !action.isFetching && action.itemId
        ? state.filter(id => id !== action.itemId)
        : state;
    default:
      return state;
  }
};

/**
 * Creates a reducer mapping all ids/unique properties to their values
 * @param {string} name The singular name of the items
 * @param {string} [uniqueProperty="id"] A unique property of this item type
 * @param {function} [customCases=null] An optional function to handle custom cases
 * @returns {function} The reducer
 */
export const createById = (
  name: string,
  uniqueProperty: string = "id",
  customCases: (
    state: {},
    action: {
      type: string;
      isFetching: boolean;
      error: string;
      itemId?: number | string;
      item?: {};
      items?: Array<{}>;
      page?: number;
    }
  ) => {} = null
) => (
  state: { [index: string]: any } = {},
  action: {
    type: string;
    isFetching: boolean;
    error: string;
    itemId?: number | string;
    item?: { [index: string]: any };
    items?: Array<{}>;
    page?: number;
  }
) => {
  if (customCases) {
    let returnValue = customCases(state, action);

    if (returnValue !== state) {
      return returnValue;
    }
  }

  switch (action.type) {
    case "FETCH_" + changeCase.snakeCase(name).toUpperCase():
      return action.itemId && action.item
        ? {
            ...state,
            [action.itemId]: {
              ...cleanObject(action.item),
              _isFetching: action.isFetching,
              _error: action.error
            }
          }
        : state;
    case "FETCH_" + changeCase.snakeCase(pluralize(name)).toUpperCase():
      return action.isFetching || action.error || !action.items
        ? state
        : {
            ...state,
            ...action.items.reduce(
              (
                object: { [index: string]: any },
                item: { [index: string]: any }
              ) => {
                object[item[uniqueProperty]] = {
                  ...state[item[uniqueProperty]],
                  ...cleanObject(item),
                  _isFetching: action.isFetching,
                  _error: action.error
                };
                return object;
              },
              {}
            )
          };
    case "CREATE_" + changeCase.snakeCase(name).toUpperCase():
      return !action.isFetching && !action.error && action.item
        ? {
            ...state,
            [action.item[uniqueProperty]]: {
              ...cleanObject(action.item),
              _isFetching: action.isFetching,
              _error: action.error
            }
          }
        : state;
    case "UPDATE_" + changeCase.snakeCase(name).toUpperCase():
      return !action.isFetching && action.item && action.item[uniqueProperty]
        ? {
            ...state,
            [action.item[uniqueProperty]]: {
              ...state[action.item[uniqueProperty]],
              ...cleanObject(action.item),
              _isFetching: action.isFetching,
              _error: action.error
            }
          }
        : state;
    case "DELETE_" + changeCase.snakeCase(name).toUpperCase():
      return !action.isFetching && action.itemId
        ? removeKey(state, action.itemId)
        : state;
    default:
      return state;
  }
};

/**
 * Creates a normalized reducer
 * @param {string} name The item name used for the actions
 * @param {string} [uniqueProperty="id"] A unique property of this item type
 * @returns {function} The reducer
 */
export const createReducer = (name: string, uniqueProperty: string = "id") =>
  combineReducers({
    byId: createById(name, uniqueProperty),
    allIds: createAllIds(name, uniqueProperty)
  });

/**
 * Gets a single item based on its id
 * @param {Object} state The correct part of the redux state
 * @param {number} itemId The items id to look for
 * @returns {Object} The item
 */
export const getItemById = (
  state: GenericReducerStateType,
  itemId: number | string
) => state.byId[itemId];

/**
 * Gets all items
 * @param {Object} state The correct part of the redux state
 * @returns {Array} All items
 */
export const getAllItems = (state: GenericReducerStateType): Array<{}> =>
  state.allIds.map(id => state.byId[id]);

/**
 * Wraps a function, useful for redux getters
 * @param {function} target The function to wrap
 * @param {function} mapState Maps the state to the part that should be passed
 * @returns {function} The wrapped function
 */
export const wrap = (
  target: (object: {}, ...args: Array<any>) => any,
  mapState = ({}) => {}
) => (state: {}, ...args: Array<any>) => target(mapState(state), ...args);
