import React, { useRef, useCallback } from "react";
import styled from "styled-components";

import { colors, shadows, borders } from "../../../utilities/style";
import HyperlinkEditPopover from "./HyperlinkEditPopover";
import PopoverPreview from "../PopoverPreview";
import { Popover } from "reactstrap";

const FakeA = styled.span`
  color: ${colors.green_3};
  text-decoration: ${({ isSelected }) => (isSelected ? "underline" : "none")};

  /* in order to position the popover */
  display: inline-block;
  position: relative;
`;

const StyledA = styled.a`
  color: ${colors.green_3};
  cursor: pointer;

  &&:hover {
    color: ${colors.green_4};
  }
`;

const LinkPreview = styled.div`
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: center;

  & > * {
    flex-shrink: 0;
  }
`;

const Url = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  flex-shrink: 1;
`;

/**
 * Renders a hyperlink
 * @returns {Component} the react component
 */
const HyperlinkMark = ({
  children,
  attributes,
  node,
  isSelected,
  isFocused,
  serializer,
  editor
}) => {
  const ref = useRef(null);

  /**
   * Sets a reference to the element
   * @param {Element} element the element that should be set as reference
   * @returns {void}
   */
  const setRef = useCallback(element => {
    ref.current = element;
  }, []);

  const url = node.data.get("url") || "";
  const removeLink = useCallback(() => editor.unwrapLink(), [editor]);
  const shouldAutoOpen = useCallback(
    () => (isSelected && isFocused && url.trim().length) === 0,
    [isSelected, isFocused, url]
  );

  if (serializer) {
    return (
      <StyledA
        href={url ? url : "#"}
        {...attributes}
        target="_blank"
        rel="noreferrer noopener "
      >
        {children}
      </StyledA>
    );
  }
  //othwewise render the editing mark
  return (
    <FakeA {...attributes} isSelected={open} innerRef={setRef}>
      {children}
      <PopoverPreview
        open={isSelected && isFocused}
        elementReference={ref}
        placement="bottom"
        editor={editor}
        popover={HyperlinkEditPopover}
        shouldAutoOpen={shouldAutoOpen}
        wrapper={Popover}
      >
        {openPopover => (
          <LinkPreview>
            <Url title={url}>{url}</Url>
            <span> - </span>
            <StyledA onClick={openPopover}>Bearbeiten</StyledA>
            <span> | </span>
            <StyledA onClick={removeLink}>Entfernen</StyledA>{" "}
          </LinkPreview>
        )}
      </PopoverPreview>
    </FakeA>
  );
};

export default HyperlinkMark;
