/**
 * Interface that describes a "minimal" exercise, requiring only necessary properties
 */
import {
  Exercise,
  ExerciseDTO,
  ExerciseFactory,
  ExerciseType,
  IExercise
} from "./Exercise";
import {
  emptyString,
  jsonToHtml,
  jsonToText
} from "../components/anton/helpers/serializer";

export interface IExerciseFlashcard extends IExercise {
  front?: string;
  frontHtml?: string;
  back?: string;
  backHtml?: string;
  correct?: boolean;
}

/**
 * A flashcard exercise
 */
export interface ExerciseFlashcard extends Exercise {
  front: string;
  frontHtml: string;
  back: string;
  backHtml: string;
  correct?: boolean;
}

/**
 * Factory for creating an exercise
 */
export class ExerciseFlashcardFactory extends ExerciseFactory {
  /**
   * Creates a DTO from the exercise
   * @param exercise
   * @returns the DTO
   */
  static toDTO(exercise: ExerciseFlashcard): ExerciseFlashcardDTO {
    let { front, frontHtml, back, backHtml, correct, ...rest } = exercise;
    let dto = super.toDTO({ ...rest });
    return {
      ...dto,
      front,
      back
    };
  }

  /**
   * Creates an exercise from a DTO
   * @param dto
   * @returns exercise
   */
  static fromDTO(dto: ExerciseFlashcardDTO): ExerciseFlashcard {
    let { front, back, ...rest } = dto;

    let exercise = super.fromDTO({ ...rest, type: ExerciseType.Flashcard });
    return this.create({ ...exercise, front, back });
  }

  /**
   * Creates a new exercise
   * @param params
   * @returns Exercise
   */
  static create(
    params: IExerciseFlashcard = {} as IExerciseFlashcard
  ): ExerciseFlashcard {
    let {
      front = emptyString,
      frontHtml = jsonToHtml(front),
      excerpt = jsonToText(front),
      back = emptyString,
      backHtml = jsonToHtml(back),
      correct = undefined,
      ...rest
    } = params;

    const exercise = super.create({ ...rest, type: ExerciseType.Flashcard });
    return { ...exercise, front, frontHtml, excerpt, back, backHtml, correct };
  }

  /**
   * Duplicates an exercise
   * @param exercise
   * @returns ExerciseFlashcard
   */
  static duplicate(exercise: ExerciseFlashcard): ExerciseFlashcard {
    const newExercise = super.duplicate(exercise);
    return ExerciseFlashcardFactory.create({ ...exercise, ...newExercise });
  }
}

/**
 * A data transfer object for the flashcard exercises
 */
export interface ExerciseFlashcardDTO extends ExerciseDTO {
  front: string;
  back: string;
}
