import { Document, Block, Text, Value } from "slate";

/**
 * Plugin to prevent an empty document.
 * @param {string|Block} type The block (type) which should be used as default
 * @returns {Object} The slate plugin
 */
const NoEmpty = type => {
	type =
		typeof type == "string"
			? Block.create({
					type,
					nodes: [Text.create()]
			  })
			: type;

	/**
	 * An onBeforeChange handler
	 * @param {Change} change The slate change object
	 * @returns {Value} A slate value
	 */
	const onBeforeChange = change => {
		const state = change.state;
		const document = state.document;

		// If document is not empty, it continues
		if (!document.nodes.isEmpty()) {
			return;
		}

		// Reset the state
		return Value.fromJSON({
			document: Document.create({
				nodes: [type]
			})
		});
	};

	return {
		onBeforeChange
	};
};

export default NoEmpty;
