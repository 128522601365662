import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import {
  Alert,
  Badge,
  Container,
  Nav,
  DropdownMenu,
  NavItem,
  DropdownToggle,
  DropdownItem,
  Dropdown
} from "reactstrap";
import LoadingBar from "react-redux-loading-bar";
import { error } from "react-notification-system-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Navbar from "../components/Navbar";
import Placeholder from "../components/Placeholder";
import {
  getAuthenticatedUser,
  getAuthenticatedUserFetching,
  isOnline
} from "../reducers";
import { logout } from "../actions/authentication";
import { colors } from "../utilities/style";
import Breadcrumbs from "../components/Breadcrumbs";
import { updateOnlineStatus } from "../actions/online";
import HeaderSearchbar from "./HeaderSearchbar";

const PreUser = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NavbarDivider = styled.div`
  width: 1px;
  height: 24px;
  padding: 0;
  margin: 0 1rem;
  background-color: ${colors.grey_medium};
`;

/**
 * Efficiently renders the users dropdown menu
 * @returns {Component} The component
 */
class UserDropdownMenu extends React.PureComponent {
  render = () => {
    const { logout } = this.props;
    return (
      <DropdownMenu right>
        <DropdownItem>
          <Link to="/profile">Profil verwalten</Link>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem id="logoutUser" onClick={logout}>
          Abmelden
        </DropdownItem>
      </DropdownMenu>
    );
  };
}

/**
 * Header component
 * @returns {Component} the component
 */
class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      navbarOpen: false,
      userDropdownOpen: false
    };
  }

  componentDidMount = () => {
    const { updateOnlineStatus } = this.props;

    updateOnlineStatus();

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    window.addEventListener("failedFetch", this.onFetchError);
  };

  componentWillUnmount = () => {
    const { updateOnlineStatus } = this.props;

    window.removeEventListener("online", updateOnlineStatus);
    window.removeEventListener("offline", updateOnlineStatus);

    window.removeEventListener("failedFetch", this.onFetchError);
  };

  onFetchError = ({ detail: { error } }) => {
    const { errorNotification } = this.props;
    const message =
      error && error.message
        ? "Bei der Verbindung mit dem Server ist ein Fehler aufgetreten: " +
          error.message
        : "Bei der Verbindung mit dem Server ist ein Fehler aufgetreten.";
    errorNotification({ message });
  };

  toggleHeaderNavbarOpen = () =>
    this.setState({ navbarOpen: !this.state.navbarOpen });

  toggleUserDropwdown = () =>
    this.setState({ userDropdownOpen: !this.state.userDropdownOpen });

  render = () => {
    const {
      user: { username },
      fetchingUser,
      logout,
      isOnline,
      search,
      preUser
    } = this.props;
    const { navbarOpen, userDropdownOpen } = this.state;

    return (
      <header>
        <Navbar open={navbarOpen} toggleCollapsed={this.toggleHeaderNavbarOpen}>
          <LoadingBar className="redux-loading-bar" />
          <NavbarDivider />
          <Breadcrumbs />
          {search && <HeaderSearchbar />}
          {preUser && <PreUser>{preUser}</PreUser>}
          {((preUser && username) || fetchingUser) && <NavbarDivider />}
          {(username || fetchingUser) && (
            <Nav navbar className={"flex-row"}>
              {!isOnline && (
                <NavItem className="d-flex align-items-center mx-2">
                  <Badge color="danger">Offline</Badge>
                </NavItem>
              )}
              <Dropdown
                isOpen={userDropdownOpen}
                toggle={this.toggleUserDropwdown}
                id="userMenu"
                nav
                color="link"
              >
                {username ? (
                  <DropdownToggle nav caret>
                    <span className={"d-none d-sm-inline"}>{username}</span>
                    <FontAwesomeIcon
                      className={"d-inline d-sm-none"}
                      icon={["far", "user"]}
                    />
                  </DropdownToggle>
                ) : (
                  <Placeholder inline text minWidth={5} />
                )}
                <UserDropdownMenu open={userDropdownOpen} logout={logout} />
              </Dropdown>
            </Nav>
          )}
        </Navbar>
      </header>
    );
  };
}

Header.propTypes = {
  preUser: PropTypes.node,
  search: PropTypes.bool
};

const mapStateToProps = state => ({
  isOnline: isOnline(state),
  user: getAuthenticatedUser(state) || {},
  fetchingUser: getAuthenticatedUserFetching(state)
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  /**
   * Updates the online / offline status
   * @returns {void}
   */
  updateOnlineStatus() {
    return dispatch(updateOnlineStatus(navigator.onLine !== false)); //can be undefined, fallback to true
  },
  /**
   * Displays an error notification
   * @param {Object} notification An object containing notification properties
   * @returns {Promise} The promise describing the progress of the action
   */
  errorNotification(notification) {
    return dispatch(
      error({ dismissible: "click", position: "bc", ...notification })
    );
  },
  /**
   * Logs the user out
   * @returns {void}
   */
  logout() {
    dispatch(logout(true));
    dispatch(push("/login"));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
