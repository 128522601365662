//react
import React from "react";
import ReactDOM from "react-dom";
import createHistory from "history/createBrowserHistory";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import throttle from "lodash/throttle";
import { AppContainer } from "react-hot-loader";
import App from "App";
import "moment/locale/de";
import { setLocale } from "yup/lib/customLocale";
import ReactGA from "react-ga";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import "./scss/global.scss";
import { loadState, saveState } from "./local-storage";
import reducers, { getAuthenticatedUser } from "./reducers";

// monkeyPatch React to display information on why a component updated
if (process.env.NODE_ENV !== "production") {
  const { whyDidYouUpdate } = require("why-did-you-update");
  //whyDidYouUpdate(React);
}

//polyfills
require("es6-promise").polyfill();
require("isomorphic-fetch");

//Load state from local storage and create history object
const persistedState = loadState();
const history = createHistory();

//and the redux store
const store = createStore(
  reducers,
  persistedState,
  composeWithDevTools(
    applyMiddleware(
      thunkMiddleware,
      routerMiddleware(history),
      store => next => action => {
        if (action.visualize) {
          store.dispatch(action.isFetching ? showLoading() : hideLoading());
        }
        return next(action);
      }
    )
  )
);

//storing some keys of the application state in the localstorage
store.subscribe(
  throttle(() => {
    const { authentication } = store.getState();

    saveState({
      authentication
    });
  }, 1000)
);

// send user information to drift / GA for customer engagement
store.subscribe(() => {
  let user = getAuthenticatedUser(store.getState());
  if (user && user.username && drift) {
    drift.identify(user.username, {
      email: user.email,
      name: user.firstname + " " + user.lastname
    });
  }
  // send userId to GA
  if (user && user.id) {
    ReactGA.set({ userId: user.id });
  } else {
    ReactGA.set({ userId: null });
  }
});

/**
 * Renders the application
 * @param {Component} Component that should be rendered as the top level application
 * @returns {void}
 */
const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component history={history} store={store} />
    </AppContainer>,
    document.getElementById("root")
  );
};

//do the initial render
render(App);

setLocale({
  mixed: {
    required: "Dieses Feld darf nicht leer sein."
  },
  string: {
    email: "Es muss eine gültige E-Mail Adresse angegeben werden.",
    min: "Muss mindestens ${min} Zeichen lang sein."
  }
});
