/**
 * Interface that describes a "minimal" multiple choice answer, requiring only necessary properties
 */
import {
  emptyString,
  jsonToHtml
} from "../components/anton/helpers/serializer";
import { generateNonce } from "./Exercise";
import { ExerciseFreeTextAnswer } from "./ExerciseFreeTextAnswer";
import { Dictionary } from "./Types";

export interface IExerciseMultipleChoiceAnswer {
  id?: number | string;
  content?: string;
  uid?: string;
  contentHtml?: string;
  correct?: boolean;
  explanation?: string;
  explanationHtml?: string;
  order?: number;
  createdAt?: string;
  updatedAt?: string;
}

/**
 * An answer for a multiple choice exercise
 */
export interface ExerciseMultipleChoiceAnswer {
  id: number | string;
  uid: string;
  content: string;
  contentHtml: string;
  correct: boolean;
  explanation: string;
  explanationHtml: string;
  order: number;
}

/**
 * Creates ExerciseMultipleChoiceAnswers
 */
export class ExerciseMultipleChoiceAnswerFactory {
  /**
   * Creates a DTO from the exercise
   * @param answer
   * @returns the DTO
   */
  static toDTO(
    answer: ExerciseMultipleChoiceAnswer
  ): ExerciseMultipleChoiceAnswerDTO {
    let { id, content, correct, explanation, order, uid } = answer;
    return {
      id: typeof id === "number" ? id : null,
      content,
      correct,
      explanation,
      order,
      uid
    };
  }

  /**
   * Creates an exercise from a DTO
   * @param dto
   * @returns exercise
   */
  static fromDTO(
    dto: ExerciseMultipleChoiceAnswerDTO
  ): ExerciseMultipleChoiceAnswer {
    let {
      created_at: createdAt,
      updated_at: updatedAt,
      correct,
      ...rest
    } = dto;
    correct = !!correct;
    return ExerciseMultipleChoiceAnswerFactory.create({
      correct,
      ...rest,
      createdAt,
      updatedAt
    });
  }

  /**
   * Creates a new multiple choice exercise answer
   * @param params
   * @returns Exercise
   */
  static create(
    params: IExerciseMultipleChoiceAnswer = {} as IExerciseMultipleChoiceAnswer
  ): ExerciseMultipleChoiceAnswer {
    let {
      id = "new",
      uid = generateNonce(),
      content = emptyString,
      correct = false,
      contentHtml = jsonToHtml(content),
      explanation = null,
      explanationHtml = jsonToHtml(explanation),
      order
    } = params;

    return {
      id,
      uid,
      content,
      contentHtml,
      explanation,
      explanationHtml,
      order,
      correct
    };
  }

  /**
   * Duplicates an exercise answer
   * @param answer
   * @returns ExerciseMultipleChoiceAnswer
   */
  static duplicate(
    answer: ExerciseMultipleChoiceAnswer
  ): ExerciseMultipleChoiceAnswer {
    const { content, explanation, order, correct } = answer;
    return ExerciseMultipleChoiceAnswerFactory.create({
      content,
      explanation,
      order,
      correct
    });
  }

  /**
   * Checks if there are empty answers inside a ExerciseMultipleChoiceAnswer dictionary
   * @param answers
   * @returns true if there are empty answers
   */
  static hasEmptyAnswers(
    answers: Dictionary<ExerciseMultipleChoiceAnswer>
  ): boolean {
    let emptyAnswers = false;
    Object.values(answers).forEach(answer => {
      if (
        !answer.content ||
        (JSON.stringify(answer.content.toJSON()) === emptyString &&
          answer.explanation === null)
      ) {
        emptyAnswers = true;
        return;
      }
    });
    return emptyAnswers;
  }
}

/**
 * A data transfer object for the multiple choice exercise answers
 */
export interface ExerciseMultipleChoiceAnswerDTO {
  id?: number;
  uid: string;
  content: string;
  correct: boolean;
  explanation: string;
  order: number;
  created_at?: string;
  updated_at?: string;
}
