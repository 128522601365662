import { combineReducers } from "redux";

import { createById, createAllIds } from "../../utilities/reducer";

const itemName = "course";

/**
 * Stores the user role by course id of the current user
 * @param {Object} state The previous state
 * @param {Object} action The action to proccess
 * @returns {Object} The next state
 */
const roleById = (state = {}, action) => {
  switch (action.type) {
    case "RECEIVE_USER_ROLE":
      return { ...state, [action.courseId]: action.role };
    case "FETCH_USER_COURSES":
      return !action.isFetching && !action.error
        ? {
            ...state,
            ...action.items.reduce((obj, course) => {
              obj[course.id] = course.userType;
              return obj;
            }, {})
          }
        : state;
    case "CREATE_COURSE_SUBSCRIBER":
      return { ...state, [action.courseId]: "subscriber" };
    case "CREATE_COURSE":
      if (!action.isFetching && action.item.id) {
        return { ...state, [action.item.id]: "author" };
      }

      return state;
    case "DELETE_COURSE_USER":
    case "DELETE_COURSE":
      if (!action.isFetching && action.itemId) {
        return Object.keys(state)
          .filter(courseId => courseId != action.itemId)
          .reduce((obj, key) => {
            obj[key] = state[key];

            return obj;
          }, {});
      }

      return state;
    default:
      return state;
  }
};

export default combineReducers({
  bySlug: createById(itemName, "slug", (state, action) => {
    switch (action.type) {
      case "UPDATE_COURSE_USER":
        if (!action.isFetching && !action.error) {
          //Find slug
          const course = Object.values(state).find(
            course => course.id === action.itemId
          );
          if (!course) {
            return state;
          }
          return {
            ...state,
            [course.slug]: {
              ...course,
              users: course.users.map(
                user => (user.id === action.userId ? action.item : user)
              )
            }
          };
        }
        return state;
      case "CREATE_COURSE_SUBSCRIBER":
      case "DELETE_COURSE_USER":
        if (action.isFetching) {
          return state;
        }
        //Find slug
        const course = Object.values(state).find(
          course => course.id === (action.itemId || action.courseId)
        );

        if (!course) {
          return state;
        }

        return {
          ...state,
          [course.slug]: {
            ...course,
            users: action.users
          }
        };
      case "COURSE_MOVE_LESSON":
        const previousLessons = state[action.courseSlug].lessonIds;

        const lessonIds = new Array(previousLessons.length).fill(null);
        lessonIds[action.targetIndex] = previousLessons[action.sourceIndex];

        for (let i = 0; i < previousLessons.length; i++) {
          //skips the copied index
          if (i == action.sourceIndex) {
            continue;
          }
          //and fills the rest from top to bottom
          for (let j = 0; j < lessonIds.length; j++) {
            if (lessonIds[j] === null) {
              lessonIds[j] = previousLessons[i];
              break;
            }
          }
        }

        return {
          ...state,
          [action.courseSlug]: {
            ...state[action.courseSlug],
            lessonIds
          }
        };
      case "DELETE_LESSON":
        return !action.isFetching && action.itemId
          ? Object.values(state)
              .map(course => ({
                ...course,
                lessonIds: course.lessonIds.filter(id => id != action.itemId)
              }))
              .reduce((obj, course) => {
                obj[course.slug] = course;
                return obj;
              }, {})
          : state;
      default:
        return state;
    }
  }),
  allSlugs: createAllIds(itemName, "slug"),
  roleById
});

/**
 * Gets a single course based on its slug
 * @param {Object} state The correct part of the redux state
 * @param {number} courseSlug The item's slug to look for
 * @returns {Object} The item
 */
export const getCourseBySlug = (state, courseSlug) => state.bySlug[courseSlug];

/**
 * Gets a single course based on its id
 * @param {Object} state The correct part of the redux state
 * @param {number} courseId The item's id to look for
 * @returns {Object} The item
 */
export const getCourseById = (state, courseId) =>
  Object.values(state.bySlug).find(course => course.id === courseId);

/**
 * Gets all courses
 * @param {Object} state The correct part of the redux state
 * @returns {Array} All courses
 */
export const getCourses = state =>
  state.allSlugs.map(slug => state.bySlug[slug]);

/**
 * Returns an object mapping the course id to the roles of the current user
 * @param {Object} state The redux state
 * @returns {Object} User roles by course id of the current user
 */
export const getCourseRolesById = state => state.roleById;

/**
 * Returns the role of the current user for a specific course id
 * @param {Object} state The redux state
 * @param {number} courseId id of the course
 * @returns {string} The user role
 */
export const getCourseRoleById = (state, courseId) => state.roleById[courseId];

/**
 * Returns whether the user user is an author for the given course
 * @param {Object} state The redux state
 * @param {number} courseId if of the course
 * @returns {boolean} Whether the current user is an author
 */
export const isCourseAuthor = (state, courseId) =>
  getCourseRoleById(state, courseId) === "author";

/**
 * Returns whether the user user is a collaborator for the given course
 * @param {Object} state The redux state
 * @param {number} courseId if of the course
 * @returns {boolean} Whether the current user is a collaborator
 */
export const isCourseCollaborator = (state, courseId) =>
  getCourseRoleById(state, courseId) === "collaborator";

/**
 * Returns whether the user user is a subscriber for the given course
 * @param {Object} state The redux state
 * @param {number} courseId if of the course
 * @returns {boolean} Whether the current user is a subscriber
 */
export const isCourseSubscriber = (state, courseId) =>
  getCourseRoleById(state, courseId) === "subscriber";

/**
 * Returns all courses for which the current user has one of the given roles
 * @param {Object} state The redux state
 * @param {Array<string>} roles An array of role strings
 * @returns {Array<Object>} An array of courses
 */
export const getCoursesWithRoles = (state, roles = []) =>
  Object.keys(state.roleById)
    .filter(id => roles.includes(state.roleById[id]))
    .map(id => state.byId[id]);

/**
 * Returns whether a course is public or not
 * @param state
 * @param courseId
 * @returns {boolean}
 */
export const isCoursePublic = (state, courseId) => {
  const course = getCourseById(state,courseId);
  if(course) {
    return course.status === "public";
  }
  return false;
};
