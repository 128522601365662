import * as React from "react";
import { Container } from "reactstrap";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { connect } from "react-redux";
import universal from "react-universal-component";

import Wrapper from "../components/Wrapper";
import MainContainer from "../components/MainContainer";
import Header from "../containers/Header";

export const injectLoadingBar = (Component: React.Component) =>
  connect()((props: {}) => (
    <Component
      onBefore={() => props.dispatch(showLoading())}
      onAfter={() => setTimeout(() => props.dispatch(hideLoading()), 500)}
      {...props}
    />
  ));

export const universalOptions = ({
  loading = (props: {}) => (
    <Wrapper>
      <Header />
      <MainContainer>
        <Container />
      </MainContainer>
    </Wrapper>
  ),
  error = (props: {}) => (
    <Wrapper>
      <Header />
      <MainContainer>
        <Container>
          Es ist ein unerwarteter Fehler aufgetreten. Bitte melde dies dem
          Support!
        </Container>
        >
      </MainContainer>
    </Wrapper>
  ),
  timeout = 15000,
  onError = (error: Error, info: { isServer: boolean; isSync: boolean }) => {
    console.error(error);
  },
  onLoad = (
    module: {},
    info: { isServer: boolean; isSync: boolean },
    props: {},
    context: { store: {} }
  ) => {},
  minDelay = 0,
  alwaysDelay = false,
  loadingTransition = true
} = {}) => ({
  loading,
  error,
  timeout,
  onError,
  onLoad,
  minDelay,
  alwaysDelay,
  loadingTransition
});

const defaultOptions = universalOptions();

/**
 * Combines the two functions injectLoadingBar() and universal()
 * @param {Promise} promise The code splitting promise
 * @param {Object} options The universal options
 * @returns {Component} The react component
 */
export const universalWithLoadingBar = (
  promise: Promise,
  options = defaultOptions
) => injectLoadingBar(universal(promise, options));
