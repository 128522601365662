/**
 * Stores whether the client currently has an internet connection
 * @param {boolean} state The previous state
 * @param {Object} action The action that should be processed
 * @returns {boolean} The new state
 */
export default (state = true, action) => {
	switch (action.type) {
		case "UPDATE_ONLINE_STATUS":
			return action.online;
		default:
			return state;
	}
};

/**
 * Checks whether the client currently has an internet connection
 * @param {boolean} state This part of the redux state
 * @returns {boolean} Whether the user currenlty has an internet connection
 */
export const isOnline = state => state;
