export const getCurrentSelectionRect = () => {
  const selection = window.getSelection();
  const range = selection.getRangeAt(0);
  return selection && range && range.getBoundingClientRect();
};

/**
 * Returns the number of pixels that the document is currently scrolled vertically.
 * @returns number
 */
export const getScrollY = (): number => {
  return window.scrollY;
};
