import {
  Exercise,
  ExerciseDTO,
  ExerciseFactory,
  ExerciseType,
  IExercise
} from "./Exercise";
import {
  emptyString,
  jsonToHtml,
  jsonToText
} from "../components/anton/helpers/serializer";
import { ExerciseFlashcard } from "./ExerciseFlashcard";

/**
 * Interface that describes a "minimal" static content, requiring only necessary properties
 */
export interface IStaticContent extends IExercise {
  content?: string;
  contentHtml?: string;
}

/**
 * Static Content
 */
export interface StaticContent extends Exercise {
  content: string;
  contentHtml: string;
}

/**
 * Factory to create static content
 */
export class StaticContentFactory extends ExerciseFactory {
  /**
   * Creates a DTO from the exercise
   * @param exercise
   * @returns the DTO
   */
  static toDTO(exercise: StaticContent): StaticContentDTO {
    let { content, contentHtml, ...rest } = exercise;
    let dto = super.toDTO({ ...rest });
    return {
      ...dto,
      content
    };
  }

  /**
   * Creates an exercise from a DTO
   * @param dto
   * @returns exercise
   */
  static fromDTO(dto: StaticContentDTO): StaticContent {
    let { content, ...rest } = dto;

    let exercise = super.fromDTO({ ...rest, type: ExerciseType.StaticContent });
    return this.create({ ...exercise, content });
  }

  /**
   * Creates a new exercise
   * @param params
   * @returns Exercise
   */
  static create(params: IStaticContent = {} as IStaticContent): StaticContent {
    let {
      content = emptyString,
      contentHtml = jsonToHtml(content),
      excerpt = jsonToText(content),
      ...rest
    } = params;
    let exercise = super.create({ ...rest, type: ExerciseType.StaticContent });
    return { ...exercise, content, contentHtml, excerpt };
  }

  /**
   * Duplicates an exercise
   * @param exercise
   * @returns StaticContent
   */
  static duplicate(exercise: StaticContent): StaticContent {
    const newExercise = super.duplicate(exercise);
    return StaticContentFactory.create({ ...exercise, ...newExercise });
  }
}

export interface StaticContentDTO extends ExerciseDTO {
  content: string;
}
