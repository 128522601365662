import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {renderBlockButton, renderMarkButton, renderNode} from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { toggleBlock } from "../helpers/handlers";
import { hasBlock } from "../helpers/content";
import { markdownBlockSequence } from "../helpers/markdown";

/**
 * Inserts a code block
 */
class CodeBlockButton extends AntonButton {
  name = "code-block";
  icon = <FontAwesomeIcon icon={["far","code"]} />;
  onMouseDown = toggleBlock("code");
  tooltip = {
    target: "code-block",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Code-Block einfügen"
  }
}

/**
 * Inserts a code block
 */
class CodeMarkButton extends AntonButton {
  name = "code-mark";
  icon = <FontAwesomeIcon icon={["far","code"]} />;
  onMouseDown = toggleBlock("code");
  tooltip = {
    target: "code-mark",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Code-Block einfügen"
  }
}

/**
 * A component rendering computer code
 */
class CodeBlock extends React.PureComponent {
  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { children, attributes } = this.props;
    return (
      <pre {...attributes}>
        <code>{children}</code>
      </pre>
    );
  };
}

/**
 * Deserializes a code element
 * @param {Object} element The element to deserialize
 * @param {function} next A callback function to render the children
 * @returns {Object} The deserialized data
 */
const deserializeCode = (element,next) => {
  if (element.tagName.toLowerCase() === 'pre') {
    const code = element.childNodes[0];
    const childNodes =
      code && code.tagName.toLowerCase() === 'code'
        ? code.childNodes
        : el.childNodes;

    return {
      object: 'block',
      type: 'code',
      nodes: next(childNodes),
    }
  }
};

/**
 * The Code Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const Code = (options) => Object.assign(
  renderNode("code", CodeBlock),
  renderMarkButton("code-block",CodeMarkButton),
  renderBlockButton("code-block",CodeBlockButton),
  markdownBlockSequence("code", "´"),
  {
    deserialize: deserializeCode,
    isActive: hasBlock("code"),
  }
);

export default Code;
