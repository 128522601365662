import * as React from "react";
import styled from "styled-components";
import { Container, Navbar as BootstrapNavbar, NavbarBrand } from "reactstrap";
import { Link } from "react-router-dom";

import { colors } from "../utilities/style";
import Brand from "./Brand";

const WhiteNavbar = styled(BootstrapNavbar)`
  background-color: white;
  border-bottom: 1px solid ${colors.grey_light};
`;

interface NavbarProps {
  children: React.ReactNode;
  className?: string;
}

interface NavbarState {
  open: boolean;
}

/**
 * The navbar component
 * @returns {Component} The component
 */
class Navbar extends React.PureComponent<NavbarProps, NavbarState> {
  /**
   * Creates a new instance
   * @param {NavbarProps} props The component properties
   */
  constructor(props: NavbarProps) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      open: false
    };
  }
  /**
   * Toggles whether the navbar is collapsed
   * @returns {void}
   */
  toggleNavbar() {
    this.setState({
      open: !this.state.open
    });
  }
  /**
   * Renders the component
   * @returns {Component} The component
   */
  render() {
    const { children, className = "" } = this.props;

    return (
      <WhiteNavbar className={className} light>
        <Container>
          <NavbarBrand tag="strong" className={"mr-2"}>
            <Link to="/" className={"text-success"}>
              <Brand />
            </Link>
          </NavbarBrand>
          {children}
        </Container>
      </WhiteNavbar>
    );
  }
}

export default Navbar;
