import React, { useState, useCallback, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Form,
  Input,
  Popover,
  InputGroup,
  ButtonGroup,
  InputGroupAddon, PopoverBody
} from "reactstrap";
import PropTypes from "prop-types";

import { AntonPopoverBody } from "./AntonPopover";

/**
 * Renders a popover to edit the hyperlink
 * @returns {Component} the react component
 */
const VideoEditPopover = React.memo(({ editor, blocks, range, close }) => {
  const [url, setUrl] = useState(() => {
    const node = blocks.find(node => node.type === "video");
    return node ? node.data.get("url") : "";
  });
  const inputRef = useRef(null);

  const onInputChange = useCallback(event => setUrl(event.target.value), [
    setUrl
  ]);

  const removeVideo = useCallback(
    event => {
      requestAnimationFrame(() => editor.setBlocks("paragraph"));
      close();
    },
    [editor, close]
  );

  const changeVideo = useCallback(
    () => {
      if (url) {
        const videoBlock = blocks.find(block => block.type === "video");
        //is a immutable list, not an array thus .size
        if (videoBlock) {
          //update existing video blocks
          const data = videoBlock.data.toJS();
          if (data.url !== url.trim()) {
            requestAnimationFrame(() =>
              editor.setNodeByKey(videoBlock.key, {
                data: { ...data, url }
              })
            );
          }
        } else {
          //add new video
          requestAnimationFrame(() =>
            editor.setBlocksAtRange(range, {
              type: "video",
              data: { url }
            })
          );
        }
      } else {
        removeVideo();
      }

      close();
    },
    [editor, range, close, removeVideo, blocks, url]
  );

  const onBlur = useCallback(e => changeVideo(), [changeVideo]);

  //react autofocus scrolls to the element
  useEffect(
    () => {
      if (inputRef.current) {
        editor.blur();
        inputRef.current.focus({ preventScroll: true });
      }
    },
    [inputRef, editor]
  );

  return (
    <AntonPopoverBody>
      <div className={"d-flex justify-content-center"}>
        <Input
          type="url"
          name="url"
          id="url"
          value={url}
          onChange={onInputChange}
          placeholder="Youtube / Vimeo URL"
          innerRef={inputRef}
          onBlur={onBlur}
          bsSize="sm"
        />

        <ButtonGroup className={"ml-1"}>
          <Button size="sm" color={"link"} onMouseDown={changeVideo}>
            <FontAwesomeIcon icon={["far", "check"]} />
          </Button>
          <Button size="sm" color={"link"} onMouseDown={removeVideo}>
            <FontAwesomeIcon icon={["far", "trash-alt"]} />
          </Button>
        </ButtonGroup>
      </div>
    </AntonPopoverBody>
  );
});

VideoEditPopover.propTypes = {
  editor: PropTypes.object,
  url: PropTypes.string,
  removeVideo: PropTypes.func,
  changeVideo: PropTypes.func
};

export default VideoEditPopover;
