import React, { useState, useCallback, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  InputGroup,
  InputGroupAddon,
  Button,
  ButtonGroup,
  Form,
  Input,
  Popover
} from "reactstrap";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "../../../utilities/style";
import { AntonPopoverBody } from "./AntonPopover";

const LinkPreview = styled.div`
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: center;

  & > * {
    flex-shrink: 0;
  }
`;

const Url = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  flex-shrink: 1;
`;

const Link = styled.span`
  color: ${colors.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

/**
 * Renders a popover to edit the hyperlink
 * @returns {Component} the react component
 */
export const HyperlinkEditPopover = React.memo(
  ({ editor, inlines, range, close }) => {
    const [url, setUrl] = useState(() => {
      const link = inlines.find(inline => inline.type === "hyperlink");
      return link ? link.data.get("url") : ""; //maybe there's no link yet
    });
    const inputRef = useRef(null);

    /**
     * Handler that updates the state
     * @param {Event} event the event
     * @returns {void}
     */
    const onInputChange = useCallback(event => {
      setUrl(event.target.value);
    }, []);

    /**
     * Removes the link
     * @param {Event} event the event
     * @returns {void}
     */
    const removeLink = useCallback(
      () => requestAnimationFrame(() => editor.unwrapLinkAtRange(range)),
      [range, editor]
    );

    /**
     * Changes the link
     * @param {Event} e the event
     * @returns {void}
     */
    const updateLink = useCallback(
      e => {
        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }

        if (url) {
          const link = inlines.find(inline => inline.type == "hyperlink");
          //is a immutable list, not an array thus .size
          if (link) {
            //update existing links
            const data = link.data.toJS();
            if (data.url !== url.trim()) {
              requestAnimationFrame(() =>
                editor.setNodeByKey(link.key, {
                  data: { ...data, url }
                })
              );
            }
          } else {
            //add new link
            requestAnimationFrame(() => editor.wrapLinkAtRange(range, url));
          }
        } else {
          removeLink();
        }
        close();
      },
      [editor, close, removeLink, url, inlines, range]
    );

    const onBlur = useCallback(e => updateLink(), [updateLink]);
    const onKeyDown = useCallback(
      e => {
        if (e.key === "Enter") {
          //e.preventDefault(); //no new line
          updateLink();
        }
      },
      [updateLink]
    );

    //react autofocus scrolls to the element
    useEffect(
      () => {
        if (inputRef.current) {
          editor.blur();
          inputRef.current.focus({ preventScroll: true });
        }
      },
      [inputRef, editor]
    );

    return (
      <AntonPopoverBody>
        <div className={"d-flex justify-content-center"}>
          <Input
            type="url"
            name="url"
            id="url"
            value={url}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
            placeholder="Füge eine Url ein (z.B. https://wit.app)"
            onBlur={onBlur}
            innerRef={inputRef}
            autoComplete="off"
            bsSize={"sm"}
          />

          <ButtonGroup className={"ml-1"}>
            <Button size="sm" color={"link"} onClick={updateLink}>
              <FontAwesomeIcon icon={["far", "check"]} />
            </Button>
          </ButtonGroup>
        </div>
      </AntonPopoverBody>
    );
  }
);

HyperlinkEditPopover.propTypes = {
  editor: PropTypes.object,
  url: PropTypes.string
};

export default HyperlinkEditPopover;
