import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  InputGroupAddon,
  InputGroup,
  Input,
  Form,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import styled from "styled-components";

import MainContainer from "../components/MainContainer";
import Wrapper from "../components/Wrapper";
import FooterLanding from "./landing/FooterLanding";
import NavbarLanding from "./landing/NavbarLanding";

/**
 * Privacy Policy Page
 * @returns {Component} the React Component
 */
class PrivacyPolicy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
  }
  toggle = () => this.setState({ isOpen: !this.state.isOpen });
  render() {
    return (
      <Wrapper fixed={"top"}>
        <NavbarLanding />
        <div className={"py-4"} id={"functions"}>
          <Container>
            <Row>
              <Col md={{ size: 12 }} className={"text-center my-4"}>
                <h1>Datenschutzerklärung</h1>
              </Col>
              <Col md={{ size: 12 }} className={"text-center my-4"}>
                <h3>✅ Was wir tun:</h3>
              </Col>
              <Col md={{ size: 8, offset: 2 }} className={"mb-4"}>
                <ul>
                  <li>
                    Wir verwenden state-of-the-art Verschlüsselungen und
                    Protokolle, um sicherzustellen, dass alle Daten sicher
                    übertragen und gespeichert werden.
                  </li>
                  <li>
                    Wir speichern deine Benutzerdaten, welche du bei der
                    Registrierung angibst, in unserer Datenbank. Konkret sind
                    das: <i>Vorname, Nachname, E-Mail Adresse, Passwort</i> und{" "}
                    <i>Benutzernamen</i>. Datenstandort ist dabei Frankfurt,
                    Deutschland.
                  </li>
                  <li>
                    Wir sammeln anonymisiert Daten dazu, wie unsere Applikation
                    gebraucht wird. Dazu sammeln wir auch Informationen über die
                    verwendeten Browser / Geräte und das Land, aus welchem
                    unsere Seite aufgerufen wird.
                  </li>
                  <li>
                    Wir brauchen für die Sammlung und Auswertung der
                    anonymisierten Nutzungsdaten{" "}
                    <a href="https://analytics.google.com/" target="_blank">
                      Google Analytics
                    </a>
                    . Dazu wird ein Teil der Daten an Google übermittelt. Aus
                    den Daten sind keine Rückschlüsse auf einzelne User möglich.
                  </li>
                  <li>
                    Für die direkte Kommunikation mit den Nutzern auf unserer
                    Website verwenden wir{" "}
                    <a href="https://drift.com/" target="_blank">
                      Drift
                    </a>
                    . Dazu übermitteln wir deinen Benutzernamen und deine
                    E-Mail-Adresse an Drift.
                  </li>
                  <li>
                    Für die Aufzeichnung von technischen Fehlern in unserer
                    Applikation verwenden wir{" "}
                    <a href="https://rollbar.com/" target="_blank">
                      Rollbar
                    </a>
                    . Dazu senden wir anonymisiert Daten zu den Fehlern und
                    technische Daten zum Browser / Gerät, auf welchem der Fehler
                    aufgetreten ist, an Rollbar. Weder wir noch Rollbar können
                    die Daten und Verhaltensmuster auf einzelne User
                    zurückführen.
                  </li>
                </ul>
              </Col>
              <Col md={{ size: 12 }} className={"text-center my-4"}>
                <h3>❌ Was wir nicht tun:</h3>
              </Col>
              <Col md={{ size: 8, offset: 2 }} className={"mb-4"}>
                <ul>
                  <li>
                    Wir geben deine persönlichen Daten nicht an Drittparteien
                    weiter.
                  </li>
                  <li>Wir behalten deine Daten nach dem Löschen nicht.</li>
                  <li>
                    Wir geben keine auf unserer Plattform erstellten Inhalte
                    weiter. Das Recht an den Inhalten bleibt jederzeit bei den
                    jeweiligen Autoren.
                  </li>
                  <li>
                    Wir bieten Drittanbietern keine Möglichkeit, über eine
                    Schnittstelle an deine persönlichen Daten zu gelangen.
                  </li>
                </ul>
              </Col>
              <Col md={{ size: 12 }} className={"text-center my-4"}>
                <h1>Spielregeln</h1>
              </Col>
              <Col md={{ size: 8, offset: 2 }} className={"mb-4"}>
                <ul>
                  <li>
                    Wir pflegen auf wit einen offenen und freundlichen Umgang.
                    Benutzer, welche sich nicht daran halten und vermehrt durch
                    anstössige, rassistische und / oder verleumdende Inhalte
                    auffallen, werden von der Plattform verwiesen.
                  </li>
                  <li>
                    Wir übernehmen keine Haftung für die von den Usern in den
                    Lehrgängen zur Verfügung gestellten Inhalte. Es ist Aufgabe
                    der jeweiligen AutorInnen sicherzustellen, dass allfällige
                    Urheberrechte und Lizenzvereinbarungen eingehalten werden.
                    Bei einem Verstoss, behalten wir es uns vor, die Inhalte von
                    unserer Plattform zu entfernen.
                  </li>
                  <li>
                    Benutzer, welche sich unerlaubt Zugang zu privaten Inhalten
                    verschaffen oder das Punkte- / Anreizsystem zu ihren Gunsten
                    oder zu Ungunsten anderer Benutzer ausnutzen, werden von der
                    Plattform verwiesen.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterLanding />
      </Wrapper>
    );
  }
}

export default PrivacyPolicy;
