import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Flexbar = styled.div`
  position: relative;
  height: 100%;

  display: flex;
  align-items: center;
  align-content: center;

  ${({ center }) => (center ? "justify-content: center;" : "")};
`;

Flexbar.propTypes = {
  center: PropTypes.bool
};

export default Flexbar;
