import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { Popover } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getVideoID from "get-video-id";

import { colors } from "../../../utilities/style";
import VideoEditPopover from "./VideoEditPopover";
import VideoEmbed from "../../VideoEmbed";

const VideoWrapper = styled.div`
  background: ${colors.grey_1};
  color: ${colors.fontLight};
  border-radius: 5px;
  display: block;
  position: relative;

  ${({ url }) => (url ? `background: transparent; color: ${colors.font};` : "")}

  ${({ serializer, isFocused, isSelected }) => {
    if (!serializer && (isFocused || isSelected)) {
      return `background: ${colors.green_1}; cursor: pointer;`;
    }
  }}
  &&:hover {
    ${({ serializer }) =>
      serializer ? "" : `background: ${colors.green_1}; cursor: pointer;`}
  }
`;

const Mask = styled.div`
  display: ${isFocused => (!isFocused ? "none" : "block")};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
`;

const URL_PREFIXES = ["http://www.", "https://www.", "http://", "https://"];

/**
 * Gets the embed url from the video url
 * @param {string} url The video url
 * @returns {string} The embed url
 */
const getEmbedUrl = url => {
  const { id, service } = getVideoID(url);
  const urlWithoutPrefix = URL_PREFIXES.reduce((previousValue, prefix) => {
    return previousValue.startsWith(prefix)
      ? previousValue.substring(prefix.length)
      : previousValue;
  }, url);

  switch (service) {
    case "youtube":
      return `https://www.youtube.com/embed/${id}?showinfo=0&modestbranding=1&controls=2`;
    case "vimeo":
      return `https://player.vimeo.com/video/${id}?color=23CD93&title=0&byline=0&portrait=0`;
    default:
      return null;
  }
};

const VideoBlock = React.memo(
  ({
    children,
    attributes,
    node,
    isSelected,
    isFocused,
    serializer,
    editor
  }) => {
    const url = node.data.get("url");
    const embedUrl = getEmbedUrl(url);

    const ref = useRef(null);

    const openPopover = useCallback(
      () => {
        editor.moveStartToStartOfBlock().moveEndToEndOfBlock();

        editor.registerPopover({
          elementReference: ref,
          placement: "bottom",
          popover: VideoEditPopover
        });
      },
      [editor, ref.current]
    );

    if (serializer) {
      return embedUrl ? <VideoEmbed url={embedUrl} {...attributes} /> : null;
    }

    return (
      <React.Fragment>
        <VideoWrapper
          innerRef={ref}
          className={"my-2 p-2"}
          serializer={serializer}
          isFocused={isFocused}
          isSelected={isSelected}
          onClick={openPopover}
        >
          <Mask isFocused={isFocused} />
          {embedUrl ? (
            <VideoEmbed url={embedUrl} {...attributes} />
          ) : (
            <span>
              <FontAwesomeIcon
                icon={["far", "film"]}
                size={"lg"}
                className={"mr-2"}
              />
              Füge ein Video ein.
            </span>
          )}
          {!embedUrl && (
            <p className={"text-danger mb-0"}>
              Es muss eine gültige Video URL eingegeben werden.
            </p>
          )}
        </VideoWrapper>
      </React.Fragment>
    );
  }
);
export default VideoBlock;
