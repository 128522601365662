import React from "react";

import logoSvg from "../assets/images/wit-logo.svg";
import logoPng from "../assets/images/wit-logo.png";
import logoPng2x from "../assets/images/wit-logo@2x.png";

/**
 * The Brand
 * @returns {Component} the component
 */
const Brand = () => <img className={"align-top"} src={logoSvg} />;

export default Brand;
