import React from "react";

/**
 * Calls the render function if the mark type is equal to the given one
 * @param {string} type The mark type to render
 * @param {component} Component A react component rendering the node
 * @returns {Object} The slate plugin
 */
export const renderMark = (type, Component) => ({
	/**
	 * Renders a mark
	 * @param {Object} props The given mark properties
   * @param {Editor} editor the editor
   * @param {function} next the function that is called to invoke the next plugin
   * @returns {Node} The rendered mark
	 */
	renderMark(props,editor,next) {
		return props.mark.type === type ? <Component {...props} /> : next();
	}
});

/**
 * Creates a deserialize plugin for the mark
 * @param {string} type the type of the mark
 * @param {string} tagName the name of the html tag
 * @returns {Object} the deserialize plugin
 */
export const deserializeMark = (type,tagName) => ({
  /**
   * Deserializes a mark
   * @param {Object} element The element to deserialize
   * @param {function} next A callback function to render the children
   * @returns {Object} The deserialized data
   */
  deserialize(element,next) {
    if(element.tagName.toLowerCase() !== tagName) { return }
    return {
      object: "mark",
      type: type,
      nodes: next(element.childNodes)
    };
  }
});

/**
 * Creates a deserialize plugin for the block
 * @param {string} type the type of the block
 * @param {string} tagName the name of the html tag
 * @returns {Object} the deserialize plugin
 */
export const deserializeBlock = (type, tagName) => ({
  /**
   * Deserializes a block element
   * @param {Object} element The element to deserialize
   * @param {function} next A callback function to render the children
   * @returns {Object} The deserialized data
   */
  deserialize(element,next) {
    if(element.tagName.toLowerCase() !== tagName) { return }

    return {
      object: "block",
      type: type,
      nodes: next(element.childNodes)
    };
  }
});

/**
 * Creates a plugin for rendering the mark button
 * @param {string} type the type
 * @param {Component} Component the component that should be rendered
 * @returns {Object} the plugin containing the renderMarkButton function
 */
export const renderMarkButton = (type, Component) => ({
  /**
   * Renders a mark button
   * @param {Object} props the props that are passed to the button
   * @returns {Component} the component
   */
  renderMarkButton(props) {
    return <Component {...props} />;
  }
});

/**
 * Creates a plugin for rendering the block button
 * @param {string} type the type
 * @param {Component} Component the component that should be rendered
 * @returns {Object} the plugin containing the renderBlockButton function
 */
export const renderBlockButton = (type, Component) => ({
  /**
   * Renders a block button
   * @param {Object} props the props that are passed to the button
   * @returns {Component} the component
   **/
  renderBlockButton(props) {
    return <Component {...props} />;
  }
});

/**
 * Calls the render function if the node type is equal to the given one
 * @param {string} type The node type to render
 * @param {component} Component A react component rendering the node
 * @returns {Object} The slate plugin
 */
export const renderNode = (type, Component) => ({
	/**
	 * Renders a node
	 * @param {Object} props The given node properties
   * @param {Editor} editor the editor
   * @param {function} next the function that is called to invoke the next plugin
	 * @returns {Node} The rendered node
	 */
	renderNode(props,editor,next) {
		return props.node.type === type ? <Component {...props} /> : next();
	}
});

