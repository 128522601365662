import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  renderBlockButton,
  renderMarkButton,
  renderNode
} from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { toggleBlock } from "../helpers/handlers";
import { hasBlock } from "../helpers/content";

/**
 * Converts the selected text to a bulleted list
 */
class NumberedListMarkButton extends AntonButton {
  name = "numbered-list-mark";
  icon = <FontAwesomeIcon icon={["far", "list-ol"]} />;
  onMouseDown = toggleBlock("numbered-list");
  tooltip = {
    target: "numbered-list-mark",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Zu geordneter Liste konvertieren"
  };
}

/**
 * Inserts a bulleted list
 */
class NumberedListBlockButton extends AntonButton {
  name = "numbered-list-block";
  icon = <FontAwesomeIcon icon={["far", "list-ol"]} />;
  onMouseDown = toggleBlock("numbered-list");
  tooltip = {
    target: "numbered-list-block",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Geordnete Liste einfügen"
  };
}


/**
 * The Numbered List Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const BulletedList = options =>
  Object.assign(
    /*renderNode("numbered-list", NumberedListBlock),*/
    renderMarkButton("numbered-list", NumberedListMarkButton),
    renderBlockButton("numbered-list", NumberedListBlockButton),
    {
      isActive: value => hasBlock("numbered-list")(value)
    }
  );

export default BulletedList;
