import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useCallback } from "react";
import styled from "styled-components";
import { ButtonGroup, Button } from "reactstrap";

import { colors } from "../../../utilities/style";
import ImageEditPopover from "./ImageEditPopover";
import PopoverPreview from "../PopoverPreview";
import AntonContext from "../AntonContext";

const LAYOUT_OPTIONS = [
  { key: "align-left", icon: ["far", "align-left"] },
  { key: "align-center", icon: ["far", "align-center"] },
  { key: "align-right", icon: ["far", "align-right"] },
  { key: "full-width", icon: ["far", "arrows-h"] }
];

const EditorStyledImage = styled.div`
  position: relative;
  box-sizing: content-box;
  display: inline-block;
  img {
    border: 2px solid transparent;
  }
`;

const Img = styled.img`
  max-width: ${({ layout }) => (layout === "full-width" ? "100%" : "50%")};

  ${({ layout }) =>
    layout === "align-left"
      ? "float:left;"
      : layout === "align-center"
      ? "margin: 0 auto;"
      : layout === "align-right"
      ? "float:right"
      : ""};

  height: auto;

  display: block;

  &:after {
    clear: both;
  }
`;

const Clearfix = styled.div`
  :after {
    content: "";
    display: block;
    clear: both;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  background: ${colors.grey_1};
  color: ${colors.fontLight};
  border-radius: 5px;
  display: block;

  ${({ image }) =>
    image ? `background: transparent; color: ${colors.font};` : ""}

  ${({ serializer, isFocused, isSelected }) => {
    if (!serializer && (isFocused || isSelected)) {
      return `background: ${colors.green_1}; cursor: pointer;`;
    }
  }}
  &&:hover {
    ${({ serializer }) =>
      serializer ? "" : `background: ${colors.green_1}; cursor: pointer;`}
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  .btn-link.active {
    color: ${colors.green_3};
  }
  .btn {
    padding: 0.375rem 0.5rem;
  }
`;

/**
 * Renders an image
 */
const ImageBlock = React.memo(
  ({ node, attributes, isSelected, serializer = false, editor, isFocused }) => {
    const ref = useRef(null);

    const image = node.data.get("image");
    const imageLayout = node.data.get("layout") || "align-left";

    const shouldAutoOpen = useCallback(
      () => isSelected && isFocused && !image,
      [isSelected, isFocused, image]
    );

    const changeLayout = useCallback(
      (e, layout) => {
        editor.setNodeByKey(node.key, {
          data: { ...node.data.toJS(), layout: layout }
        });
      },
      [node, editor]
    );

    const removeImage = useCallback(
      e => requestAnimationFrame(() => editor.setBlocks("paragraph")),
      [editor]
    );

    const img = image ? (
      <Img
        src={image.path}
        width={image.width || ""}
        height={image.height || ""}
        alt={"Bild #" + image.id}
        layout={imageLayout}
      />
    ) : null;

    if (serializer) {
      return (
        <EditorStyledImage {...attributes}>
          <Clearfix>{img}</Clearfix>
        </EditorStyledImage>
      );
    } else {
      return (
        <ImageWrapper
          innerRef={ref}
          className={"my-2 p-2"}
          serializer={serializer}
          image={image}
          isFocused={isFocused}
          isSelected={isSelected}
        >
          {image ? (
            <EditorStyledImage {...attributes}>
              <Clearfix>{img}</Clearfix>
            </EditorStyledImage>
          ) : (
            <span>
              <FontAwesomeIcon
                icon={["far", "file-image"]}
                size={"lg"}
                className={"mr-2"}
              />
              Füge ein Bild ein.
            </span>
          )}
          <PopoverPreview
            open={isSelected && isFocused}
            elementReference={ref}
            placement="bottom"
            editor={editor}
            className={"popover"}
            popover={ImageEditPopover}
            shouldAutoOpen={shouldAutoOpen}
          >
            {openPopover => (
              <div className={"d-flex justify-content-center"}>
                <StyledButtonGroup>
                  {LAYOUT_OPTIONS.map(l => (
                    <Button
                      color="link"
                      key={l.key}
                      onMouseDown={e => changeLayout(e, l.key)}
                      active={l.key === imageLayout}
                    >
                      <FontAwesomeIcon icon={l.icon} />
                    </Button>
                  ))}
                </StyledButtonGroup>
                <Button onMouseDown={removeImage} color="link">
                  <FontAwesomeIcon icon={["far", "trash-alt"]} />
                </Button>
              </div>
            )}
          </PopoverPreview>
        </ImageWrapper>
      );
    }
  }
);

export default ImageBlock;
