import * as React from "react";
import styled from "styled-components";

const Main = styled.div`
  flex: 1;
`;

interface MainContainerProps {
  className?: string;
  children: React.ReactNode;
}

/**
 * The main app container
 * @returns {Component} The component
 */
class MainContainer extends React.PureComponent<MainContainerProps, any> {
  /**
   * Renders the component
   * @returns {ReactNode}
   */
  render() {
    const { children, className } = this.props;

    return <Main className={"my-4 " + className}>{children}</Main>;
  }
}

export default MainContainer;
