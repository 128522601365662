import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from "styled-components";

import {deserializeBlock,  renderMarkButton, renderNode} from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { toggleBlock } from "../helpers/handlers";
import { hasBlock } from "../helpers/content";
import { markdownBlockSequence } from "../helpers/markdown";

/**
 * Marks the selected text as h3
 */
class H3MarkButton extends AntonButton {
  name = "heading-three-mark";
  icon = <FontAwesomeIcon icon={["far","h3"]} />;
  onMouseDown = toggleBlock("heading-three");
  tooltip = {
    target: "heading-three-mark",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Überschrift 3"
  }
}

const StyledH6 = styled.h6`
  font-weight: bold;
`;

/**
 * Renders a title of third order
 */
class H3Mark extends React.PureComponent {
  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { children, attributes } = this.props;
    return <StyledH6 {...attributes}>{children}</StyledH6>;
  };
}

/**
 * The H3 Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const H3 = (options) => Object.assign(
  renderNode("heading-three", H3Mark),
  renderMarkButton("heading-three-mark",H3MarkButton),
  deserializeBlock("heading-three","h3"),
  markdownBlockSequence("heading-three", "###"),
  {
    isActive: hasBlock("heading-three"),
  }
);

export default H3;
