export const VALID_IMAGE_TYPES = [
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/svg+xml"
];
export const VALID_IMAGE_EXTENSIONS = [".png", ".jpg", ".jpeg", ".svg", ".gif"];
export const MAX_IMAGE_SIZE = 2 * 1024 * 1024;

/**
 * Checks whether a given image is valid
 * @param {File} file The file to validate
 * @returns {Array<Errors>} An array of validation errors
 */
export const validateImage = (file: File) => {
  const errors = [];

  if (!VALID_IMAGE_TYPES.includes(file.type)) {
    errors.push(
      new Error(
        `Ungültiger Dateityp!" ${VALID_IMAGE_EXTENSIONS.join(
          " , "
        )} wären gültig. Die ausgewählte Datei ist vom Typ ${file.type}.`
      )
    );
  }

  if (file.size > MAX_IMAGE_SIZE) {
    errors.push(
      new Error(
        `Die Datei muss kleiner als
					${MAX_IMAGE_SIZE /
            1024 /
            1024} MB sein! Die ausgewähle Datei ist ca. ${Math.round(
          file.size / 1024 / 1024
        )} MB gross.`
      )
    );
  }

  return errors;
};
